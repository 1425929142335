import dayjs from 'dayjs'
import { useState, useMemo } from 'react'
import { ONE, uptime } from 'src/constants/Uptime.constants'

/**
 * Hook to calculate the end date based on the Uptime selected by the User
 */

export const useUptime = ({
    start= dayjs(),
    options
}) => {
    
    const [selectedUptime, setSelectedUptime] = useState(ONE)    
    return useMemo(() => {
        const startDate = dayjs(start).startOf('day')

        const $dateFormat = options?.dateFormat || 'YYYY-MM-DD'

        const range = {}

        let endDate = startDate
            .startOf('day')
            .add(uptime[selectedUptime], 'year')
        if(selectedUptime === 'oneMonth'){
            endDate = startDate
                .startOf('day')
                .add(1, 'month')
        }

        range.startDate = startDate
        range.endDate = endDate
        range.startDateString = startDate.format($dateFormat)
        range.endDateString = endDate.format($dateFormat)
        
        return {
            uptime: selectedUptime,
            setUptime: setSelectedUptime,
            range
        }
    }, [selectedUptime, start])
}
