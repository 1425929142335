export const ONE = 'oneYear'
export const TWO = 'twoYears'
export const THREE = 'threeYears'
export const MONTH = 'oneMonth'

export const uptime = {
    [MONTH]: 0,
    [ONE]: 1,
    [TWO]: 2,
    [THREE]: 3,
}
