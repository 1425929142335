export const addToArray = (arr = [], data) => {
    const updatedArray = [...arr, data]

    return updatedArray
}

export const updateArrayPosition = (arr = [], index = 0, data = {}) => {
    const updatedArray = [
        ...arr.slice(0, index),
        data,
        ...arr.slice(index + 1, arr.length),
    ]

    return updatedArray
}

export const deleteArrayPosition = (arr = [], index = 0) => {
    const updatedArray = [
        ...arr.slice(0, index),
        ...arr.slice(index + 1, arr.length),
    ]

    return updatedArray
}

export const removeDuplicates = (arr = []) => {
    return [...new Set(arr)]
}
