import { useState } from 'react'

const useModal = (initialState = false) => {
    const [visible, setVisible] = useState(false)

    const handleShowModal = () => setVisible(true)
    const handleCloseModal = () => setVisible(false)

    return { visible, showModal: handleShowModal, closeModal: handleCloseModal }
}

export default useModal
