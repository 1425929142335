/* eslint-disable no-unused-vars */
import React, { useImperativeHandle, useCallback } from 'react'
import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'
import * as yup from 'yup'
import { validations } from 'src/service/yupValidations'
import { useFormik } from 'formik'
import FormikAntInputPassword from 'src/components/FormikAntDInputs/FormikAntInputPassword'
import FormikAntSelectAdmin from 'src/components/FormikAntDInputs/FormikAntSelectAdmin'
import classNames from 'classnames'
import { useStaffState } from 'src/store/reducers/staffReducer/staff.hooks'
import { useHistoryTradesState } from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import { removeEmptySpaces } from 'src/utils/stringUtils'

const AddCustomerToEpochForm = React.forwardRef(({ onSubmit }, ref) => {
    const { epochs } = useHistoryTradesState()
    const _initialState = {
        epoch: null,
        initialBalance: '',
    }

    const yupSchema = yup.object().shape({
        epoch: validations.number,
        initialBalance: validations.number,
    })

    const handleSubmit = useCallback(
        (values) => {
            onSubmit({
                ...values,
            })
        },
        [onSubmit]
    )

    const formik = useFormik({
        initialValues: _initialState,
        validationSchema: yupSchema,
        onSubmit: handleSubmit,
    })

    useImperativeHandle(ref, () => ({
        submitForm: () => formik.submitForm(),
        resetForm: () => formik.resetForm(_initialState),
    }))

    return (
        <form onSubmit={formik.handleSubmit} className="pb-3">
            <div>
                <div className="mb-1">Select Epoch</div>
                <FormikAntSelectAdmin
                    formik={formik}
                    name="epoch"
                    value={
                        formik.values?.epoch !== null
                            ? formik.values?.epoch
                            : null
                    }
                    placeholder="SELECT EPOCH"
                    style={{
                        width: '100%',
                        // height: '40px',
                    }}
                    className={classNames('text-center rounded-sm', {
                        'ring-1 ring-red-600':
                            (formik.touched.epoch && formik.errors.epoch) ||
                            (formik.errors.epoch && formik.submitCount > 0),
                    })}
                    size="large"
                    onChange={(val) => {
                        formik.setValues({
                            ...formik.values,
                            epoch: val,
                        })
                    }}
                    options={epochs.map((epoch) => {
                        return {
                            value: epoch.id.toString(),
                            label: `EPOCH ${epoch.id}`,
                        }
                    })}
                />
                <div className="mb-1 text-palette-gray-700 font-oxanium font-medium text-base">
                    Initial Balance
                </div>
                <FormikAntInput
                    formik={formik}
                    name="initialBalance"
                    size="small"
                    prefix={
                        <span className="text-base text-[#B2955F] font-semibold">
                            $
                        </span>
                    }
                    // placeholder="Username"
                    transform={{
                        output: (value) => removeEmptySpaces(value),
                    }}
                />
            </div>
        </form>
    )
})
AddCustomerToEpochForm.displayName = 'AddCustomerToEpochForm'

export default AddCustomerToEpochForm
