import { useState } from 'react'
import useDebounce from './useDebounce'
/**
 * Create a hook that will return a debounced state value
 * @param value The value to debounce this value will be set as the state after the delay.
 * @param delay The delay in milliseconds to debounce the state.
 * @returns A tuple with the debounced value
 * @example
 * const value = useDebounceState(0, 1000)
 */

export const useDebounceState = (value = null, delay = 1000) => {
    const [debouncedValue, setDebouncedValue] = useState(value)
    useDebounce(() => setDebouncedValue(value), delay, [value])
    return debouncedValue
}
