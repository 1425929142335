import React from 'react'
import { Modal } from 'antd'
import Button from './../../Buttons/Button'

const SubscribeSaveKey = ({ visible, onConfirmed, onClose }) => {
    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={526}
            bodyStyle={{
                height: 260,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignContent: 'center',
            }}
        >
            <div className="flex flex-col gap-2 text-center ">
                <div className="mx-auto text-base text-palette-gray-700 font-semibold ">
                    YOU HAVE JUST LINKED AN ACCOUNT
                </div>
                <div className="mx-auto text-base text-palette-gray-700 font-semibold ">
                    WITH THE GIVEN SETTINGS
                </div>
                <div className="mx-auto text-base text-palette-brown-901 font-semibold ">
                    {' '}
                    DO YOU AUTHORIZE IGNIITE TO TRADE?
                </div>
            </div>

            <div className="flex flex-row justify-around h-14">
                <Button
                    variant="dark"
                    className="h-14 w-40 font-semibold"
                    onClick={onConfirmed}
                >
                    YES
                </Button>
                <Button
                    variant="dark"
                    className="h-14 w-40 font-semibold"
                    onClick={onClose}
                >
                    NO
                </Button>
            </div>
        </Modal>
    )
}

export default SubscribeSaveKey
