/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import { createReducer } from '@reduxjs/toolkit'
import {
    getWalletsBalance,
    getStatsAccountAll,
    getStatsAccount,
} from './stats.actions'

export const initialState = {
    stats: {
        totalTrades: 0,
        cumpnlUsd: '0.00',
        wins: '0.00',
        losses: '0.00',
        winsUsd: '0.00',
        lossesUsd: '0.00',
        tradesWined: 0,
        tradesLoosed: 0,
        cumpnl: '0.00',
        profitFee: '0.00',
    },
    tradeHistory: [],
    graphicData: [],
    walletsBalance: [],
}

const statsReducer = createReducer(initialState, (builder) => {
    builder

        .addCase(getStatsAccount.pending, (state, { payload }) => {
            return {
                ...state,
                stats: {
                    totalTrades: 0,
                    cumpnlUsd: '0.00',
                    wins: '0.00',
                    losses: '0.00',
                    winsUsd: '0.00',
                    lossesUsd: '0.00',
                    tradesWined: 0,
                    tradesLoosed: 0,
                    cumpnl: '0.00',
                    profitFee: '0.00',
                },
                tradeHistory: [],
                graphicData: [],
            }
        })
        .addCase(getStatsAccountAll.pending, (state, { payload }) => {
            return {
                ...state,
                stats: {
                    totalTrades: 0,
                    cumpnlUsd: '0.00',
                    wins: '0.00',
                    losses: '0.00',
                    winsUsd: '0.00',
                    lossesUsd: '0.00',
                    tradesWined: 0,
                    tradesLoosed: 0,
                    cumpnl: '0.00',
                    profitFee: '0.00',
                },
                tradeHistory: [],
                graphicData: [],
            }
        })
        .addCase(getStatsAccount.fulfilled, (state, { payload }) => {
            return { ...state, ...payload.data }
        })
        .addCase(getStatsAccountAll.fulfilled, (state, { payload }) => {
            return { ...state, ...payload.data }
        })
        .addCase(getWalletsBalance.fulfilled, (state, { payload }) => {
            state.walletsBalance = payload
        })
})

export default statsReducer
