export const MONTHLY = 'monthly'
export const WEEKLY = 'weekly'
export const DAILY = 'daily'
export const DAYS_90 = 'DAYS90'
export const YEARLY = 'yearly'
export const CUSTOM = 'CUSTOM'

export const temporality = {
    [MONTHLY]: 29,
    [WEEKLY]: 6,
    [DAILY]: 1,
    [DAYS_90]: 89,
    [YEARLY]: 364,
}
