// Helper to compare with the other charts
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
} from 'chart.js'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'
import { WEEKLY } from 'src/constants/temporality.constants'
import { DAILY } from '../../constants/temporality.constants'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip)

const options = {
    responsive: true,
    plugins: {
        title: {
            display: true,
            text: '[HELPER] PnLs',
            align: 'start',
            color: '#fff',
            font: {
                size: 18,
            },
        },
    },
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
            grid: {
                drawBorder: true,
                color: 'rgba(116, 116, 116, 0.2)',
                opacity: '0.5',
            },
        },
    },
}

const DashboardPnLs = ({ graphicData = [], temporality }) => {
    const $graphicData = useMemo(() => {
        const labels = graphicData.map((v) => {
            const date = new Date(v.date)

            if (temporality === WEEKLY) {
                return date.toLocaleDateString('en-US', {
                    weekday: 'long',
                })
            }
            if (temporality === DAILY) {
                return date.toLocaleTimeString('en-US', {
                    hour12: false,
                })
            }

            return date.toLocaleDateString('en-US', { date: 'short' })
        })
        // Object with with win and losses
        const sets = graphicData.reduce(
            (acc, v) => {
                if (v.pnlUsd >= 0) {
                    acc.win.push(v.pnlUsd)
                    acc.losses.push(0)
                } else {
                    acc.win.push(0)
                    acc.losses.push(v.pnlUsd)
                }
                return acc
            },
            { win: [], losses: [] }
        )

        return {
            labels,
            datasets: [
                {
                    label: 'Wins',
                    data: sets.win,
                    borderColor: 'rgb(0, 109, 65)',
                    backgroundColor: 'rgb(0, 109, 65)',
                    borderWidth: 1,
                },
                {
                    label: 'Losses',
                    data: sets.losses,
                    borderColor: 'rgb(244, 78, 103)',
                    backgroundColor: 'rgb(244, 78, 103)',
                    borderWidth: 1,
                },
            ],
        }
    }, [graphicData, temporality])

    return (
        <div className="w-full lg:w-[665px] p-5 rounded border-palette-black-725 border-2 bg-palette-black-600">
            <Bar options={options} data={$graphicData} />
        </div>
    )
}

DashboardPnLs.propTypes = {}

export default DashboardPnLs
