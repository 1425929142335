import React from 'react'

const StatsContainer = ({ children }) => {
    return (
        <div className="bg-palette-black-800 w-full min-w-[10.5rem] p-3 lg:p-5">
            {children}
        </div>
    )
}

export default StatsContainer
