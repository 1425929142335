import React, { useMemo, useState, useCallback, useRef } from 'react'
import classNames from 'classnames'
import {
    useExchange,
    usePlaceOrder,
} from 'src/store/reducers/exchangeReducer/exchange.hooks'
import useModal from 'src/hooks/useModal'
import Button from '../../Buttons/Button'
import { ORDER_TYPE } from 'src/constants/positionConstants'
import { sleep } from 'src/utils/promise'
import { ChangeLeverage } from './ChangeLeverage'
import { MarginMode } from './MarginMode'
import { FaWallet } from 'react-icons/fa'
import { ConfigureOrder } from './ConfigureOrder'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

export const CreatePosition = ({ keyId, tradeData }) => {
    const [loading, setLoading] = useState(false)
    const configureOrder = useRef(null)
    const [isLong, setIsLong] = useState(null)
    const { visible, showModal, closeModal } = useModal()
    const { availableBalance, equity } = useExchange()
    const {
        visible: visibleMargin,
        showModal: showModalMargin,
        closeModal: closeModalMargin,
    } = useModal()
    const [orderType, setOrderType] = useState(ORDER_TYPE.LIMIT)

    const placeOrder = usePlaceOrder()
    // const setLeverage = useSetLeverage()

    const { symbol = '', quantity: currentQty, leverage } = tradeData || {}

    const symbolCrypto = useMemo(() => {
        return symbol.replace('USDT', '')
    }, [symbol])

    const handleOnClickButton = (isLong = true) => {
        setIsLong(isLong)
        configureOrder.current.submitForm()
    }

    const handlePlaceOrder = useCallback(
        async (values) => {
            const { price, amount } = values
            const percentage = (100 / currentQty) * Number(amount)
            const config = {
                orderType: orderType,
                side: isLong ? 'Buy' : 'Sell',
                symbol,
                qty: Number(currentQty),
                percentage,
                entryPrice:
                    orderType === ORDER_TYPE.LIMIT ? Number(price) : undefined,
                keyId,
            }

            setLoading(true)
            await sleep(600)
            await placeOrder(config)
            configureOrder.current.resetForm()
            setLoading(false)
        },
        [orderType, isLong, symbol, keyId, currentQty]
    )

    const $leverage = useMemo(() => {
        return {
            long: leverage || 0,
            short: leverage || 0,
        }
    }, [leverage])

    return (
        <div className="flex flex-col min-h-[482px] bg-palette-black-800 pt-4 px-4 pb-12">
            <ChangeLeverage
                keyId={keyId}
                symbol={symbol}
                visible={visible}
                onClose={closeModal}
                leverage={$leverage}
            />
            <MarginMode visible={visibleMargin} onClose={closeModalMargin} />
            <div className="flex flex-row gap-4">
                <Button
                    disabled={!tradeData}
                    onClick={() => {
                        showModal()
                    }}
                    className="w-full rounded-none"
                >
                    Leverage
                </Button>{' '}
                <Button
                    disabled
                    onClick={() => {
                        showModalMargin()
                    }}
                    className="w-full rounded-none"
                >
                    Isolated
                </Button>
            </div>
            <div className="mt-3 flex flex-row gap-8">
                <div
                    onClick={() => setOrderType(ORDER_TYPE.LIMIT)}
                    className={classNames(
                        'w-full text-center py-4 font-oxanium hover:cursor-pointer',
                        {
                            'border-b-2 border-palette-brown-901':
                                orderType === ORDER_TYPE.LIMIT,
                        }
                    )}
                >
                    Limit
                </div>
                <div
                    onClick={() => setOrderType(ORDER_TYPE.MARKET)}
                    className={classNames(
                        'w-full text-center py-4 font-oxanium hover:cursor-pointer',
                        {
                            'border-b-2 border-palette-brown-901':
                                orderType === ORDER_TYPE.MARKET,
                        }
                    )}
                >
                    Market
                </div>
            </div>
            <div className="flex flex-row mt-4 items-end">
                <div className="flex flex-row gap-3 items-end">
                    <FaWallet className="text-xl relative -top-1" />
                    <div className="text-lg">Equity Balance</div>
                </div>
                <div className="ml-auto text-lg">
                    {equity ? formatNumberToCurrency(equity) : 0} USDT
                </div>
            </div>
            <div className="flex flex-row mt-4 items-end">
                <div className="flex flex-row gap-3 items-end">
                    <FaWallet className="text-xl relative -top-1" />
                    <div className="text-lg">Available Balance</div>
                </div>
                <div className="ml-auto text-lg">
                    {availableBalance
                        ? formatNumberToCurrency(availableBalance)
                        : 0}{' '}
                    USDT
                </div>
            </div>
            <ConfigureOrder
                ref={configureOrder}
                symbol={symbol}
                orderType={orderType}
                onSubmit={handlePlaceOrder}
                disabled={!tradeData}
            />
            <div className="flex flex-row gap-4 mt-auto">
                <Button
                    disabled={loading || !symbol}
                    loading={loading}
                    onClick={() => handleOnClickButton(true)}
                    className="w-full rounded-none"
                    size="normal"
                    variant="success"
                >
                    Buy/Long {symbolCrypto}
                </Button>
                <Button
                    disabled={loading || !symbol}
                    loading={loading}
                    onClick={() => handleOnClickButton(false)}
                    className="w-full rounded-none"
                    size="normal"
                    variant="danger"
                >
                    Sell/Short {symbolCrypto}
                </Button>
            </div>
        </div>
    )
}
