import React, { useCallback, useState, useEffect } from 'react'
import { useGetBalance } from 'src/store/reducers/exchangeReducer/exchange.hooks'

import { CreatePosition, SetSLTPModal } from '../../components/PositionResolver'

import Tabs from '../../components/AdminComponents/Tabs'
import TableUserOrders from 'src/components/AdminComponents/TableUserOrders'

import TableUsers from '../../components/AdminComponents/TableUsers'

import Container from '../../components/Container'
import {
    useFetchUserActiveTrades,
    useFetchUserOrders,
    useFetchUserTrades,
    usePositionResolverState,
    useClosePosition,
    useCancelOrder,
} from 'src/store/reducers/positionResolver/position.hooks'

const PositionResolverView = () => {
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedTrade, setSelectedTrade] = useState(null)
    const [tabSelected, setTabSelected] = useState('activeOrders')
    const { users, activeTrades, activeOrders } = usePositionResolverState()
    const [isManual, setIsManual] = useState(false)
    const [tradeToEditSlTP, setTradeToEditSLTP] = useState(null)

    const { keyId } = selectedUser ?? {}

    useGetBalance(keyId)
    useFetchUserTrades(keyId)
    useFetchUserOrders(keyId)
    useFetchUserActiveTrades()

    useEffect(() => {
        setSelectedTrade(null)
    }, [keyId])

    const closePosition = useClosePosition()
    const cancelOrder = useCancelOrder()

    const handleOnClosePosition = useCallback(
        (symbol) => {
            closePosition({ keyId, symbol })
        },
        [closePosition, keyId]
    )

    const handleOnCancelOrder = useCallback(
        (symbol, orderId) => {
            cancelOrder({ keyId, symbol, orderId })
        },
        [cancelOrder, keyId]
    )

    const handleOnSelectUser = (user) => setSelectedUser(user)
    const handleOnSelectTrade = (trade) => setSelectedTrade(trade)

    return (
        <div>
            <div className="w-full grid grid-cols-3 gap-3 xl:gap-5">
                <div className="col-span-2">
                    <TableUsers
                        dataSource={users}
                        onSelect={handleOnSelectUser}
                    />
                </div>

                <div>
                    {selectedUser && (
                        <Container className="mb-3 px-4 py-2">
                            <div>TRADER: {selectedUser.trader}</div>
                            <div className="flex justify-between mt-3">
                                <div>USER: {selectedUser.user}</div>
                                <div>KEY NAME: {selectedUser.keyname}</div>
                            </div>
                        </Container>
                    )}
                    <CreatePosition keyId={keyId} tradeData={selectedTrade} />
                </div>
            </div>

            {selectedUser && (
                <React.Fragment>
                    <SetSLTPModal
                        isManual={isManual}
                        emisorSelected={keyId}
                        visible={tradeToEditSlTP !== null}
                        tradeData={tradeToEditSlTP}
                        onCancel={() => setTradeToEditSLTP(null)}
                    />
                    <Tabs
                        menus={[
                            {
                                tabName: 'Active Trades',
                                tabIndex: 'activeOrders',
                            },
                            { tabName: 'Orders', tabIndex: 'orders' },
                        ]}
                        selected={tabSelected}
                        onSelect={(tabIndex) => setTabSelected(tabIndex)}
                    />
                    <div className="mt-6">
                        {tabSelected === 'activeOrders' && (
                            <TableUserOrders
                                dataSource={activeTrades[keyId] ?? []}
                                hideColumns={['orderPrice', 'cancelOrder']}
                                onSelect={handleOnSelectTrade}
                                onClosePosition={handleOnClosePosition}
                                enableEditSlTP={true}
                                emisorSelected={keyId}
                                onEditSlTp={(value) => {
                                    setTradeToEditSLTP(value)
                                    setIsManual(true)
                                }}
                            />
                        )}
                        {tabSelected === 'orders' && (
                            <TableUserOrders
                                dataSource={activeOrders[keyId] ?? []}
                                hideColumns={[
                                    'closePosition',
                                    'positionValue',
                                    'entryprice',
                                    'liqPrice',
                                    'leverage',
                                    'pnl',
                                    'pnlusd',
                                ]}
                                onSelect={handleOnSelectTrade}
                                onClosePosition={handleOnCancelOrder}
                            />
                        )}
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

export default PositionResolverView
