import { useCall } from '@usedapp/core'

import { useERC721IgniiteContract } from '../useContract'
import { NFT_ADDRESS_IGNIITE } from '../../../constants/addressConstants'
import { useEffect, useCallback, useState, useMemo, useRef } from 'react'
import { ipfsReplaceUri } from 'src/utils/ipfs'
import { sleep } from './../../../utils/promise'

const useFetchNft = (tokenId) => {
    const [loading, setLoading] = useState(false)
    const [nft, setNft] = useState(undefined)
    const currentTokenId = useRef(null)

    const contract = useERC721IgniiteContract(NFT_ADDRESS_IGNIITE)
    const { value, error } =
        useCall(
            tokenId && {
                contract,
                method: 'tokenURI',
                args: [tokenId],
            }
        ) ?? {}

    const fetchNft = useCallback(async () => {
        if (!value || !value?.[0]) {
            setNft(undefined)
            return
        }
        if (tokenId === currentTokenId.current) return
        currentTokenId.current = tokenId

        setLoading(true)
        await sleep(1000)
        const nftData = await fetch(ipfsReplaceUri(value?.[0])).then((res) =>
            res.json()
        )

        setNft({ ...nftData, image: ipfsReplaceUri(nftData.image) })
        setLoading(false)
    }, [value, tokenId])

    useEffect(() => {
        if (!tokenId && nft) {
            setNft(undefined)
            currentTokenId.current = null
        }
    }, [tokenId, nft])

    useEffect(() => {
        fetchNft()
    }, [value])

    const _error = useMemo(() => {
        if (error) {
            console.error('Error', error.message)
        }
        return error?.message
    }, [error])

    return { nft, reload: fetchNft, loading, error: _error }
}

export default useFetchNft
