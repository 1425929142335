import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { FaSpinner } from 'react-icons/fa'

const classes = {
    base: 'inline-flex items-center justify-center focus:outline-none transition ease-in-out duration-300 rounded-sm font-semibold',
    disabled: 'opacity-50 cursor-not-allowed',
    pill: 'rounded-full',
    size: {
        small: 'px-2 py-1 text-sm',
        normal: 'px-4 py-2',
        large: 'px-8 py-3 text-lg',
    },
    // bg-palette-black-725 hover:bg-palette-black-725/80
    variant: {
        light: 'bg-palette-black-725 border border-palette-brown-901  hover:border-palette-brown-901/80 focus:ring-2 focus:ring-palette-brown-901 focus:ring-opacity-50 text-palette-brown-901',
        primary:
            'bg-palette-brown-901 hover:bg-palette-brown-901/80 focus:ring-2 focus:ring-palette-brown-901 focus:ring-opacity-50 text-white',
        secondary:
            'bg-palette-black-700 hover:bg-palette-black-700/80 focus:ring-2 focus:ring-palette-gray-700 focus:ring-opacity-50 text-palette-brown-901 border border-palette-brown-901',
        dark: 'bg-palette-black-725 hover:bg-palette-black-725/80 focus:ring-2 focus:ring-palette-black-725 focus:ring-opacity-50 text-palette-gray-700',
        success:
            'bg-palette-green-500 hover:bg-palette-green/80 focus:ring-2 focus:ring-palette-green-500 focus:ring-opacity-50 text-white',
        danger: 'bg-palette-pink-700 hover:bg-palette-pink-700/80 focus:ring-2 focus:ring-palette-pink-700 focus:ring-opacity-50 text-white',
        gray: 'bg-palette-pink-700 hover:bg-palette-pink-700/80 focus:ring-2 focus:ring-palette-pink-700 focus:ring-opacity-50 text-white',
        // light: 'bg-palette-gray-100 hover:bg-palette-gray-100/80 focus:ring-2 focus:ring-primary focus:ring-opacity-50 text-palette-blue-300 border border-palette-blue-300',
    },
    spinnerSize: {
        small: 'text-xs',
        normal: 'text-2xl',
        large: 'text-3xl',
    },
}

const Button = forwardRef(
    (
        {
            children,
            className,
            loading = false,
            disabled,
            type = 'button',
            size = 'small',
            variant = 'primary',
            pill,
            spinnerSize = 'normal',
            ...restProps
        },
        ref
    ) => {
        return (
            <button
                ref={ref}
                disabled={disabled}
                type={type}
                className={classNames(`${classes.base}`, {
                    [classes.size[size]]: !!classes.size[size],
                    [classes.variant[variant]]: !!classes.variant[variant],
                    [classes.pill]: pill,
                    [classes.disabled]: disabled,
                    [className]: !!className,
                })}
                {...restProps}
            >
                {loading && (
                    <FaSpinner
                        className={classNames('animate-spin mr-3', {
                            [classes.spinnerSize[spinnerSize]]:
                                !!classes.spinnerSize[spinnerSize],
                        })}
                    />
                )}
                {children}
            </button>
        )
    }
)

Button.displayName = 'Button'

export default Button
