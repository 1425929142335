import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { notification } from "antd";
import axiosInstance from "src/store/services/axiosConfig";
import { formatNumberToCurrency } from "src/utils/formatNumber";

export const refreshStaff = createAction('staff/refresh')

export const getSalesStaffMembers = createAsyncThunk(
    'staff/salesStaffMembers',
    async(
        _,
        {rejectWithValue}
    ) => {
        try {
            const res = await axiosInstance.get('epochs/stafflist/')
            return res.data 
            
        } catch (error) {
            if(!error.message) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getStaffClients = createAsyncThunk(
    'staff/staffClients',
    async(
        _,
        {rejectWithValue}
    ) => {
        try {
            const res = await axiosInstance.get('epochs/clientsbystaff/')
            return res.data.data

        } catch (error) {
            if (!error.message) throw error
            return rejectWithValue(error.response.data)
            
        }

    }
)

export const registerStaff = createAsyncThunk(
    'staff/registerstaff',
    async ({ user, password, email }, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('epochs/registerstaff/', {
                username: user,
                email,
                password,
            })

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)
export const registerClient = createAsyncThunk(
    'admin/registerClient',
    async({user, password, email, seller, epoch, initialBalance}, {rejectWithValue}) => {
        try {
            const res = await axiosInstance.post('epochs/registerclient/', {
                username: user,
                email,
                password,
                seller,
                epoch,
                user_balance: initialBalance
            })
            return res.data
        } catch (err) {
            if (!err.response) throw err
            return rejectWithValue(err.response.data)
        }
    }
)

export const addUserToEpoch = createAsyncThunk(
    'admin/addUserToEpoch',
    async ({ user, epoch, initialBalance }, { rejectWithValue }) => {
        try {
            console.log("posting...", user, epoch, initialBalance)
            const res = await axiosInstance.post('epochs/addusertoepoch/', {
                user,
                epoch,
                user_balance: initialBalance
            })
            return res.data
        } catch (err) {
            if (!err.response) throw err
            return rejectWithValue(err.response.data)
        }
    }
)

export const staffDetails = createAsyncThunk(
    'staff/staffDetails',
    async({selectedEpoch}, {rejectWithValue}) => {
        try {
            const res = await axiosInstance.post('epochs/staffdetails/', {
                epoch: selectedEpoch
            })
            return res.data.data
        } catch (err) {
            if(!err.response) throw err
            return rejectWithValue(err.response.data)
        }

    }
)
export const staffClientEpochs = createAsyncThunk(
    'staff/clientepochs',
    async(_, {rejectWithValue}) => {
        try {
            const res = await axiosInstance.get('epochs/clientepochs/')
            return res.data.data.epochs
        } catch (err) {
            if (!err.response) throw err
            return rejectWithValue(err.response.data)            
        }
    }
)

export const selectEpoch = createAction('staff/selectEpoch')
export const epochQuantitySelect = createAction('staff/epochQuantity')

export const getEpochBalances = createAsyncThunk(
    'staff/getEpochBalances',
    async ({ epochQuantity }, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post('epochs/epochsbalance/', {
                quantity: Number(epochQuantity),
            })
            return res.data.data
        } catch (err) {
            if (!err.response) throw err
            return rejectWithValue(err.response.data)
        }
    }
)

export const getSellerComission = createAsyncThunk(
    'staff/getSellerComission',
    async({selectedEpoch}, {rejectWithValue}) => {
        try {
            const res = await axiosInstance.post('epochs/sellercomission/', {
                epoch: selectedEpoch,
            })
            return res.data.data
        } catch (err) {
            if (!err.response) throw err
            return rejectWithValue(err.response.data)            
        }
    }
)

export const createSellerPayment = createAsyncThunk(
    'tradesHistory/createSellerPayment',
    async ({ epoch, amount }, { rejectWithValue }) => {
        try {
            await axiosInstance.post(
                'epochs/createsellerpayment/',
                {
                    epoch,
                    amount,
                }
            )
            notification.success({
                message: 'Payment succeed',
                description:
                    `You successfully claimed ${formatNumberToCurrency(amount)} USD from Epoch ${epoch}`,
                placement: 'bottomRight',
            })        
            return amount 
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)