/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react'
import { FiInbox } from 'react-icons/fi'
import { formatNumber } from './../../utils/formatNumber'
import dayjs from 'dayjs'
import {
    useHistoryTradesState,
    useGetTradesHistory,
    useGetAllTrades,
} from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import { Pagination } from 'antd'
import axiosInstance from 'src/store/services/axiosConfig'
import { YEARLY } from 'src/constants/temporality.constants'
import {
    getTradesHistory,
    deleteTrade,
} from 'src/store/reducers/historyTradesReducer/historyTrades.actions'
import { useDispatch } from 'react-redux'


const RenderColums = ({ element, handleDelete }) => {
    return (
        <tr className="text-center text-palette-gray-100 font-semibold text-xs border-b-2 border-palette-black-700 bg-palette-black-800 ">
            <td className="py-8 font-semibold">
                {dayjs(element.date).format('MMMM D, YY')}
            </td>
            <td className="py-8 font-semibold">{element.pair}</td>
            <td
                className={`py-8 font-semibold	${
                    element.type === 'Buy'
                        ? 'text-palette-green-500 p-3'
                        : 'text-palette-red-100 p-3'
                }`}
            >
                {element.type === 'Buy' ? 'Long' : 'Short'}
            </td>
            <td
                className={`py-8 font-semibold ${
                    element.qty > 0
                        ? 'text-palette-green-500 p-3'
                        : 'text-palette-pink-700 p-3'
                }`}
            >
                {element.qty > 0
                    ? `${formatNumber(element.qty)}`
                    : `-${formatNumber(element.qty)}`}
            </td>
            <td className="py-8 font-medium	text-palette-gray-00 ">
                {element.epoch_id}
            </td>

            {/* <td
                className={`py-8 font-medium ${
                    element.pnlUsd > 0
                        ? 'text-palette-green-500 p-3'
                        : 'text-palette-pink-700 p-3'
                }`}
            >
                {element.growth} %
            </td> */}
            <td className="py-8 flex justify-center">
                <div
                    className={`w-16 font-semibold rounded-full text-palette-red-500 border border-palette-red-600 cursor-pointer`}
                    onClick={() => handleDelete(element.id)}
                >
                    DELETE
                </div>
            </td>
        </tr>
    )
}

const TradesTablePullAdmin = ({
    headers,
    emptyMessage = '0 positions open',
}) => {
    // TODO: make this one single global variable
    // useGetAllTrades()
    // Settings for pagination
    const [currentPage, setCurrentPage] = useState(1)
    const { allTrades, refresh } = useHistoryTradesState()
    const postPerPage = 10
    

    const reversedTrades = [...allTrades].reverse()

    // Divide the array to show on the table
    const indexOfLastTrade = currentPage * postPerPage
    const indexOfFirstTrade = indexOfLastTrade - postPerPage
    const currentTrades = reversedTrades.slice(
        indexOfFirstTrade,
        indexOfLastTrade
    )
    
    // Reset page view each time a request with different temporality is made
    useEffect(() => {
        setCurrentPage(1)
    }, [allTrades])

   
    const dispatch = useDispatch()
    const handleDelete = async (id) => {
        try {
            const res = await dispatch(deleteTrade({id})).unwrap()
        } catch (error) {
            console.log(error)
        }
        
    }

    return (
        <div className=" bg-palette-black-700 overflow-x-auto">
            <div className="flex justify-between mb-3">
                <div className="mt-1 lg:mt-6 text-palette-gray-100 font-medium tracking-widest text-sm">
                    HISTORY
                </div>
                <Pagination
                    showSizeChanger={false}
                    current={currentPage}
                    pageSize={postPerPage}
                    total={reversedTrades.length}
                    onChange={(page) => setCurrentPage(page)}
                />
            </div>
            <table className="table-auto border border-collapse border-palette-black-700 w-full rounded-t-lg overflow-hidden">
                <tbody>
                    <tr className="">
                        {headers.map((element) => {
                            return (
                                <th
                                    className="text-palette-gray-700 text-[11px] text-center align-middle py-5 bg-palette-black-725 px-3 "
                                    key={element}
                                >
                                    {element}
                                </th>
                            )
                        })}
                    </tr>
                    {(!currentTrades || currentTrades?.length === 0) && (
                        <tr className="text-center text-palette-gray-100 font-semibold text-xs border-b-2 border-palette-black-700 bg-palette-black-800 ">
                            <td
                                colSpan={headers.length}
                                className="py-8 text-center px-3"
                            >
                                <div className="flex justify-center items-center">
                                    {emptyMessage}{' '}
                                    <FiInbox className="text-3xl ml-1" />
                                </div>
                            </td>
                        </tr>
                    )}
                    {currentTrades &&
                        currentTrades.map((value, index) => {
                            return (
                                <RenderColums
                                    key={`tt-${index}`}
                                    element={value}
                                    handleDelete={handleDelete}
                                />
                            )
                        })}
                </tbody>
            </table>
        </div>
    )
}

export default TradesTablePullAdmin
