import axiosInstance from 'src/store/services/axiosConfig'
import { createAsyncThunk, createAction } from '@reduxjs/toolkit'

export const registerPaymentMethod = createAsyncThunk(
    'payments/registerPaymentMethod',
    async ({ alias, address }, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post(
                'bybit/payment/registerpaymentmethod/',
                { alias, address }
            )
            return res.data
        } catch (error) {
            if (!error.response) throw error

            return rejectWithValue(error.response.data)
        }
    }
)

export const getPaymentsMethods = createAsyncThunk(
    'payments/getPaymentsMethods',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get(
                'bybit/payment/getpaymentmethodbyuser/'
            )
            return res.data
        } catch (error) {
            if (!error.response) throw error

            return rejectWithValue(error.response.data)
        }
    }
)

export const removePaymentMethod = createAction('payments/removePaymentMethod')
export const deletePaymentMethod = createAsyncThunk(
    'payments/deletePaymentMethod',
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.delete(
                `bybit/payment/deletepaymentmethod/${id}`
            )
            dispatch(removePaymentMethod(id))
            return res.data
        } catch (error) {
            if (!error.response) throw error

            return rejectWithValue(error.response.data)
        }
    }
)

export const getPaymentOrderHistory = createAsyncThunk(
    'payments/getpPaymentOrderHistory',
    async (id, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/payment/getpaymentorderhistorybyuser/`
            )

            return res.data
        } catch (error) {
            if (!error.response) throw error

            return rejectWithValue(error.response.data)
        }
    }
)

export const getUserPaymentPending = createAsyncThunk(
    'payments/getpaymentpending',
    async (_, { rejectWithValue, dispatch, getState }) => {
        try {
            const {
                userInfoReducer: { id },
            } = getState()
            const res = await axiosInstance.get(
                `bybit/payment/getpaymentpending/${id}`
            )

            return res.data
        } catch (error) {
            if (!error.response) throw error

            return rejectWithValue(error.response.data)
        }
    }
)

export const updateTime = createAction('payments/updateTime')
export const setOverDue = createAction('payments/setOverDue')
