import classNames from 'classnames'

const ButtonTemporality = ({ selected, children, ...props }) => {
    return (
        <button
            {...props}
            className={classNames(
                'bg-palette-black-800 w-full h-12 md:h-full lg:px-8 py-[0.2rem] font-semibold text-xs lg:text-sm',
                {
                    'text-palette-brown-901 border border-palette-brown-901':
                        selected,
                    'text-palette-gray-700': !selected,
                }
            )}
        >
            {children}
        </button>
    )
}

export default ButtonTemporality
