/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react'
import ButtonPeriodicity from './ButtonPeriodicity'

import { MONTHLY, BIMONTHLY, QUARTERLY, BIANNUAL, ANNUAL, CONTRACT_TERM } from 'src/constants/Periodicity.constants'

const Periodicity = ({ selected, onSelect }) => {
    return (
        <div>
            <div className={'w-full mb-3 md:w-auto space-x-2 flex flex-row lg:ml-auto'}>
                <div className={'space-x-2 flex flex-row w-full lg:w-auto'}>
                    <ButtonPeriodicity
                        onPointerDown={() => {
                            onSelect(MONTHLY)}
                        }
                        selected={MONTHLY === selected}
                    >
                        MONTHLY
                    </ButtonPeriodicity>
                    <ButtonPeriodicity
                        onPointerDown={() => {
                            onSelect(BIMONTHLY)}
                        }
                        selected={BIMONTHLY === selected}
                    >
                        BIMONTHLY
                    </ButtonPeriodicity>
                </div>
            </div>
            <div className={ 'w-full mb-3 md:w-auto space-x-2 flex flex-row lg:ml-auto' } >
                <div className={'space-x-2 flex flex-row w-full lg:w-auto'}>
                    <ButtonPeriodicity
                        onPointerDown={() => onSelect(QUARTERLY)}
                        selected={QUARTERLY === selected}
                    >
                        QUARTERLY
                    </ButtonPeriodicity>
                    <ButtonPeriodicity
                        onPointerDown={() => onSelect(BIANNUAL)}
                        selected={BIANNUAL === selected}
                    >
                        BIANNUAL
                    </ButtonPeriodicity>
                </div>
            </div>
            <div className={'w-full mb-3 md:w-auto space-x-2 flex flex-row lg:ml-auto'}>
                <div className={'space-x-2 flex flex-row w-full lg:w-auto'}>
                    <ButtonPeriodicity
                        onPointerDown={() => onSelect(ANNUAL)}
                        selected={ANNUAL === selected}
                    >
                        ANNUAL
                    </ButtonPeriodicity>
                    <ButtonPeriodicity
                        onPointerDown={() => onSelect(CONTRACT_TERM)}
                        selected={CONTRACT_TERM === selected}
                    >
                        CONTRACT_TERM
                    </ButtonPeriodicity>
                </div>
            </div>
        </div>
    )
}

export default Periodicity
