import ConfirmModal from './../../Modals/GenericModals/ConfirmModal'

const ConfirmMintModal = ({ visible, onClose, onConfirm }) => {
    return (
        <ConfirmModal
            visible={visible}
            onClose={onClose}
            onConfirm={onConfirm}
            confirmButtonText="Mint"
        >
            <div className="px-2">
                <div className="text-palette-brown-901 font-bold text-3xl text-center mx-auto">
                    IMPORTANT MINTING INFO!
                </div>
                <div className="mt-6">
                    {`Once you click on "Mint" button it will ask you for
                    permission a couple of times:`}
                </div>
                <div className="mt-3 text-justify">
                    1.- Permissions to spend your USDTs, this will show the
                    amount to be authorized and who is authorized to spend it
                    (in this case our contract) you must confirm it and wait for
                    the second permission window.
                </div>
                <div className="mt-4 text-justify">
                    2.- Authorization to mint and transfer the USDT authorized
                    amount, this process will transfer the authorized amount of
                    USDT from your wallet to our contract and start the
                    automatic mint process.
                </div>
            </div>
        </ConfirmModal>
    )
}
export default ConfirmMintModal
