/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react'
import * as echarts from 'echarts/core'
import ReactEcharts from 'echarts-for-react'
import { TooltipComponent, TitleComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { categories, links, mobileTitles } from '../../constants/graphConstants'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

// Register the required components
echarts.use([TitleComponent, TooltipComponent, CanvasRenderer])

export const WinsxLossesDetailsMobile = ({ graphicData }) => {

    const data = [
        {
            name: `${graphicData.tradesNum}`,
            id: 'Number of trades',
            x: 300,
            y: 300,
            category: 0,
        },
        {
            id: 'WINS',
            name: `${graphicData.wins}`,
            x: 500,
            y: 500,
            category: 1,
        },
        {
            id: 'Biggest Win',
            name: `${formatNumberToCurrency(graphicData.biggestWin)}`,
            x: 500,
            y: 700,
            category: 3,
        },
        {
            id: 'totalWins',
            name: `${formatNumberToCurrency(graphicData.totalWins)}`,
            x: 500,
            y: 900,
            category: 3,
        },

        {
            id: 'LOSSES',
            name: `${graphicData.losses}`,
            x: 100,
            y: 500,
            category: 2,
        },
        {
            id: 'Biggest Loss',
            name: `${formatNumberToCurrency(graphicData.biggestLoss)}`,
            x: 100,
            y: 700,
            category: 4,
        },
        {
            id: 'Total Losses USDT',
            name: `${formatNumberToCurrency(graphicData.totalLosses)}`,
            x: 100,
            y: 900,
            category: 4,
        },
    ]

    const option = {
        title: mobileTitles,
        tooltip: {},
        series: [
            // values
            {
                type: 'graph',
                layout: 'none',
                top: '20%',
                bottom: '15%',
                textStyle: {
                    fontFamily: "'Maven Pro', 'sans-serif'",
                },

                tooltip: {
                    show: false,
                },
                label: {
                    show: true,
                    fontWeight: 600,
                    fontFamily: "'Maven Pro', 'sans-serif'",
                    fontSize: 12,
                },

                data: data,
                links: links,
                categories: categories
            },
        ],
    }

    return (
        <div className=" col-span-12 h-full lg:w-[665px] mb-3 pt-5 rounded border-palette-black-725 border-2 bg-palette-black-600">
            <div className="text-[16px] font-normal text-palette-gray-100 font-maven">
                WINS & LOSSES DETAILS
            </div>
            <ReactEcharts className='h-[450px]' option={option} />
        </div>
    )
}
