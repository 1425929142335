import { createReducer } from "@reduxjs/toolkit";
import { epochQuantitySelect, getCustomerEpochBalances } from "./customer.actions";

export const initialState = {
    epochQuantity: 'ALL',
    epochsBalances: [],
}

const customerReducer = createReducer(initialState, (builder) => {
    builder      
        .addCase(epochQuantitySelect, (state, { payload }) => {
            state.epochQuantity = payload
        })
        .addCase(getCustomerEpochBalances.fulfilled, (state, { payload }) => {
            state.epochsBalances = payload
        })
})

export default customerReducer