import React from 'react'
import ReactPlayer from 'react-player'
import { TbRefresh } from 'react-icons/tb'
import { FaSpinner } from 'react-icons/fa'

const NFTDisplay = ({ nft, loading }) => {
    let content = (
        <ReactPlayer
            width="100%"
            height="100%"
            controls={false}
            playing={true}
            url={nft}
            playsinline
            muted={true}
            loop
        />
    )

    if (loading)
        content = <FaSpinner className="animate-spin text-2xl text-white" />
    if (!nft && loading)
        content = (
            <div className="cursor-pointer flex justify-center items-center w-full h-full">
                {' '}
                <TbRefresh className="text-3xl text-white" />
            </div>
        )

    return <React.Fragment>{content}</React.Fragment>
}

export default NFTDisplay
