import React, { useCallback } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { deleteClosePosition } from 'src/store/reducers/dashboardReducer/dashboard.actions'
import { AiOutlineStop } from 'react-icons/ai'
import { FiInbox } from 'react-icons/fi'
import { getSymbolFormat } from './../../utils/symbolUtilities'
import {
    formatNumber,
    formatNumberToCurrency,
    formatNumberToPercentage,
} from './../../utils/formatNumber'

const RenderColums = ({ element }) => {
    // const [deleting, setDeleting] = useState(false)
    const dispatch = useDispatch()

    const closePosition = useCallback(
        (symbol) => dispatch(deleteClosePosition({ symbol })),
        [dispatch]
    )
    // const eventMonitor = (symbol, side) => {
    //     monitorAction(symbol, side)
    //         .unwrap()
    //         .then(() => {
    //             console.log('monitoreando')
    //         })
    //         .catch((error) => {
    //             window.alert('error en el back' + error)
    //         })
    // }
    const eventClosePosition = async (symbol) => {
        // setDeleting(true)
        try {
            await closePosition(symbol).unwrap()
        } catch (error) {
            console.log(error)
        } finally {
            // setDeleting(false)
        }
    }

    const symbolFormat = getSymbolFormat(element.symbol)

    return (
        <tr className="text-center text-palette-gray-100 font-semibold text-xs border-b-2 border-palette-black-700 bg-palette-black-800 ">
            <td className="py-8">{element.symbol}</td>
            <td
                className={`py-8 ${
                    element.side === 'Buy'
                        ? 'text-palette-green-500 p-3'
                        : 'text-palette-pink-700 p-3'
                }`}
            >
                {element.side === 'Buy' ? 'Long' : 'Short'}
            </td>
            <td className="py-8 text-palette-yellow-825">
                {formatNumber(element.leverage)} X
            </td>
            <td className="py-8 ">{formatNumber(element.quantity)}</td>

            <td className="py-8">
                {formatNumberToCurrency(element.positionValue, symbolFormat)}{' '}
                USD
            </td>
            <td className="py-8">
                {formatNumberToCurrency(element.entryprice, symbolFormat)} USD
            </td>
            <td className="py-8 text-palette-brown-700">
                {formatNumberToCurrency(element.liqPrice, symbolFormat)} USD
            </td>
            <td
                className={`$py-8 ${
                    element.pnl > 0
                        ? 'text-palette-green-500'
                        : 'text-palette-pink-700'
                }`}
            >
                <div className="">
                    <div>{formatNumberToCurrency(element.pnlusd)} USD</div>
                    <div>{formatNumberToPercentage(element.pnl)}</div>
                </div>
            </td>
            <td className="py-8">
                {formatNumber(element.tp, symbolFormat)} /{' '}
                {formatNumber(element.sl, symbolFormat)}
            </td>

            <td className="py-8">
                <button
                    key={element.id}
                    disabled
                    onClick={() => eventClosePosition(element.symbol)}
                    className="text-palette-gray-700 hover:text-palette-pink-700 text-2xl disabled:cursor-not-allowed"
                >
                    <AiOutlineStop />
                </button>
            </td>
        </tr>
    )
}

const TradesTable = ({
    headers,
    values,
    emptyMessage = '0 positions open',
}) => {
    const activeTrades = useSelector(
        (state) => state.dashboardReducer.activeTrades
    )
    return (
        <div className="mt-2 bg-palette-black-700 overflow-x-auto">
            <table className="table-auto border border-collapse border-palette-black-700 w-full rounded-t-lg overflow-hidden">
                <tr className="">
                    {headers.map((element) => {
                        return (
                            <th
                                className="text-palette-gray-700 text-[11px] text-center align-middle py-5 bg-palette-black-725 px-3 "
                                key={element}
                            >
                                {element}
                            </th>
                        )
                    })}
                </tr>
                {(!values || values?.length === 0) && (
                    <tr className="text-center text-palette-gray-100 font-semibold text-xs border-b-2 border-palette-black-700 bg-palette-black-800 ">
                        <td
                            colSpan={headers.length}
                            className="py-8 text-center px-3"
                        >
                            <div className="flex justify-center items-center">
                                {emptyMessage}{' '}
                                <FiInbox className="text-3xl ml-1" />
                            </div>
                        </td>
                    </tr>
                )}
                {values &&
                    values
                        .filter(
                            ({ symbol }) =>
                                !activeTrades
                                    .map((v) => v.symbol)
                                    .includes(symbol)
                        )
                        .map((value, index) => {
                            return (
                                <RenderColums
                                    key={`tt-${value.id}`}
                                    element={value}
                                />
                            )
                        })}
            </table>
        </div>
    )
}

export default TradesTable
