/* eslint-disable no-unused-vars */
import React from 'react'

import Stats5 from '../../components/DataComponents/Stats5'
// import { Doughnut } from 'react-chartjs-2'

import { convertToUSD } from '../../utils/formatter'
import {
    Chart,
    ArcElement,
    CategoryScale,
    LinearScale,
    Title,
    Tooltip,
    BarElement,
} from 'chart.js'
import { Table } from 'antd'
import {
    useAdminState,
    useFetchReferral,
} from 'src/store/reducers/adminReducer/admin.hooks'

import { useNavigate } from 'react-router-dom'
import Button from 'src/components/Buttons/Button'
import TableInComingComissions from 'src/components/AdminComponents/TableInComingComissions'
import TableLastComissions from '../../components/AdminComponents/TableLastComissions'
import TableRefers from 'src/components/AdminComponents/TableRefers'

Chart.register(
    ArcElement,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip
)

const AdminTrader = (props) => {
    useFetchReferral()
    const {
        referrals,
        totalProfitReferral,
        totalCommissionReferral,
        incomingCommisions,
        lastCommissions,
    } = useAdminState()
    const navigation = useNavigate()

    return (
        <div className="w-full flex flex-col gap-3 xl:gap-5">
            <div>
                <div className="flex flex-row gap-4 w-full mt-2">
                    <div className="flex flex-col w-full  overflow-x-auto">
                        <div className="flex flex-row gap-4 w-full">
                            <Stats5
                                title={'Fees Paid'}
                                value={Number(totalCommissionReferral)}
                                hidePercent
                            />
                            <Stats5
                                title={'Due Fees'}
                                value={Number(totalProfitReferral)}
                                hidePercent
                            />
                            <Stats5
                                title={'My Commissions'}
                                value={Number(totalCommissionReferral)}
                                hidePercent
                            />
                            <Stats5
                                title={'Users PNL'}
                                value={Number(totalProfitReferral)}
                                hidePercent
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="">
                <div className="mt-1 lg:mt-6 text-palette-gray-100 font-medium text-sm">
                    MY REFERS
                </div>{' '}
                <TableRefers
                    dataSource={referrals}
                    hideColumns={['bybitRefer']}
                />
            </div>
            <div className="">
                <div className="text-palette-gray-100 font-medium text-sm">
                    INCOMING COMMISSIONS
                </div>
                <TableInComingComissions dataSource={incomingCommisions} />
            </div>
            <div>
                <div className="text-palette-gray-100 font-medium text-sm">
                    LAST COMISIONS
                </div>
                <TableLastComissions dataSource={lastCommissions} />
            </div>

            <div></div>
            <div></div>
        </div>
    )
}

export default AdminTrader
