import { Popconfirm } from 'antd'
import { AiOutlineStop } from 'react-icons/ai'

import Button from 'src/components/Buttons/Button'
import CustomTable from './CustomTable'
import {
    formatNumber,
    formatNumberToCurrency,
    formatNumberToPercentage,
} from 'src/utils/formatNumber'
import Icon from 'src/components/Icons/Icon'
import { getSymbolFormat } from './../../utils/symbolUtilities'

const TableUserOrders = ({
    dataSource,
    hideColumns = [],
    onSelect,
    onClosePosition,
    onEditSlTp,
    enableEditSlTP = false,
}) => {
    const columns = [
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            render: (value) => (
                <span className="font-bold text-base">{value}</span>
            ),
        },
        {
            title: 'Side',
            dataIndex: 'side',
            key: 'side',
            render: (_, record) => (
                <span
                    className={`py-8 ${
                        record.side === 'Buy'
                            ? 'text-palette-green-500 p-3'
                            : 'text-palette-pink-700 p-3'
                    }`}
                >
                    {sideText(record)}
                </span>
            ),
        },
        {
            title: 'Qty',
            dataIndex: 'quantity',
            key: 'value',
            render: (value) => <span>{formatNumber(value)}</span>,
        },
        {
            title: 'Position Value',
            dataIndex: 'positionValue',
            key: 'positionValue',
            render: (value, record) => (
                <span>
                    {formatNumberToCurrency(value, record.symbolFormat)}
                </span>
            ),
        },
        {
            title: 'Entry Price',
            dataIndex: 'entryprice',
            key: 'entryprice',
            render: (value, record) => (
                <span>
                    {formatNumberToCurrency(value, record.symbolFormat)}
                </span>
            ),
        },
        {
            title: 'Order Price',
            dataIndex: 'orderPrice',
            key: 'orderPrice',
            render: (value, record) => (
                <span>
                    {formatNumberToCurrency(value, record.symbolFormat)}
                </span>
            ),
        },
        {
            title: 'Liquidation Price',
            dataIndex: 'liqPrice',
            key: 'liqPrice',
            render: (value, record) => (
                <span>
                    {formatNumberToCurrency(value, record.symbolFormat)}
                </span>
            ),
        },
        {
            title: 'Leverage',
            dataIndex: 'leverage',
            key: 'leverage',
            render: (value, record) => (
                <div>
                    <span className="text-tempname-purple-3 font-bold tracking-wider">
                        {value}x
                    </span>
                    <span className="text-tempname-purple-3 font-bold tracking-wider">
                        {record.isolated ? ' Isolated' : ' Cross'}
                    </span>
                </div>
            ),
        },
        {
            title: 'PNL %',
            dataIndex: 'pnl',
            key: 'pnl',
            render: (value) => (
                <span
                    className={`py-8 ${
                        value >= 0
                            ? 'text-palette-green-500 p-3'
                            : 'text-palette-pink-700 p-3'
                    }`}
                >
                    {formatNumberToPercentage(value)}
                </span>
            ),
        },
        {
            title: 'PNL USD',
            dataIndex: 'pnlusd',
            key: 'pnlusd',
            render: (value) => (
                <span
                    className={`py-8 ${
                        value >= 0
                            ? 'text-palette-green-500 p-3'
                            : 'text-palette-pink-700 p-3'
                    }`}
                >
                    {formatNumberToCurrency(value)}
                </span>
            ),
        },
        {
            title: 'SL/TP',
            dataIndex: 'tpsl',
            key: 'tpsl',
            render: (_, record) => {
                return (
                    <div className="flex flex-row justify-center">
                        <span className="mr-2">
                            {formatNumberToCurrency(
                                record.sl,
                                record.symbolFormat
                            )}
                            /
                            {formatNumberToCurrency(
                                record.tp,
                                record.symbolFormat
                            )}
                        </span>
                        {enableEditSlTP && (
                            <button onClick={() => onEditSlTp(record)}>
                                <Icon
                                    name="3-edit"
                                    className="h-4 w-4 hover:text-experts-green-1 delay-150 duration-300"
                                />
                            </button>
                        )}
                    </div>
                )
                // <span className="">
                //     {record.sl}/{record.tp}
                // </span>
            },
        },

        {
            title: 'Close Position',
            dataIndex: 'closePosition',
            key: 'actionPosition',
            render: (_, record) => (
                <Popconfirm
                    placement="top"
                    title="Are you sure you want to close this position?"
                    onConfirm={() => onClosePosition(record.symbol)}
                >
                    <Button variant="danger" className="text-2xl">
                        <AiOutlineStop />
                    </Button>
                </Popconfirm>
            ),
        },
        {
            title: 'Cancel order',
            dataIndex: 'cancelOrder',
            key: 'actionOrder',
            render: (_, record) => (
                <Popconfirm
                    placement="top"
                    title="Are you sure you want to cancel this order?"
                    onConfirm={() =>
                        onClosePosition(record.symbol, record.orderId)
                    }
                >
                    <Button variant="danger" className="text-2xl">
                        <AiOutlineStop />
                    </Button>
                </Popconfirm>
            ),
        },
    ].map((v) => ({ ...v, align: 'center' }))

    const sideText = (record) => {
        if (record.closeOrder === true) {
            if (record.side === 'Buy') {
                return 'Close Short'
            } else {
                return 'Close Long'
            }
        } else {
            if (record.side === 'Buy') {
                return 'Long'
            } else {
                return 'Short'
            }
        }
    }

    return (
        <CustomTable
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        onSelect(record)
                    }, // click row
                }
            }}
            columns={columns.filter((v) => !hideColumns.includes(v.dataIndex))}
            dataSource={dataSource.map((c) => {
                return { ...c, symbolFormat: getSymbolFormat(c?.symbol) }
            })}
        />
    )
}

export default TableUserOrders
