import React from 'react'
import { Input, Form } from 'antd'

import classNames from 'classnames'

const FormItem = Form.Item
const { TextArea } = Input

const defaultTransform = {
    input: (value) => value,
    output: (value) => value,
    blur: null,
}


export const FormikAntTextArea = ({
    formik,
    name,
    placeholder,
    value,
    className,
    showError = true,
    transform = defaultTransform,
    ...props
}) => {
    const touched = formik.touched[name]
    const hasError = formik.errors[name]
    const submittedError = hasError && formik.submitCount > 0
    const touchedError = hasError && touched

    const $transform = {
        ...defaultTransform,
        ...transform,
    }

    const $value = value ?? formik.values?.[name]
    const Validate = showError ? FormItem : React.Fragment

    return (
        <Validate
            validateStatus={
                submittedError || touchedError ? 'error' : 'success'
            }
            help={
                showError && (submittedError || touchedError) ? hasError : false
            }
        >
            {' '}
            <TextArea
                id={name}
                name={name}
                // bordered={bordered}
                // prefix={prefix}
                placeholder={placeholder}
                value={$transform.input($value)}
                className={classNames(
                    {
                        [className]: !!className,
                    }
                    // classes.base,
                    // {
                    //     [classes.variant[variant]]:
                    //         !!classes.variant[variant] !== 'none',
                    //     [classes.size[size]]: size !== 'none',
                    //     [className]: !!className,
                    // }
                    // 'outline'
                )}
                onChange={(e) => {
                    const {
                        target: { value, name },
                    } = e

                    formik.setValues({
                        ...formik.values,
                        [name]: $transform.output(value, formik.values[name]),
                    })
                }}
                onBlur={(e) => {
                    const {
                        target: { value, name },
                    } = e

                    formik.setTouched({ ...formik.touched, [name]: true })

                    if (!$transform.blur) return

                    formik.setValues({
                        ...formik.values,
                        [name]: $transform.blur(value, formik.values[name]),
                    })
                }}
                {...props}
                // style={{ fontSize: '12px' }}
            />
        </Validate>
    )
}
