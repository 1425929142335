/* eslint-disable no-unused-vars */
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
} from 'chart.js'
import { useMemo } from 'react'
import { Bar } from 'react-chartjs-2'


ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
)

const options = {
    maintainAspectRatio: false,
    scales: {
        x: {
            type: 'linear',
            offset: false,
            gridLines: {
                offsetGridLines: false,
            },
            // set the size of the column range
            ticks: {
                callback: function (val, index) {
                    if (val + 2 <= 0) {
                        return `(${Math.abs(val)})-(${Math.abs(val + 2)})`
                    } else {
                        return `${val}-${val + 2}`
                    }
                },
            },
            title: {
                display: true,
                text: 'Daily return (%)',
            },
        },
        y: {
            stacked: true,
            position: 'right',
            grid: {
                drawBorder: true,
                color: 'rgba(116, 116, 116, 0.2)',
                opacity: '0.5',
            },
            title: {
                display: true,
                text: 'Count',
            },
        },
    },

    plugins: {
        legend:{
            display: false
        },
        centerText: false,
        title: {
            display: true,
            align: 'start',
            color: '#BFBFBF',
            text: 'DISTRIBUTION OF GAINS AND LOSSES',
            padding: {
                bottom: 50,
            },
            font: {
                family: "'Maven Pro', 'sans-serif'",
                size: 16,
                weight: 400,
            },
        },
        growthCounter: false,
        // configuration for the tag when mouse hovers on bar
        tooltip: {
            callbacks: {
                title: (items) => {
                    if (!items.length) {
                        return ''
                    }
                    const item = items[0]
                    const x = item.parsed.x
                    const max = x + 2
                    return `${x}% - ${max}%`
                },
            },
        },
    },
}

const DashboardNormalDistribution = ({ graphicData, temporality }) => {
    const $graphicData = useMemo(() => {
        // function to create a range of values
        const range = (start, stop, step) => {
            return Array.from(
                { length: (stop - start) / step + 1 },
                (_, i) => start + i * step
            )
        }
        // array with dailyReturns values
        const dailyReturns = graphicData.map((trade) => trade.dailyReturn)

        // minumun value in daily Returns
        const minReturn = dailyReturns.reduce((acc, curr) => {
            return acc < curr ? acc : curr
        },0)

        // maximum value in returns
        const maxReturn = dailyReturns.reduce((acc, curr) => {
            return acc > curr ? acc : curr
        },0)

        // calculate the maximum absolute number
        const absMax = Math.max(Math.abs(minReturn), Math.abs(maxReturn))

        // get the closest multiple of 10
        const extreme = Math.ceil(absMax / 10) * 10

        // Create a range in step sizes of 2
        const labels = range(-extreme, extreme - 1, 2)

        // fill an array of values within the same range
        const bins = []
        for (let i = 0; i < labels.length; i++) {
            if (i > labels.length) {
                break
            }
            const bin = dailyReturns.filter((item) => {
                return item > labels[i] && item < labels[i + 1]
            })
            // labelsRange.push(`${labels[i]} - ${labels[i+1]}`)
            bins.push(bin)
        }
        // create an array with the count of the values in the bins array
        const data = bins.map((b) => b.length)
        return {
            labels: labels,
            datasets: [
                {
                    lineTension: 0,
                    fill: true,
                    borderJoinStyle: 'round',
                    data: data,
                    borderWidth: 0.2,
                    barPercentage: 0.8,
                    categoryPercentage: 1,
                    hoverBackgroundColor: 'darkgray',
                    barThickness: 'flex',
                    borderColor: 'rgb(141, 121, 63)',
                    backgroundColor: 'rgba(175,161,120, 0.5)',
                },
            ],
        }
    }, [graphicData, temporality])

    return (
        <div className="col-span-12 h-96 md:h.auto md:col-span-6 p-5 rounded border-palette-black-725 border-2 bg-palette-black-600">
            <Bar options={options} data={$graphicData} />
        </div>
    )
}

DashboardNormalDistribution.propTypes = {}

export default DashboardNormalDistribution
