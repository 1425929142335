/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
// import * as actions from 'src/store/reducers/dashboardReducer/dashboardReducer'
import SubscribeSaveKey from 'src/components/Modals/SubscribeModals/SubscribeSaveKey'
import { Select } from 'antd'
import FormikAntInput from '../FormikAntDInputs/FormikAntInput'
import { numberValidation } from '../../utils/inputValidations'
import Button from 'src/components/Buttons/Button'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { validations } from 'src/utils/yupValidations'
import classNames from 'classnames'
import { sleep } from 'src/utils/promise'
import { useActiveKey } from 'src/store/reducers/dashboardReducer/dashboard.hooks'
import {
    formatValueToNumber,
    formatValueToNumberOnChange,
} from './../../utils/inputsUtilities'
import {
    getKeys,
    saveKeys,
    subscribe,
} from 'src/store/reducers/dashboardReducer/dashboard.actions'

const { Option } = Select

const KeysComponent = ({ emisorId, stateButton, firstAccount }) => {
    const [showModal, setShowModal] = useState(false)

    const dispatch = useDispatch()

    const saveKeysAction = useCallback(
        (payload) => dispatch(saveKeys(payload)),
        [dispatch, emisorId]
    )

    const getKeysAction = useCallback(() => dispatch(getKeys()), [dispatch])
    const activeKey = useActiveKey()
    const subscribeKey = useCallback(
        (value) => dispatch(subscribe(value)),
        [dispatch]
    )

    const schema = yup.object().shape({
        name: validations.generic,
        key: validations.generic,
        secret: validations.generic,
        riskPorc: validations.generic,
    })

    const initialValues = {
        name: '',
        key: '',
        secret: '',
        btcEth: '100',
        altcoin: '100',
        riskPorc: '',
        maxTrades: '1',
    }

    const handleSubmit = useCallback((values, { setSubmitting }) => {
        setShowModal(true)
        setSubmitting(true)
    }, [])

    const formik = useFormik({
        initialValues,
        onSubmit: handleSubmit,
        validationSchema: schema,
    })

    const handleConfirmSave = useCallback(async () => {
        const { values } = formik
        setShowModal(false)
        try {
            const res = await saveKeysAction({
                ...values,
                linkedAccount: emisorId,
            }).unwrap()

            const { activeAccount } = res

            subscribeKey(activeAccount)
            getKeysAction()
            formik.resetForm()
            stateButton()
        } catch (error) {
            console.log({ error })
            window.alert('Failed to save key')
        }
        await sleep(600)
        formik.setSubmitting(false)
    }, [formik, emisorId])

    return (
        <div>
            <SubscribeSaveKey
                visible={showModal}
                onConfirmed={handleConfirmSave}
                onClose={() => {
                    if (formik.isSubmitting) formik.setSubmitting(false)
                    setShowModal(false)
                }}
            />
            <div className="mt-3 px-4 pt-5 pb-3 text rounded-md shadow-md bg-palette-black-700 border border-palette-beigde-100">
                <form onSubmit={formik.handleSubmit}>
                    <div>
                        {' '}
                        <div className="text-base text-palette-gray-100 mb-3">
                            {firstAccount ? 'Main' : 'Sub Account'}
                        </div>
                        <div className="flex flex-col lg:flex-row gap-2 md:gap-8">
                            <div className="flex-1 flex flex-col">
                                <div className="flex-1 flex flex-col md:flex-row gap-2 md:gap-8">
                                    <div className="flex-1">
                                        <FormikAntInput
                                            className="h-10"
                                            name="name"
                                            placeholder={'Name'}
                                            formik={formik}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <FormikAntInput
                                            className="h-10"
                                            placeholder={'Key'}
                                            name="key"
                                            formik={formik}
                                        />
                                    </div>
                                    <div className="flex-1">
                                        <FormikAntInput
                                            placeholder={'Secret'}
                                            className="h-10"
                                            name="secret"
                                            formik={formik}
                                        />
                                    </div>
                                </div>
                                <div className="text-xs text-palette-gray-700 font-semibold">
                                    ACCOUNT SETTINGS
                                </div>
                                <div className="flex-1 flex flex-col md:flex-row gap-2 md:gap-8 ">
                                    <div className="flex-1 flex flex-col">
                                        <FormikAntInput
                                            className="h-[38px] border-0 border-b  focus:outline-none border-palette-gray-100 bg-palette-black-700 text-center"
                                            name="btcEth"
                                            formik={formik}
                                            value={formik.values?.btcEth}
                                            variant="none"
                                            showError={false}
                                            bordered
                                            transform={{
                                                output: (value) => {
                                                    const $value =
                                                        formatValueToNumberOnChange(
                                                            value,
                                                            {
                                                                min: 1,
                                                                max: 100,
                                                                onlyInteger: true,
                                                                enableEmptyValue: true,
                                                            }
                                                        )
                                                    return $value
                                                },
                                                blur: (value) => {
                                                    return formatValueToNumber(
                                                        value,
                                                        '100'
                                                    )
                                                },
                                            }}
                                            // onChange={(e) => {
                                            //     const {
                                            //         target: { value, name },
                                            //     } = e
                                            //     const _value = numberValidation(
                                            //         value,
                                            //         2,
                                            //         1,
                                            //         100,
                                            //         true
                                            //     )

                                            //     if (_value)
                                            //         formik.setValues({
                                            //             ...formik.values,
                                            //             [name]: _value,
                                            //         })
                                            // }}
                                        />
                                        <div className="text-sm text-center  text-palette-gray-100 mt-2">
                                            BTC / ETH
                                        </div>
                                    </div>
                                    <div className="flex-1 flex flex-col">
                                        <FormikAntInput
                                            className="h-[38px] border-0 border-b  focus:outline-none border-palette-gray-100 bg-palette-black-700 text-center"
                                            name="altcoin"
                                            formik={formik}
                                            value={formik.values?.altcoin}
                                            variant="none"
                                            showError={false}
                                            bordered
                                            transform={{
                                                output: (value) => {
                                                    const $value =
                                                        formatValueToNumberOnChange(
                                                            value,
                                                            {
                                                                min: 1,
                                                                max: 100,
                                                                onlyInteger: true,
                                                                enableEmptyValue: true,
                                                            }
                                                        )
                                                    return $value
                                                },
                                                blur: (value) => {
                                                    return formatValueToNumber(
                                                        value,
                                                        '100'
                                                    )
                                                },
                                            }}
                                        />
                                        <div className="text-sm text-center  text-palette-gray-100 mt-2">
                                            ALTCOIN
                                        </div>
                                    </div>
                                    <div className="flex-1 flex flex-col">
                                        <Select
                                            value={
                                                formik.values?.riskPorc !== ''
                                                    ? formik.values?.riskPorc
                                                    : null
                                            }
                                            placeholder="Select a risk percent"
                                            style={{
                                                width: '100%',
                                                height: '40px',
                                            }}
                                            className={classNames(
                                                'border-0 border-b text-center rounded-sm',
                                                {
                                                    'ring-1 ring-red-600':
                                                        (formik.touched
                                                            .riskPorc &&
                                                            formik.errors
                                                                .riskPorc) ||
                                                        (formik.errors
                                                            .riskPorc &&
                                                            formik.submitCount >
                                                                0),
                                                }
                                            )}
                                            size="large"
                                            // disabled={!edit}
                                            bordered={false}
                                            onChange={(val) => {
                                                formik.setValues({
                                                    ...formik.values,
                                                    riskPorc: val,
                                                })
                                            }}
                                        >
                                            <Option value="1">1%</Option>
                                            <Option value="2">2%</Option>
                                            <Option value="3">3%</Option>
                                        </Select>
                                        <div className="text-sm text-center  text-palette-gray-100 mt-2">
                                            MAX RISK %
                                        </div>
                                    </div>
                                    <div className="flex-1 flex flex-col">
                                        <Select
                                            defaultValue={
                                                formik.values?.maxTrades
                                            }
                                            style={{
                                                width: '100%',
                                                marginBottom: '0.25rem',
                                            }}
                                            className="border-0 border-b text-center rounded-sm"
                                            size="large"
                                            // disabled={!edit}
                                            bordered={false}
                                            onChange={(val) => {
                                                formik.setValues({
                                                    ...formik.values,
                                                    maxTrades: val,
                                                })
                                            }}
                                        >
                                            <Option value="1">1</Option>
                                            {/* <Option value="2">2</Option>
                                    <Option value="3">3</Option> */}
                                        </Select>
                                        <div className="text-sm text-center  text-palette-gray-100 mt-2">
                                            MAX TRADES
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full lg:w-1/4 flex flex-row gap-3 mt-3 lg:mt-0">
                                <Button
                                    disabled={formik.isSubmitting}
                                    onClick={() =>
                                        formik.resetForm(initialValues)
                                    }
                                    variant="none"
                                    className="w-full h-10 bg-palette-black-725 text-gra-700 hover:bg-black-725/80 focus:ring-2 focus:ring-palette-gray-100 focus:ring-opacity-50"
                                >
                                    BACK TO DEFAULT
                                </Button>
                                <Button
                                    disabled={formik.isSubmitting}
                                    type="submit"
                                    loading={formik.isSubmitting}
                                    variant="light"
                                    className="w-full h-10 bg-palette-black-700"
                                >
                                    SAVE
                                </Button>{' '}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default KeysComponent
