import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useCallback } from 'react'
import useRefresh from '../../../hooks/useRefresh'
import { useUserInfo } from '../userInfoReducer/userInfo.hooks'
import { createSelector } from '@reduxjs/toolkit'
import {
    setActiveAccount,
    getKeyEmisors,
    getAccountBalance,
    getActiveTrades,
    getBybitTrades,
} from './dashboard.actions'

const selectSelf = (state) => state.dashboardReducer
const activeKeySelector = createSelector(
    selectSelf,
    (self) => self.activekeysbybit
)
const balanceSelector = createSelector(selectSelf, (self) => self.balance)
const cumPnlSelector = createSelector(selectSelf, (self) => self.cumpnl)
const initialBalanceSelector = createSelector(
    selectSelf,
    (self) => self.initialbalance
)
const tradesBybitSelector = createSelector(
    selectSelf,
    (self) => self.tradesBybit
)
const refreshSelector = createSelector(selectSelf, (self) => self.refresh)
const keyEmisorsSelector = createSelector(selectSelf, (self) => self.keyEmisor)

export const useDashboardState = () => useSelector(selectSelf)

export const isEmisorSelector = createSelector(selectSelf, (self) => {
    const keyVal = self.keysbybit.find((v) => v.id === self.activekeysbybit)
    return keyVal?.emisor
})

export const useSetActiveAccount = () => {
    const dispatch = useDispatch()

    return useCallback(
        (id) => {
            dispatch(setActiveAccount(id))
        },
        [dispatch]
    )
}

export const useActiveKey = () => {
    return useSelector(activeKeySelector)
}

export const useBalance = () => {
    return useSelector(balanceSelector)
}

export const useCumPnl = () => {
    return useSelector(cumPnlSelector)
}

export const useInitialBalance = () => {
    return useSelector(initialBalanceSelector)
}

export const useKeysBybit = () => {
    return useSelector((state) => state.dashboardReducer.keysbybit)
}

export const useTradesBybit = () => {
    return useSelector(tradesBybitSelector)
}
export const useDasboardRefresh = () => {
    return useSelector(refreshSelector)
}

export const useEmisor = () => {
    const { isSuperuser } = useUserInfo()
    const isEmisor = useSelector(isEmisorSelector)
    const navigate = useNavigate()

    useEffect(() => {
        if (!isEmisor && !isSuperuser)
            navigate('livetrading', { replace: true })
    }, [isEmisor])
}

export const useFetchKeysEmisor = () => {
    const dispatch = useDispatch()

    const _getKeysEmisor = useCallback(
        () => dispatch(getKeyEmisors()),
        [dispatch]
    )

    useEffect(() => {
        _getKeysEmisor()
    }, [])

    return useSelector(keyEmisorsSelector)
}

export const useFetchAccountBalance = () => {
    const { slowRefresh } = useRefresh()
    const refresh = useDasboardRefresh()
    const activekey = useActiveKey()

    const dispatch = useDispatch()

    const fetchAccountBalance = useCallback(() => {
        dispatch(getAccountBalance())
    }, [dispatch, activekey])

    useEffect(() => {
        if (activekey > 0) fetchAccountBalance()
    }, [activekey, refresh, slowRefresh])
}

/**
 * UNUSED HOOKS FOR NOW
 */
export const useFetchActiveTrades = () => {
    const dispatch = useDispatch()
    const fetch = useCallback(() => dispatch(getActiveTrades()), [dispatch])

    useEffect(() => {
        fetch()
    }, [])
}

export const useFetchBybitTrades = () => {
    // const { fastRefresh } = useRefresh()
    // const refresh = useDasboardRefresh()
    // const activekey = useActiveKey()
    const dispatch = useDispatch()

    const getBybitTradesAction = useCallback(
        () => dispatch(getBybitTrades()),
        [dispatch]
    )

    // useEffect(() => {
    //     if (activekey > 0) getBybitTradesAction()
    // }, [activekey, fastRefresh, refresh])
    useEffect(() => {
        getBybitTradesAction()
    }, [])

    return { reload: getBybitTradesAction }
}
