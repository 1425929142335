/* eslint-disable no-unused-vars */
import React, { useImperativeHandle, useCallback, useState, useEffect } from 'react'

import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'

import * as yup from 'yup'
import { validations } from 'src/service/yupValidations'
import { useFormik } from 'formik'
import { DatePicker } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import Uptime from './Uptime'
import { useUptime } from 'src/hooks/useUptime'
import { FormikAntTextArea } from 'src/components/FormikAntDInputs/FormikAntTextArea'
import { useHistoryTradesState } from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import Periodicity from './Periodicity'
import { MONTHLY } from 'src/constants/temporality.constants'

const AddEpochForm = React.forwardRef(({ onSubmit }, ref) => {
    const [startDate, setStartDate] = useState('')
    const { uptime, setUptime, range} = useUptime({start: startDate})
    const [ periodicity, setPeriodicity ] = useState(MONTHLY)
    const {epochs} = useHistoryTradesState()

    const _initialState = {
        minimumAmount: '',
        startAt: '',
        uptime: '',
        periodicity: '',
        description: '',
    }

    const yupSchema = yup.object().shape({
        minimumAmount: validations.number,
        // startAt: validations.date,
        // uptime: validations.date,
        // description: validations.generic,
    })

    const handleSubmit = useCallback(
        (values) => {
            const data = {
                ...values,
                periodStart: range.startDateString,
                periodEnd: range.endDateString,
                periodicity: periodicity,
            }
            onSubmit(data)
        },
        [onSubmit, range, periodicity]
    )

    const formik = useFormik({
        initialValues: _initialState,
        validationSchema: yupSchema,
        onSubmit: handleSubmit,
    })


    useImperativeHandle(
        ref,
        () => ({
            submitForm: () => formik.submitForm(),
            resetForm: () => formik.resetForm(_initialState),
        }),
        [formik]
    )
    return (
        <form onSubmit={formik.handleSubmit} className="pb-3">
            <div>
                <div className="text-[#B2955F] font-oxanium font-medium text-xl mb-4">
                    EPOCH {epochs[0].id + 1}
                </div>
                <div className="mb-1 text-palette-gray-700 font-oxanium font-medium text-base">
                    Minimum Amount
                </div>
                <FormikAntInput
                    formik={formik}
                    name="minimumAmount"
                    size="small"
                    prefix={
                        <span className="text-base text-[#B2955F] font-semibold">
                            $
                        </span>
                    }
                    // placeholder="Username"
                    transform={{
                        output: (value) => {
                            // valdate if value is empty and return empty string
                            // and apply a regex to only allow letters and numbers and no spaces
                            // and return it as a string

                            let _value = value
                            if (!_value) return ''
                            _value = _value.replace(/[^a-zA-Z0-9]/g, '')

                            return _value
                        },
                    }}
                />
            </div>
            <div className="mb-4">
                <div className="mb-1 text-palette-gray-700 font-oxanium font-medium text-base ">
                    Start at
                </div>
                <DatePicker
                    className="w-full h-10 text-xs "
                    size="large"
                    onChange={(date) => {
                        setStartDate(date)
                    }}
                />
            </div>
            <div className="mb-4">
                <div className="mb-1 text-palette-gray-700 font-oxanium font-medium text-base ">
                    Uptime
                </div>
                <Uptime
                    selected={uptime}
                    onSelect={(v) => {
                        setUptime(v)
                    }}
                />
            </div>
            <div>
                <div className="mb-1 text-palette-gray-700 font-oxanium font-medium text-base ">
                    Description
                </div>
                <FormikAntTextArea formik={formik} name={'description'} className='h-20'/>
            </div>
            <div className="mb-4">
                <div className="mb-1 text-palette-gray-700 font-oxanium font-medium text-base ">
                    Periodicity
                </div>
                <Periodicity
                    selected={periodicity}
                    onSelect={(value) => {
                        setPeriodicity(value)
                    }}/>
            </div>
        </form>
    )
})
AddEpochForm.displayName = 'AddEpochForm'

export default AddEpochForm
