import React from 'react'

import { useSelector } from 'react-redux'
import { FiInbox } from 'react-icons/fi'
import { getSymbolFormat } from './../../utils/symbolUtilities'
import {
    formatNumber,
    formatNumberToPercentage,
    formatNumberToCurrency,
} from './../../utils/formatNumber'

const RenderColums = ({ element }) => {
    const symbolFormat = getSymbolFormat(element.symbol)
    return (
        <tr className="text-center text-palette-gray-100 font-semibold text-xs border-b-2 border-palette-black-700 bg-palette-black-800 ">
            <td className="py-8">{element.account}</td>
            <td className="py-8">{element.symbol}</td>
            <td
                className={`py-8 ${
                    element.side === 'Buy'
                        ? 'text-palette-green-500 p-3'
                        : 'text-palette-pink-700 p-3'
                }`}
            >
                {element.side === 'Buy' ? 'Long' : 'Short'}
            </td>
            <td className="py-8 text-palette-yellow-825">
                {formatNumber(element.leverage)} X
            </td>
            <td className="py-8 ">
                {formatNumberToCurrency(element.value, symbolFormat)}
            </td>

            <td className="py-8">
                {formatNumberToCurrency(element.entryPrice, symbolFormat)} USD
            </td>
            <td className="py-8">
                {formatNumberToCurrency(element.positionMargin)} USD
            </td>

            <td
                className={`$py-8 ${
                    element.pnl > 0
                        ? 'text-palette-green-500'
                        : 'text-palette-pink-700'
                }`}
            >
                <div className="">
                    <div>{formatNumberToCurrency(element.pnlUsd)} USD</div>
                    <div>{formatNumberToPercentage(element.pnl)}</div>
                </div>
            </td>
        </tr>
    )
}

const TradesTableHistory = ({
    headers,
    values,
    emptyMessage = '0 positions open',
}) => {
    const activeTrades = useSelector(
        (state) => state.dashboardReducer.activeTrades
    )
    return (
        <div className="mt-2 bg-palette-black-700 overflow-x-auto">
            <table className="table-auto border border-collapse border-palette-black-700 w-full rounded-t-lg overflow-hidden">
                <tr className="">
                    {headers.map((element) => {
                        return (
                            <th
                                className="text-palette-gray-700 text-[11px] text-center align-middle py-5 bg-palette-black-725 px-3 "
                                key={element}
                            >
                                {element}
                            </th>
                        )
                    })}
                </tr>
                {(!values || values?.length === 0) && (
                    <tr className="text-center text-palette-gray-100 font-semibold text-xs border-b-2 border-palette-black-700 bg-palette-black-800 ">
                        <td
                            colSpan={headers.length}
                            className="py-8 text-center px-3"
                        >
                            <div className="flex justify-center items-center">
                                {emptyMessage}{' '}
                                <FiInbox className="text-3xl ml-1" />
                            </div>
                        </td>
                    </tr>
                )}
                {values &&
                    values
                        .filter(
                            ({ symbol }) =>
                                !activeTrades
                                    .map((v) => v.symbol)
                                    .includes(symbol)
                        )
                        .map((value, index) => {
                            return (
                                <RenderColums
                                    key={`tt-${value.id}`}
                                    element={value}
                                />
                            )
                        })}
            </table>
        </div>
    )
}

export default TradesTableHistory
