import React, {useState} from 'react'
import Button from '../Buttons/Button'
import { useFetchSellerComission, useStaffState } from 'src/store/reducers/staffReducer/staff.hooks'
import { formatNumberToCurrency } from 'src/utils/formatNumber'
import ClaimConfirm from './ClaimConfirm'
import { useDispatch } from 'react-redux'
import { createSellerPayment } from 'src/store/reducers/staffReducer/staff.actions'

export const SellerComission = () => {
    useFetchSellerComission()
    const { sellerComission, selectedEpoch } = useStaffState()
    const [visibleModal, setVisibleModal] = useState(false)
    const dispatch = useDispatch()
    
    const handleConfirm = () => {
        const epoch = selectedEpoch
        const amount = sellerComission.sellerComission
        dispatch(
            createSellerPayment({epoch, amount})
        )       
        setVisibleModal(false)
    }
    return (
        <div className="flex flex-col bg-[#0A0A0A] border border-palette-gray-500 rounded h-full">
            <ClaimConfirm
                visible={visibleModal}
                onClose={() => setVisibleModal((state) => !state)}
                onConfirm={() => handleConfirm()}
                epoch={selectedEpoch}
                amount={sellerComission.sellerComission}
            />
            <div className="relative px-3 py-4 flex flex-row justify-between before:content-[''] before:absolute before:inset-x-0 before:bottom-0 before:h-[50%] before:bg-gradient-to-t before:from-palette-gold-200 ">
                <div className="text-palette-beigde-100 text-sm font-semibold flex items-center">
                    MY COMISSION
                </div>
            </div>

            <div className="px-5 py-5 space-y-10 flex flex-col justify-center flex-auto">
                <div className="flex flex-row justify-between">
                    <div className="text-[#878787] font-medium text-sm flex items-center">
                        Pending to claim
                    </div>
                    <div className="bg-palette-yellow-700 bg-opacity-20 border rounded-full border-[#B2955F] font-oxanium font-medium px-3 text-lg w-32 text-right flex items-center justify-end py-1">
                        {formatNumberToCurrency(sellerComission.pendingBalance)}
                    </div>
                </div>
                <div className="flex flex-row justify-between ">
                    <div className="text-[#878787] font-medium text-sm flex items-center">
                        Total Initial Balance
                    </div>
                    <div className="bg-palette-yellow-700 bg-opacity-20 border rounded-full border-[#B2955F] font-oxanium font-medium px-3 text-lg w-32 text-right flex items-center justify-end py-1">
                        {formatNumberToCurrency(sellerComission.initialBalance)}
                    </div>
                </div>
            </div>

            <div className="bg-[#141414] border-t border-palette-gray-500 relative flex flex-col items-center py-3 before:content-[''] before:absolute before:inset-x-0 before:bottom-0 before:h-[50%] before:bg-gradient-to-t before:from-palette-gold-200 ">
                <div className="mb-6 space-y-2">
                    <div className="text-center text-palette-beigde-100 font-semibold text-sm">
                        MY COMISSION
                    </div>
                    <div className="text-center text-[#B2955F] font-oxanium font-medium text-3xl">
                        {formatNumberToCurrency(
                            sellerComission.sellerComission
                        )}{' '}
                        <span className="text-base">USD</span>
                    </div>
                </div>
                {selectedEpoch !== 0 && !sellerComission.epochStatus && (
                    <Button
                        className={'px-16 py-3 bg-black z-10'}
                        variant={'secondary'}
                        onClick={() => setVisibleModal(true)}
                        disabled={sellerComission.claimed}
                    >
                        <div>{sellerComission.claimed ? "CLAIMED" : "CLAIM"}</div>
                    </Button>
                )}
            </div>
        </div>
    )
}
