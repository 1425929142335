import React, { useEffect, useState } from 'react'
import { FiInbox } from 'react-icons/fi'
import { formatNumber, formatNumberToCurrency, formatNumberToPercentage } from './../../utils/formatNumber'
import { useHistoryTradesState } from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import { Pagination } from 'antd'
import {formatDate} from "../../utils/formatDate";


const RenderColums = ({ element }) => {
    return (
        <tr className="text-center text-palette-gray-100 font-semibold text-xs border-b-2 border-palette-black-700 bg-palette-black-800 ">
            <td className="py-8 font-semibold">
                { formatDate(element.date) }
            </td>
            <td className="py-8 font-semibold">{element.pair}</td>
            <td
                className={`py-8 font-semibold	${
                    element.type === 'Buy'
                        ? 'text-palette-green-500 p-3'
                        : 'text-palette-red-100 p-3'
                }`}
            >
                {element.type === 'Buy' ? 'Long' : 'Short'}
            </td>
            <td
                className={`py-8 font-semibold ${
                    element.qty > 0
                        ? 'text-palette-green-500 p-3'
                        : 'text-palette-pink-700 p-3'
                }`}
            >
                {element.qty > 0
                    ? `${formatNumber(element.qty)}`
                    : `${formatNumber(element.qty)}`}
            </td>
            <td className="py-8 font-medium">
                {formatNumberToCurrency(element.balance)}
            </td>

            <td
                className={`py-8 font-medium ${
                    element.pnlUsd > 0
                        ? 'text-palette-green-500 p-3'
                        : 'text-palette-pink-700 p-3'
                }`}
            >
                {formatNumberToPercentage(element.growth)}
            </td>
            <td className="py-8 flex justify-center">
                <div
                    className={`w-16 font-semibold rounded-full bg-palette-green-700 text-palette-green-500 border border-palette-green-600`}
                >
                    Fulfilled
                </div>
            </td>
        </tr>
    )
}

const TradesTablePull = ({
    headers,
    emptyMessage = '0 positions open',
}) => {
    // Settings for pagination
    const [currentPage, setCurrentPage] = useState(1)
    const { tradesHistory } = useHistoryTradesState()
    const [dataHistory, setDataHistory] = useState([])
    const postPerPage = 10
    

    const reversedTrades = [...dataHistory].reverse()

    // Divide the array to show on the table
    const indexOfLastTrade = currentPage * postPerPage
    const indexOfFirstTrade = indexOfLastTrade - postPerPage
    const currentTrades = reversedTrades.slice(
        indexOfFirstTrade,
        indexOfLastTrade
    )
    
    // Reset page view each time a request with different temporality is made
    useEffect(() => {
        if (tradesHistory.some(t => t.pair === "--")) 
            setDataHistory(tradesHistory.filter(transaction => transaction.pair !== "--"))
    }, [tradesHistory])
    
    useEffect(() => {
        setCurrentPage(1)
    }, [dataHistory])
    

    return (
        <div className="mt-2 bg-palette-black-700 overflow-x-auto">
            <div className="flex justify-between mb-3">
                <div className="mt-1 lg:mt-6 text-palette-gray-100 font-medium tracking-widest text-sm">
                    HISTORY
                </div>
                <Pagination
                    showSizeChanger={false}
                    current={currentPage}
                    pageSize={postPerPage}
                    total={reversedTrades.length}
                    onChange={(page) => setCurrentPage(page)}
                />
            </div>
            <table className="table-auto border border-collapse border-palette-black-700 w-full rounded-t-lg overflow-hidden">
                <tbody>
                    <tr className="">
                        {headers.map((element) => {
                            return (
                                <th
                                    className="text-palette-gray-700 text-[11px] text-center align-middle py-5 bg-palette-black-725 px-3 "
                                    key={element}
                                >
                                    {element}
                                </th>
                            )
                        })}
                    </tr>
                    {(!currentTrades || currentTrades?.length === 0) && (
                        <tr className="text-center text-palette-gray-100 font-semibold text-xs border-b-2 border-palette-black-700 bg-palette-black-800 ">
                            <td
                                colSpan={headers.length}
                                className="py-8 text-center px-3"
                            >
                                <div className="flex justify-center items-center">
                                    {emptyMessage}{' '}
                                    <FiInbox className="text-3xl ml-1" />
                                </div>
                            </td>
                        </tr>
                    )}
                    {currentTrades &&
                        currentTrades.map((value, index) => {
                            return (
                                <RenderColums
                                    key={`tt-${index}`}
                                    element={value}
                                />
                            )
                        })}
                </tbody>
            </table>
        </div>
    )
}

export default TradesTablePull
