export const MONTHLY = 'monthly'
export const BIMONTHLY = 'bimonthly'
export const QUARTERLY = 'quarterly'
export const BIANNUAL = 'biannual'
export const ANNUAL = 'anual'
export const CONTRACT_TERM = 'contract_term'

export const periodicity = {
    [MONTHLY]: 0,
    [BIMONTHLY]: 1,
    [QUARTERLY]: 2,
    [BIANNUAL]: 3,
    [ANNUAL]: 4,
    [CONTRACT_TERM]: 5,
}
