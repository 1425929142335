import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useAccountName = () => {
    const keysbybit = useSelector((state) => state.dashboardReducer.keysbybit)
    const activekeysbybit = useSelector(
        (state) => state.dashboardReducer.activekeysbybit
    )

    const acountName = useMemo(() => {
        if (keysbybit.length === 0 || activekeysbybit === 0) return ''
        return keysbybit?.find((item) => item.id === activekeysbybit)?.name
    }, [keysbybit, activekeysbybit])

    return acountName
}

export default useAccountName
