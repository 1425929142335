import { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useIsAuth } from 'src/store/reducers/auth/auth.hooks'
import { getKeys } from 'src/store/reducers/dashboardReducer/dashboard.actions'

export const useKeys = () => {
    const dispatch = useDispatch()
    const getKeysAction = useCallback(() => dispatch(getKeys()), [dispatch])
    const auth = useIsAuth()

    useEffect(() => {
        if (auth) {
            getKeysAction()
        }
    }, [auth])
}
