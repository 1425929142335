import { useFormik } from 'formik'
import {
    forwardRef,
    useImperativeHandle,
    useMemo,
    useCallback,
    useEffect,
} from 'react'
import { ORDER_TYPE } from 'src/constants/positionConstants'
import { validations } from 'src/utils/yupValidations'
import * as yup from 'yup'

import FormikAntInput from '../../FormikAntDInputs/FormikAntInput'
import {
    formatValueToNumberOnChange,
    limitValueByTick,
} from 'src/utils/inputsUtilities'
import { getSymbolData } from 'src/utils/symbolUtilities'

export const ConfigureOrder = forwardRef(function ConfigureOrder(
    { orderType, symbol, onSubmit, disabled },
    ref
) {
    const initialValues = useMemo(() => {
        const values = { stopPrice: '0', price: '0', amount: '0' }
        if (orderType === ORDER_TYPE.LIMIT) delete values.stopPrice
        if (orderType === ORDER_TYPE.MARKET) {
            delete values.stopPrice
            delete values.price
        }
        return values
    }, [orderType])

    const schemaConfig = useMemo(() => {
        const values = {
            stopPrice: validations.noZero,
            price: validations.noZero,
            amount: validations.noZero,
        }
        if (orderType === ORDER_TYPE.LIMIT) delete values.stopPrice
        if (orderType === ORDER_TYPE.MARKET) {
            delete values.stopPrice
            delete values.price
        }
        return values
    }, [orderType])

    const schema = yup.object().shape(schemaConfig)

    const formik = useFormik({
        initialValues,
        onSubmit,
        validationSchema: schema,
    })

    const handleReset = useCallback(() => {
        formik.resetForm(initialValues)
    }, [formik, initialValues])

    useEffect(() => {
        handleReset()
    }, [orderType])

    useImperativeHandle(
        ref,
        () => ({
            submitForm: formik.submitForm,
            resetForm: formik.resetForm,
        }),
        [formik]
    )

    // Object with the format symbol and qty step
    const { decimalsPrice, decimalsQty, qtyStep, tick } = useMemo(() => {
        const { format, qtyStep, tickSize } = getSymbolData(symbol)
        return {
            decimalsPrice: format?.split('.')[1]?.length || 0,
            decimalsQty: qtyStep?.toString()?.split('.')[1]?.length || 0,
            qtyStep: qtyStep || 1,
            tick: tickSize || 0.01,
        }
    }, [symbol])

    return (
        <form className="flex flex-col mt-4">
            <div className="mb-2">REPURCHASE</div>
            {orderType === ORDER_TYPE.STOP_LIMIT && (
                <FormikAntInput
                    size="small"
                    name="stopPrice"
                    disabled={disabled}
                    formik={formik}
                    prefix={<div className="w-16">Stop Price</div>}
                    placeholder=""
                    transform={{
                        output: (value) => {
                            return formatValueToNumberOnChange(value, {
                                decimals: decimalsPrice,
                                min: 0,
                            })
                        },
                        blur: (value) => {
                            return limitValueByTick(value, tick)
                        },
                    }}
                />
            )}
            {(orderType === ORDER_TYPE.STOP_LIMIT ||
                orderType === ORDER_TYPE.LIMIT) && (
                <FormikAntInput
                    size="small"
                    name="price"
                    disabled={disabled}
                    formik={formik}
                    prefix={<div className="w-16">Price</div>}
                    placeholder=""
                    transform={{
                        output: (value) => {
                            return formatValueToNumberOnChange(value, {
                                decimals: decimalsPrice,
                                min: 0,
                            })
                        },
                        blur: (value) => {
                            return limitValueByTick(value, tick)
                        },
                    }}
                />
            )}
            <FormikAntInput
                size="small"
                name="amount"
                disabled={disabled}
                formik={formik}
                prefix={<div className="w-16">QTY</div>}
                placeholder=""
                transform={{
                    output: (value) => {
                        return formatValueToNumberOnChange(value, {
                            decimals: decimalsQty,
                            min: 0,
                            onlyInteger: decimalsQty === 0,
                        })
                    },
                    blur: (value) => {
                        return limitValueByTick(value, qtyStep)
                    },
                }}
            />
        </form>
    )
})
