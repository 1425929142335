import { createReducer } from "@reduxjs/toolkit";
import {
    getStaffClients,
    getSalesStaffMembers,
    refreshStaff,
    staffDetails,
    staffClientEpochs,
    selectEpoch,
    getEpochBalances,
    epochQuantitySelect,
    getSellerComission,
    createSellerPayment,
} from './staff.actions'

export const initialState = {
    salesStaffMembers: [],
    staffClients: [],
    refreshStaff: 0,
    staffDetails: {},
    clientEpochs: [],
    selectedEpoch: 0,
    epochsBalances: [],
    epochQuantity: 'ALL',
    sellerComission: []
}

const staffReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(getSalesStaffMembers.fulfilled, (state, { payload }) => {
            state.salesStaffMembers = payload
        })
        .addCase(getStaffClients.fulfilled, (state, { payload }) => {
            state.staffClients = payload
        })
        .addCase(refreshStaff, (state, { payload }) => {
            state.refreshStaff += 1
        })
        .addCase(staffDetails.fulfilled, (state, { payload }) => {
            state.staffDetails = payload
        })
        .addCase(staffClientEpochs.fulfilled, (state, { payload }) => {
            state.clientEpochs = payload
        })
        .addCase(selectEpoch, (state, { payload }) => {
            state.selectedEpoch = payload
        })
        .addCase(getEpochBalances.fulfilled, (state, { payload }) => {
            state.epochsBalances = payload
        })
        .addCase(epochQuantitySelect, (state, { payload }) => {
            state.epochQuantity = payload
        })
        .addCase(getSellerComission.fulfilled, (state, { payload }) => {
            state.sellerComission = payload
        })
        .addCase(createSellerPayment.fulfilled, (state, { payload }) => {            
            state.sellerComission.claimed = true   
            state.sellerComission.pendingBalance -= payload          
        })
})

export default staffReducer