/* eslint-disable no-unused-vars */
import React, { useState,useEffect } from 'react'
import {
    useGetEpochsByUser,
    useGetTradesHistory,
    useHistoryTradesState,
} from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import TradesTablePull from 'src/components/tables/tradesTablePull'
import DashboardDailyPnL from 'src/components/DashboardComponents/DashboardDailyPnL'
import DashboardGrowthChart from 'src/components/DashboardComponents/DashboardGrowthChart'
import Temporality from 'src/components/Temporality'
import { MONTHLY, YEARLY } from '../../constants/temporality.constants'
import { useTemporality2 } from 'src/hooks/useTemporality2'
import { useDebounceState } from 'src/hooks/useDebounceState'
import DashboardNormalDistribution from 'src/components/DashboardComponents/DashboardNormalDistribution'
import { WinsxLossesDetails } from 'src/components/DashboardComponents/WinsxLossesDetails'
import GraphSelector from 'src/components/DashboardComponents/GraphSelector'
import DashboardPnLs from 'src/components/DashboardComponents/DashboardPnLs'
import { WinsxLossesDetailsMobile } from 'src/components/DashboardComponents/WinsxLossesDetailsMobile'
import { Select } from 'antd'
import { useDispatch } from 'react-redux'
import { selectEpoch } from 'src/store/reducers/historyTradesReducer/historyTrades.actions'
import { UserContribution } from 'src/components/DashboardComponents/UserContribution'
import dayjs from 'dayjs'
import { UserContributionMobile } from 'src/components/DashboardComponents/UsercontributionMobile'

const headersActive = [
    'Date',
    'Pair',
    'Side',
    'QTY',
    'Balance',
    '% Growth',
    'Status',
]

const DashboardAdmin = () => {
    const dispatch = useDispatch()
    const [rangeDate, setDateRange] = useState([])

    const { setTemporality, ...temporalityData } = useTemporality2(
        YEARLY,
        rangeDate
    )

    const { range, temporality } = useDebounceState(temporalityData, 500)

    // fetch epochs by logged in user
    useGetEpochsByUser()
    useGetTradesHistory({
        temporality,
        rangeDate: {
            startDate: range.startDateString,
            endDate: range.endDateString,
        },
    })

    const { epochsByUser, tradesHistory, dailyPnL, details, epochDetails, selectedEpoch } = useHistoryTradesState()

    // Check for screen size
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth)
        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])


    return (
        <div className="mb-10">
            <div className="flex justify-between">
                <div className="mt-1 lg:mt-6 tracking-widest text-palette-gold-100 items-center font-bold font-['oxanium'] text-2xl">
                    <Select
                        value={selectedEpoch}
                        size={'large'}
                        options={epochsByUser.map((e) => {
                            return {
                                value: e.epoch,
                                label: `EPOCH ${e.epoch}`,
                            }
                        })}
                        style={{
                            width: '9rem',
                            color: '#8D793F',
                            fontFamily: 'Oxanium',
                            fontWeight: 700, 
                            fontSize: '16px',   
                            
                    
                        }}
                        
                        onChange={(val) => dispatch(selectEpoch(val))}
                    />
                </div>
                {epochDetails && (
                    <div className="ml-auto text-right w-fit p-5 mb-5 ">
                        <div className="font-semibold font-maven text-xs text-palette-gray-700">
                            Ends on
                        </div>
                        <div className="font-medium font-oxanium text-base text-palette-gray-100 mt-2">
                            {dayjs(epochDetails.periodEnd).format('MMMM D, YY')}                            
                        </div>
                    </div>
                )}
            </div>

            {/* <div className="flex flex-col lg:flex-row mb-8">
                <Temporality
                    rangeDate={rangeDate}
                    selected={temporalityData.temporality}
                    onSelect={(v, range) => {
                        setTemporality(v)
                        if (range) setDateRange(range)
                    }}
                />
            </div> */}

            <>
                <div className="grid grid-cols-12 gap-4 auto-rows-min mb-12">
                    {epochDetails && (
                        <>
                            <UserContributionMobile/>
                            <DashboardGrowthChart
                                graphicData={dailyPnL}
                                temporality={temporalityData.temporality}
                                epochdetails={epochDetails}
                            />
                            <UserContribution/>
                            <DashboardDailyPnL
                                graphicData={dailyPnL}
                                temporality={temporalityData.temporality}
                            />
                        </>
                    )}
                    {tradesHistory && (
                        <>
                            <DashboardNormalDistribution
                                graphicData={tradesHistory}
                                temporality={temporalityData.temporality}
                            />
                            {width > 640 ? (
                                <WinsxLossesDetails
                                    graphicData={details}
                                    temporality={temporalityData.temporality}
                                />
                            ) : (
                                <WinsxLossesDetailsMobile
                                    graphicData={details}
                                    temporality={temporalityData.temporality}
                                />
                            )}
                        </>
                    )}
                </div>
            </>

            <TradesTablePull
                headers={headersActive}
                emptyMessage="NO TRADES REGISTERED YET"
            />
        </div>
    )
}

export default DashboardAdmin
