import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import useDeepCompareEffect from "src/hooks/useDeepCompareEffect";
import { getCustomerEpochBalances } from './customer.actions'

const selectSelf = state => state.customer

export const useCustomerState = () => {
    return useSelector(selectSelf)
} 

export const useFetchCustomerEpochsBalances = () => {
    const dispatch = useDispatch()
    const { epochQuantity } = useCustomerState()

    const action = useCallback(async () => {
        try {
            await dispatch(getCustomerEpochBalances({ epochQuantity })).unwrap()
        } catch (err) {
            console.log(err)
        }
    }, [dispatch, epochQuantity])

    useDeepCompareEffect(() => {
        action()
    }, [epochQuantity])
}