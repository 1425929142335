import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { DAppProvider, Mainnet, Localhost } from '@usedapp/core'

import App from './App'
import { RefreshContextProvider } from './context/RefreshContext'
import reportWebVitals from './reportWebVitals'
import store from './store/config'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18n'

const defaultConfig = {
    notifications: {
        expirationPeriod: 1000,
        checkInterval: 1000,
    },
}

const config =
    process.env.NODE_ENV === 'development'
        ? {
              networks: [Localhost],
              readOnlyChainId: [Localhost.chainId],
              readOnlyUrls: {
                  [Localhost.chainId]: 'http://127.0.0.1:8545/',
              },
              //   multicallAddresses: {
              //       [Localhost.chainId]: 'http://127.0.0.1:8545/',
              //   },
              ...defaultConfig,
          }
        : {
              networks: [Mainnet],
              readOnlyChainId: Mainnet.chainId,
              readOnlyUrls: {
                  [Mainnet.chainId]: process.env.REACT_APP_INFURA_API_KEY,
              },
              ...defaultConfig,
          }
//   {
//       networks: [Hardhat],
//       readOnlyChainId: Hardhat.chainId,
//       readOnlyUrls: {
//           [Hardhat.chainId]: 'http://localhost:8545',
//       },
//       ...defaultConfig,
//   }

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider store={store}>
                <DAppProvider config={config}>
                    <RefreshContextProvider>
                        <I18nextProvider i18n={i18n}>
                            <App />
                        </I18nextProvider>
                    </RefreshContextProvider>
                </DAppProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>,
    document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
