import React, { useState } from 'react'
import ActiveKeys from 'src/components/KeysComponents/ActiveKeys'
import KeysComponent from 'src/components/KeysComponents/keys'
import Button from 'src/components/Buttons/Button'
import {
    useFetchKeysEmisor,
    useKeysBybit,
} from 'src/store/reducers/dashboardReducer/dashboard.hooks'

const LinkAccount = ({ name, index }) => {
    const [showButtonConection, setShowButtonConection] = useState(false)
    const keysbybit = useKeysBybit()
    const changeStateButton = () => {
        setShowButtonConection(!showButtonConection)
    }

    return (
        <div className="flex flex-col min-h-[150px]">
            <div className="flex flex-row items-end">
                <div className="mt-1 lg:mt-6 text-palette-gray-100 font-medium text-sm">
                    Igniite 1
                </div>

                <div className="ml-auto flex flex-row gap-3 items-center">
                    <Button
                        variant="light"
                        size="small"
                        className="w-40 h-10 text-center"
                        onClick={() => {
                            changeStateButton()
                        }}
                    >
                        {!showButtonConection ? 'LINK ACCOUNT' : 'CANCEL'}
                    </Button>
                </div>
            </div>
            {showButtonConection ? (
                <KeysComponent
                    emisorId={index}
                    stateButton={changeStateButton}
                    firstAccount={keysbybit?.length === 0 ?? true}
                />
            ) : null}
            {/* Emisor hardcoded to 9 in order to hide all keys */}
            <ActiveKeys emisorId={9} />
        </div>
    )
}

const KeysView = () => {
    const keysEmisors = useFetchKeysEmisor()

    return (
        <div>
            <div className="flex flex-row items-end">
                <div className="">
                    <div className="text-xl font-bold text-lef text-palette-gray-700">
                        ACCOUNTS
                    </div>

                    <div className="text-2xl font-bold text-left mt-5  text-palette-yellow-800">
                        BYBIT
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-6">
                {keysEmisors.length > 0 && (
                    <LinkAccount index={0} {...keysEmisors[0]} />
                )}
            </div>

            <br />
        </div>
    )
}

export default KeysView
