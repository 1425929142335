import React, { useState } from 'react'
import Temporality from 'src/components/Temporality'
import Stats3 from 'src/components/DataComponents/Stats3'
import Stats5 from '../components/DataComponents/Stats5'
import TradesTable from 'src/components/tables/tradestable'
import Stats4 from '../components/DataComponents/Stats4'
import SwitchSubscribeKey from 'src/components/TradeComponents/SwitchSubscribeKey'
import useGetAccount from 'src/hooks/useGetAccount'
import TradesTableHistory from '../components/tables/tradestableHistory'
import {
    useActiveKey,
    useFetchBybitTrades,
    useFetchAccountBalance,
    useBalance,
    useTradesBybit,
    useCumPnl,
    useInitialBalance,
} from 'src/store/reducers/dashboardReducer/dashboard.hooks'
import { WEEKLY } from 'src/constants/temporality.constants'
import {
    useFetchStats,
    useTradeHistory,
    useStats,
} from 'src/store/reducers/statsReducer/stats.hooks'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

const headersActive = [
    'Symbol',
    'Side',
    'Leverage',
    'Quantity',
    'Position Value',
    'Entry Price',
    'Liq. Price',
    'PnL',
    'TP/SL',
    'Close Position',
]

const headersClose = [
    'ACC',
    'Symbol',
    'Qty',
    'Leverage',
    'Value',
    'Entry Price',
    'Position Margin',
    'P&L %',
]

const LiveTrading = () => {
    const [temporality, setTemporality] = useState(WEEKLY)
    const [rangeDate, setDateRange] = useState([])

    const activekey = useActiveKey()
    const stats = useStats()
    const tradesHistory = useTradeHistory()

    const tradesBybit = useTradesBybit()

    const walletBalance = useBalance()
    const cumPnl = useCumPnl()
    const account = useGetAccount()
    const initialBalance = useInitialBalance()

    useFetchAccountBalance()
    useFetchBybitTrades()
    useFetchStats({ temporality, account: activekey, rangeDate })

    return (
        <div className="pb-12 flex flex-col gap-3">
            <div className="flex flex-row w-full justify-end">
                <SwitchSubscribeKey item={account} />
            </div>
            <div className="flex flex-col gap-3">
                <div className="ml-auto"></div>
                <div className="flex flex-col gap-3 md:gap-0 md:flex-row md:items-end">
                    <div className="w-full md:w-2/5 lg:w-1/4 flex flex-row items-end">
                        <div className="font-oxanium">
                            <div className=" text-palette-brown-700 text-2xl font-bold relative">
                                BYBIT
                            </div>
                            <div className="font-bold text-xl text-palette-gray-700">
                                {account?.name}
                            </div>
                        </div>
                        <div className="ml-auto text-right">
                            <div className="font-semibold text-xs text-palette-gray-700">
                                BALANCE
                            </div>
                            <div className="font-bold font-oxanium text-xl text-palette-gray-100 mt-2">
                                {formatNumberToCurrency(walletBalance)}{' '}
                                <span className="text-base">USD</span>
                            </div>
                        </div>
                    </div>
                    <Temporality
                        className="md:ml-auto md:min-w-[400px]"
                        rangeDate={rangeDate}
                        selected={temporality}
                        onSelect={(v, range) => {
                            setTemporality(v)
                            if (range) setDateRange(range)
                        }}
                    />
                </div>
            </div>
            <div className="flex flex-row space-x-4 w-full overflow-x-auto pb-3 lg:pb-0 scroll-smooth">
                <Stats4
                    title="TOTAL TRADES"
                    value={stats.totalTrades}
                    active={tradesBybit?.length ?? 0}
                    closed={
                        stats.totalTrades - tradesBybit?.length >= 0
                            ? stats.totalTrades - tradesBybit?.length
                            : 0
                    }
                />
                <Stats5
                    title="ROI"
                    value={cumPnl}
                    percent={(walletBalance * 100) / initialBalance - 100}
                />
                <Stats3
                    title="Total earnings"
                    value={stats.winsUsd}
                    total={stats.tradesWined}
                    percent={stats.wins}
                />
                <Stats3
                    title="Total Losses"
                    value={stats.lossesUsd}
                    total={stats.tradesLossed}
                    percent={stats.losses}
                    positive={false}
                />
            </div>
            <div className="mt-1 lg:mt-6 text-palette-gray-100 font-medium text-sm">
                ACTIVE TRADES
            </div>
            <TradesTable
                headers={headersActive}
                values={tradesBybit}
                emptyMessage="NO TRADES REGISTERED YET"
            />
            <div className="mt-1 lg:mt-6 text-palette-gray-100 font-medium text-sm">
                CLOSE TRADES
            </div>
            <TradesTableHistory
                headers={headersClose}
                values={tradesHistory}
                emptyMessage="NO CLOSED TRADES YET"
            />
        </div>
    )
}

export default LiveTrading
