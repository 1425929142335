/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react'
import { Input, Form, Tooltip } from 'antd'
import classNames from 'classnames'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import useMediaQuery from 'src/hooks/useMediaQuery'
import useResponsiveValue from 'src/hooks/useResponsiveValue'

const FormItem = Form.Item

const classes = {
    size: {
        small: 'h-10 text-xs',
        middle: 'h-[60px] text-xs',
    },
}

const defaultTransform = {
    input: (value) => value,
    output: (value) => value,
    blur: null,
}

const FormikAntInputPassword = ({
    prefix,
    formik,
    name,
    placeholder,
    value,
    size = 'middle',
    disabled = false,
    tooltip = false,
    tooltipProps = {},
    transform = defaultTransform,
    ...props
}) => {
    const touched = formik.touched[name]
    const hasError = formik.errors[name]
    const submittedError = hasError && formik.submitCount > 0
    const touchedError = hasError && touched

    const placement = useResponsiveValue({
        base: 'topLeft',
        lg: 'rightTop',
    })

    
    const $transform = {
        ...defaultTransform,
        ...transform,
    }
    
    const $value = value ?? formik.values?.[name]
    const Wrapper = tooltip ? Tooltip : React.Fragment
    
    const defaultTooltipProps = tooltip ?  {
        trigger: ['focus'],
        title: 'EXAMPLE',
        placement: 'rightTop',
        autoAdjustOverflow: true,
        color: '#1c1c1c',
    } : {}
    
    return (
        <Wrapper {...defaultTooltipProps} {...tooltipProps}>
            <FormItem
                validateStatus={
                    submittedError || touchedError ? 'error' : 'success'
                }
                help={submittedError || touchedError ? hasError : false}
            >
                <Input.Password
                    id={name}
                    name={name}
                    placeholder={placeholder}
                    bordered={false}
                    value={$value}
                    className={classNames(
                        `rounded-sm w-full bg-palette-black-725 hover:outline-none hover:ring-1 hover:ring-palette-gray-700 placeholder:text-palette-gray-700`,
                        {
                            [classes.size[size]]: size !== 'none',
                        }
                    )}
                    iconRender={(visible) =>
                        visible ? (
                            <EyeOutlined style={{ color: '#fff' }} />
                        ) : (
                            <EyeInvisibleOutlined style={{ color: '#fff' }} />
                        )
                    }
                    disabled={disabled}
                    onChange={(e) => {
                        const {
                            target: { value, name },
                        } = e

                        formik.setValues({
                            ...formik.values,
                            [name]: $transform.output(
                                value,
                                formik.values[name]
                            ),
                        })
                    }}
                    onBlur={(e) => {
                        const {
                            target: { value, name },
                        } = e

                        formik.setTouched({ ...formik.touched, [name]: true })

                        if (!$transform.blur) return

                        formik.setValues({
                            ...formik.values,
                            [name]: $transform.blur(value, formik.values[name]),
                        })
                    }}
                    {...props}
                />
            </FormItem>
        </Wrapper>
    )
}

export default FormikAntInputPassword
