/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react'
import Button from '../Buttons/Button'
import { Formik, useFormik } from 'formik'
import { validations } from 'src/service/yupValidations'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { bulkCreateTrades } from 'src/store/reducers/historyTradesReducer/historyTrades.actions'
import { PreviewTable } from './PreviewTable'

export const CsvTrades = () => {
    const [array, setArray] = useState([])
    const inputRef = useRef(null)
    const fileReader = new FileReader()
    const dispatch = useDispatch()

    const handleOnChange = (e) => {
        e.preventDefault()
        const file = e.target.files[0]
        if (file) {
            fileReader.onload = function (event) {
                const csvOutput = event.target.result
                csvFileToArray(csvOutput)
            }
            fileReader.readAsText(file)
        }
    }

    const csvFileToArray = (string) => {
        const csvHeaders = string.slice(0, string.indexOf('\n')).split(',')
        const csvRows = string.slice(string.indexOf('\n') + 1).split('\n')

        const tradesArray = csvRows.map((i) => {
            const values = i.split(',')       
            const obj = csvHeaders.reduce((object, header, index) => {
                // avoid empty rows
                if (values[index] === '' || values[index] === undefined) {
                    return undefined
                }
                object[header] = values[index]
                return object
            }, {})

            return obj
        })
        // filter non empty rows
        const filteredTrades = tradesArray.filter(n=>n)
        setArray(filteredTrades)
    }

    const handleOnSubmit = (e) => {
        dispatch(bulkCreateTrades(array))
        setArray([])
        inputRef.current.value = null
        
    }

    const headerKeys = Object.keys(Object.assign({}, ...array))

    return (
        <div>
            <div className="bg-palette-black-600 mt-3 rounded-md shadow-md ">
                <form onSubmit={Formik.handleSubmit}>
                    <div className="px-4 pt-5 flex flex-col">
                        <div className="text-base mb-3">Insert from CSV</div>
                        <div className="flex mb-7 flex-col lg:flex-row space-x-3">
                            <div className="flex-1 mr-2 md:mr-8">
                                <input
                                    ref={inputRef}
                                    type="file"
                                    id={'csvFileInput'}
                                    accept=".csv"
                                    onChange={handleOnChange}
                                    className="text-sm file:mr-4 file:py-2 file:px-4 file:rounded-sm file:border-0 file:text-sm file:font-semibold file:bg-palette-gold-100 file:text-palette-beigde-100"
                                />
                                <Button
                                    onClick={(e) => {
                                        handleOnSubmit(e)
                                    }}
                                    variant={'light'}
                                    className={'h-10 w-32'}
                                    disabled={array.length === 0}
                                >
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
          
            <PreviewTable headers={headerKeys} rows={array}/>
        </div>
    )
}
