import React, { useCallback, useState, useMemo } from 'react'
import * as yup from 'yup'
import { useFormik } from 'formik'
import FormikAntInputPassword from '../components/FormikAntDInputs/FormikAntInputPassword'
import PasswordUpdate from 'src/components/Modals/PasswordModals/PasswordUpdate'
import { validations } from '../utils/yupValidations'
import { sleep } from '../utils/promise'
import { updatePassword } from '../store/reducers/auth/auth.actions'
import { useDispatch } from 'react-redux'
import useAuth from '../hooks/useAuth'
import SomethingWentWrong from 'src/components/Modals/ErrorModals/SomethingWentWrong'
import { useUserInfo } from '../store/reducers/userInfoReducer/userInfo.hooks'
import { shortenIfAddress } from '@usedapp/core'
import dayjs from 'dayjs'
import Button from 'src/components/Buttons/Button'

const ProfileInfo = ({ userName, address, dateJoined }) => {
    const $dateJoined = useMemo(() => {
        return dayjs(dateJoined).format('MMMM DD, YYYY')
    }, [dateJoined])

    return (
        <div className="bg-palette-black-800 px-4 pt-5 pb-8">
            <div className="flex flex-row">
                <div>
                    <div className="text-palette-gray-700 text-xs font-semibold">
                        USERNAME:
                    </div>
                    <div className="text-palette-brown-901 text-2xl font-bold font-oxanium uppercase">
                        {userName}
                    </div>
                    <div className="text-palette-gray-700 text-xs font-semibold">
                        Joined {$dateJoined}
                    </div>
                </div>
                <div className="ml-auto"></div>
            </div>
            <div className="flex flex-row items-center mt-3">
                <div className="mr-4 ont-semibold text-xs text-palette-gray-700">
                    METAMASK ADDRESS:
                </div>
                {address && (
                    <div className="bg-palette-black-725 px-4 py-2 text-palette-gray-100 font-semibold text-xs">
                        {shortenIfAddress(address)}
                    </div>
                )}
            </div>
        </div>
    )
}

// const ReferalLink = () => {
//     return (
//         <div className="bg-palette-black-800 w-full pt-5 pb-6 px-4 lg:pl-8 lg:pr-12">
//             <div className="text-ingiite-gray-700 text-xs font-semibold">
//                 REFERAL LINk
//             </div>
//             <div className="flex flex-row mt-4 items-center">
//                 <div className="text-sm leading-[14px] font-medium text-palette-gray-100">
//                     igniite.co/josm456
//                 </div>
//                 <div className="ml-auto text-palette-brown-901 text-lg">
//                     <IoCopyOutline />
//                 </div>
//             </div>
//         </div>
//     )
// }

// const ReferalID = () => {
//     return (
//         <div className="bg-palette-black-800 w-full pt-5 pb-6 px-4 lg:pl-8 lg:pr-12">
//             <div className="text-ingiite-gray-700 text-xs font-semibold">
//                 REFERAL ID
//             </div>
//             <div className="flex flex-row mt-4 items-center">
//                 <div className="text-2xl leading-[24px] text-palette-gray-100 font-bold">
//                     AS54DFSD
//                 </div>
//                 <div className="ml-auto text-palette-brown-901 text-lg">
//                     <IoCopyOutline />
//                 </div>
//             </div>
//         </div>
//     )
// }

const UserPersonalData = ({ email, firstName, lastName }) => {
    return (
        <div className="bg-palette-black-800 flex flex-row px-4 py-5 lg:p-9">
            <div className="text-palette-gray-700 text-xs flex-1">
                <div className="flex flex-col gap-4">
                    <div className="flex flex-row w-full">
                        <div className="mr-4 w-[calc(65.31px_+_20px)] text-right">
                            EMAIL
                        </div>
                        <div className="text-palette-gray-100 text-sm font-semibold">
                            {email}
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="mr-4 w-[calc(65.31px_+_20px)] text-right">
                            FIRST NAME
                        </div>
                        <div className="text-palette-gray-100 text-sm font-semibold">
                            {firstName}
                        </div>
                    </div>
                    <div className="flex flex-row w-full">
                        <div className="mr-4 w-[calc(65.31px_+_20px)] text-right">
                            LAST NAME
                        </div>
                        <div className="text-palette-gray-100 text-sm font-semibold">
                            {lastName}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const schema = yup.object().shape({
    currentPassword: validations.generic,
    password: validations.password,
    passwordConfirm: validations.passwordConfirmation,
})

const ChangePassword = () => {
    const [
        {
            edit,
            loading,
            modals: { confirmModal, error },
        },
        setState,
    ] = useState({
        edit: false,
        loading: false,
        modals: {
            confirmModal: false,
            error: false,
        },
    })

    const { logout } = useAuth()
    const dispatch = useDispatch()
    const enableEdit = () =>
        setState((state) => ({ ...state, edit: !state.edit }))

    const startLoading = () =>
        setState((state) => ({ ...state, loading: !state.loading }))

    const showModal = (name) =>
        setState((state) => ({
            ...state,
            modals: { ...state.modals, [name]: !state.modals[name] },
        }))

    const handleSubmit = async (values) => {
        showModal('confirmModal')
    }

    const formik = useFormik({
        initialValues: {
            password: '',
            passwordConfirm: '',
        },
        onSubmit: handleSubmit,
        validationSchema: schema,
    })

    const reset = useCallback(async () => {
        enableEdit()
        await sleep(100)
        formik.resetForm()
    }, [formik])

    const handleUpdatePassword = useCallback(async () => {
        startLoading()

        await sleep(1000)
        try {
            await dispatch(updatePassword(formik.values)).unwrap()
            await sleep(1000)
            logout()
        } catch (error) {
            await sleep(1000)
            formik.resetForm()
            enableEdit()
            startLoading()
            showModal('confirmModal')
            // await sleep(200)
            showModal('error')
        }
    }, [formik, dispatch])

    return (
        <div className="bg-palette-black-800 flex flex-col py-5 lg:pt-6 lg:pb-1 px-4 lg:pl-8 lg:pr-12">
            <PasswordUpdate
                visible={confirmModal}
                onConfirm={handleUpdatePassword}
                onClose={() => {
                    showModal('confirmModal')
                }}
                loading={loading}
            />
            <SomethingWentWrong
                visible={error}
                onClose={() => {
                    showModal('error')
                }}
            />
            <div className="text-palette-gray-100">CHANGE PASSWORD</div>
            <form className=" mt-4" onSubmit={formik.handleSubmit}>
                <div className="flex flex-col lg:flex-row lg:gap-4">
                    {' '}
                    <div className="w-full lg:w-[70%] xl:w-[75%] flex flex-col">
                        <div className="w-full">
                            <FormikAntInputPassword
                                name={'currentPassword'}
                                formik={formik}
                                placeholder="CURRENT PASSWORD"
                                size="small"
                                disabled={!edit}
                            />
                        </div>
                        <div className="flex flex-row gap-2">
                            <div className="w-full">
                                <FormikAntInputPassword
                                    name={'password'}
                                    formik={formik}
                                    placeholder="PASSWORD"
                                    size="small"
                                    disabled={!edit}
                                />
                            </div>
                            <div className="w-full">
                                <FormikAntInputPassword
                                    name={'passwordConfirm'}
                                    formik={formik}
                                    placeholder="CONFIRM PASSWORD"
                                    size="small"
                                    disabled={!edit}
                                />
                            </div>
                        </div>
                    </div>
                    {!edit ? (
                        <div className="flex-1 flex lg:mt-auto lg:mb-[23.99px]">
                            <Button
                                variant="dark"
                                onClick={reset}
                                className=" font-semibold text-xs w-full lg:w-[calc(50%_-_8px)] py-1 h-10 lg:ml-auto"
                            >
                                EDIT
                            </Button>
                        </div>
                    ) : (
                        <div className="flex-1 flex flex-row gap-2 lg:mt-auto lg:mb-[23.99px]">
                            <Button
                                variant="dark"
                                onClick={reset}
                                className="font-semibold text-xs w-full py-1 h-10"
                            >
                                CANCEL
                            </Button>{' '}
                            <Button
                                variant="dark"
                                type="submit"
                                className="font-semibold text-xs w-full py-1 h-10"
                            >
                                ACCEPT
                            </Button>
                        </div>
                    )}
                </div>
            </form>
        </div>
    )
}

const Profile = () => {
    const { username, address, dateJoined, email, firstName, lastName } =
        useUserInfo()

    return (
        <div className="pb-8">
            <div className="font-oxanium font-bold text-xl text-palette-gray-700 mb-6">
                MY PROFILE
            </div>
            <div className="flex flex-col lg:flex-row gap-4">
                <div className="w-full lg:w-[30%] flex flex-col gap-4 text-white">
                    <ProfileInfo
                        userName={username}
                        address={address}
                        dateJoined={dateJoined}
                    />
                </div>
                <div className="flex-1 w-full">
                    <div className="flex flex-col gap-4">
                        <UserPersonalData
                            email={email}
                            firstName={firstName}
                            lastName={lastName}
                        />
                        <ChangePassword />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Profile
