import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../services/axiosConfig'
import { reloadActiveOrders, reloadActiveTrades } from './position.slice'

export const getUserActiveTrades = createAsyncThunk(
    'positionResolver/getuseractivetrades',
    async (payload, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/manager/getuseractivetrades/`
            )

            return res.data.data
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getUserTrades = createAsyncThunk(
    'positionResolver/getUserTrades',
    async (keyId, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get(`bybit/getusertrades/${keyId}`)
            return { keyId, data: res.data }
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const getUserOrders = createAsyncThunk(
    'positionResolver/getUserOrders',
    async (keyId, { rejectWithValue, getState }) => {
        try {
            const res = await axiosInstance.get(`bybit/getuserorders/${keyId}`)

            return { keyId, data: res.data }
        } catch (error) {
            if (!error.response) throw error
            return rejectWithValue(error.response.data)
        }
    }
)

export const placeCloseLimit = createAsyncThunk(
    'placeTrade/placeCloseLimit',
    async (
        { side, symbol, keyId, qty, entryPrice },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/tradingmanager/placecloselimit/`,
                {
                    keyId,
                    side,
                    symbol,
                    qty,
                    entryPrice,
                }
            )
            dispatch(reloadActiveTrades())
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const setStopLoss = createAsyncThunk(
    'placeTrade/setSL',
    async (
        { side, symbol, keyId, qty, stopLoss },
        { rejectWithValue, dispatch }
    ) => {
        try {
            const res = await axiosInstance.post(
                `bybit/manager/settakeprofit/`,
                {
                    keyId,
                    side,
                    symbol,
                    qty,
                    takeProfit: 0,
                    stopLoss,
                }
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const closeposition = createAsyncThunk(
    'payments/closePosition',
    async ({ keyId, symbol }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post(
                'bybit/manager/closeposition/',
                {
                    keyId,
                    symbol,
                }
            )
            dispatch(reloadActiveTrades())
            return res.data
        } catch (error) {}
    }
)

export const cancelOrder = createAsyncThunk(
    'payments/cancelOrder',
    async ({ keyId, symbol, orderId }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post('bybit/manager/cancelorder/', {
                keyId,
                symbol,
                orderId,
            })
            dispatch(reloadActiveOrders())
            return res.data
        } catch (error) {}
    }
)
