import { useState, useCallback, useEffect } from 'react'
import { useFormik } from 'formik'
import { sleep } from '../../../utils/promise'
import { Modal } from 'antd'
import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'
import {
    formatValueToNumberOnChange,
    formatValueToNumber,
} from 'src/utils/inputsUtilities'
import Button from 'src/components/Buttons/Button'
import { useSetLeverage } from '../../../store/reducers/exchangeReducer/exchange.hooks'

export const ChangeLeverage = ({
    keyId,
    symbol,
    visible,
    onClose,
    leverage,
}) => {
    const setLeverage = useSetLeverage()
    const [loading, setLoading] = useState(false)

    const handleSubmit = useCallback(
        async (values) => {
            try {
                if (
                    !values.shortLeverage ||
                    !values.longLeverage ||
                    values.shortLeverage === '0' ||
                    values.longLeverage === '0'
                ) {
                    return
                }
                setLoading(true)
                await sleep(600)
                await setLeverage({ ...values, symbol, keyId }).unwrap()
                onClose()
            } catch (error) {
                console.log({ error })
            }
            setLoading(false)
        },
        [symbol, keyId, setLeverage]
    )

    const initialValues = {
        shortLeverage: leverage?.short,
        longLeverage: leverage?.long,
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        onSubmit: handleSubmit,
    })

    useEffect(() => {
        if (!visible) formik.setValues(initialValues)
    }, [visible])

    return (
        <Modal
            centered
            // closable={false}
            visible={visible}
            maskClosable={false}
            footer={null}
            onCancel={onClose}
        >
            <div className="pb-4">
                <div className="text-3xl font-bold text-center text-palette-brown-700">
                    Adjust Leverage
                </div>
                <form
                    onSubmit={formik.handleSubmit}
                    className="flex flex-col mt-4"
                >
                    <FormikAntInput
                        size="small"
                        name="shortLeverage"
                        formik={formik}
                        prefix={<div className="w-24">Short Leverage</div>}
                        // adddonAfter="HI"
                        placeholder=""
                        value={formik.values?.shortLeverage}
                        transform={{
                            output: (value) => {
                                return formatValueToNumberOnChange(value, {
                                    decimals: 2,
                                    min: 0,
                                    max: 100,
                                    enableEmptyValue: true,
                                })
                            },
                            blur: (value) => {
                                const { longLeverage } = formik.values

                                if (
                                    value === '0' ||
                                    (value === '' &&
                                        longLeverage !== '0' &&
                                        longLeverage !== '')
                                ) {
                                    return longLeverage
                                }
                                return formatValueToNumber(value)
                            },
                        }}
                    />
                    <FormikAntInput
                        size="small"
                        name="longLeverage"
                        formik={formik}
                        prefix={<div className="w-24">Long Leverage</div>}
                        // adddonAfter="HI"
                        placeholder=""
                        value={formik.values?.longLeverage}
                        transform={{
                            output: (value) => {
                                return formatValueToNumberOnChange(value, {
                                    decimals: 2,
                                    min: 0,
                                    max: 100,
                                    enableEmptyValue: true,
                                })
                            },
                            blur: (value) => {
                                const { shortLeverage } = formik.values

                                if (
                                    value === '0' ||
                                    (value === '' &&
                                        shortLeverage !== '0' &&
                                        shortLeverage !== '')
                                ) {
                                    return shortLeverage
                                }
                                return formatValueToNumber(value)
                            },
                        }}
                    />

                    <div className="text-justify text-palette-gray-700 font-medium mt-4">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Consequatur sint aperiam eius perspiciatis vel libero
                        dolore aut quas minima! Voluptas.
                        <a className="underline ml-2">
                            Read Terms and Conditions
                        </a>
                    </div>

                    <Button
                        variant="light"
                        type="submit"
                        className="mt-6 w-full rounded-sm"
                        loading={loading}
                        disabled={loading}
                    >
                        {!loading ? 'Confirm' : ''}
                    </Button>
                </form>
            </div>
        </Modal>
    )
}
