/* eslint-disable camelcase */
import { createReducer } from '@reduxjs/toolkit'
import { getUserExtraInfo } from './userInfo.actions'

export const initialState = {
    id: '',
    lastLogin: '',
    isSuperuser: false,
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    isStaff: false,
    isActive: false,
    dateJoined: '',
    alias: '',
    address: '',
    referralLink: '',
    country: '',
    authenticator: false,
    active: false,
}

const userInfoReducer = createReducer(initialState, (builder) => {
    builder.addCase(
        getUserExtraInfo.fulfilled,
        (
            state,
            {
                payload: {
                    last_login,
                    is_superuser,
                    first_name,
                    last_name,
                    is_staff,
                    is_active,
                    date_joined,
                    ...rest
                },
            }
        ) => {
            return {
                ...state,
                ...rest,

                lastLogin: last_login,
                isSuperuser: is_superuser,

                firstName: first_name,
                lastName: last_name,

                isStaff: is_staff,
                isActive: is_active,
                dateJoined: date_joined,
            }
        }
    )
})

export default userInfoReducer
