/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react'
import ButtonTemporality from './ButtonTemporality'

import {
    MONTHLY,
    WEEKLY,
    CUSTOM,
    // DAYS_90,
    YEARLY,
} from '../../constants/temporality.constants'

import locale from 'antd/es/date-picker/locale/en_US'

import dayjs from 'dayjs'
import DatePicker from '../AntWithDaysJs/DatePicker'
import { Select } from 'antd'

const { RangePicker } = DatePicker

export const MobileTemporality = ({ className, selected, onSelect }) => {
    const [dates, setDates] = useState(null)
    const [hackValue, setHackValue] = useState(null)
    const [value, setValue] = useState(null)

    const handlePickDate = useCallback(
        (v) => {
            onSelect(CUSTOM, v)
            setValue(v)
        },
        [onSelect]
    )
    const disabledDate = (current) => {
        if (!dates) {
            return false
        }
        const tooLate = dates[0] && current.diff(dates[0], 'days') > 180
        const tooEarly = dates[1] && dates[1].diff(current, 'days') > 180

        return (
            !!tooEarly ||
            !!tooLate ||
            (current && current > dayjs().endOf('day')) ||
            current.isBefore('2023-05-02')
        )
    }
    const onOpenChange = (open) => {
        if (open) {
            setHackValue([null, null])
            setDates([null, null])
        } else {
            setHackValue(null)
        }
    }

    const handleChange = (value) => {
        onSelect(value)
    }

    return (
        <div>
            <div className="flex justify-end mb-3">
                <Select
                    defaultValue="YTD"
                    style={{
                        width: 117,
                        color: '#8D793F',
                        fontFamily: 'Maven Pro',
                        fontWeight: '800',
                        fontSize: '12px',
                    }}
                    className="temporality-selector"
                    onChange={handleChange}
                    options={[
                        {
                            value: YEARLY,
                            label: 'YTD',
                        },
                        {
                            value: WEEKLY,
                            label: 'WEEKLY',
                        },
                        {
                            value: MONTHLY,
                            label: 'MONTHLY',
                        },
                        {
                            value: CUSTOM,
                            label: 'DATE',
                        },
                    ]}
                />
            </div>
            <div
                className={`my-3 justify-center ${
                    selected === CUSTOM ? 'flex' : 'hidden'
                }`}
            >
                <RangePicker
                    value={hackValue || value}
                    className="w-10/12 py-1"
                    size="small"
                    locale={locale}
                    onCalendarChange={(val) => setDates(val)}
                    onChange={handlePickDate}
                    onOpenChange={onOpenChange}
                    disabledDate={disabledDate}
                />
            </div>
        </div>
    )
}
