import { useState, useEffect } from 'react'
import useEventListener from './useEventListener'

export default function useMediaQuery(mediaQuery) {
    const getMatches = (query) => {
        if (typeof window !== 'undefined') {
            return window.matchMedia(query).matches
        }
        return false
    }

    const [isMatch, setIsMatch] = useState(getMatches(mediaQuery))
    const [matchMedia, setMatchMedia] = useState(null)

    function handleChange(matches) {
        setIsMatch(matches)
    }

    useEffect(() => {
        const _matchMedia = window.matchMedia(mediaQuery)
        handleChange(_matchMedia.matches)
        setMatchMedia(_matchMedia)
    }, [mediaQuery])

    useEventListener(
        'change',
        (e) => {
            handleChange(e.matches)
        },
        matchMedia
    )

    return isMatch
}
