import React, { useCallback } from 'react'
import { Modal } from 'antd'
import useSubscribeKey from './../../../hooks/useSubscribeKey'

const SubscribeKey = ({ visible, onClose, enableTrading, id }) => {
    const { subscribe, unsubscribe } = useSubscribeKey()

    const handleOnChange = useCallback(async () => {
        try {
            if (enableTrading) {
                await unsubscribe(id).unwrap()
            } else {
                await subscribe(id).unwrap()
            }
            onClose()
        } catch (error) {
            console.log(error)
        }
    }, [id, enableTrading, subscribe, unsubscribe])

    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            onCancel={onClose}
            // maskClosable={false}
            footer={null}
            width={526}
            bodyStyle={{
                height: 260,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-evenly',
                alignContent: 'center',
            }}
        >
            <div className="flex flex-col gap-2 text-center ">
                <div className="mx-auto text-base text-palette-gray-700 font-semibold ">
                    TRADE WILL {!enableTrading ? 'START' : 'STOP'}
                </div>
                <div className="mx-auto text-base text-palette-brown-901 font-semibold ">
                    ARE YOU AGREE?
                </div>
            </div>

            <div className="flex flex-row justify-around h-14">
                <button
                    className="flex justify-center items-center text-base h-14 w-40 text-palette-gray-700 font-semibold  bg-palette-black-725 "
                    onClick={handleOnChange}
                >
                    YES
                </button>
                <button
                    className="flex justify-center items-center text-base h-14 w-40 text-palette-gray-700 font-semibold bg-palette-black-725 
                    "
                    onClick={onClose}
                >
                    NO
                </button>
            </div>
        </Modal>
    )
}

export default SubscribeKey
