import { useDispatch, useSelector } from 'react-redux'
import { useCallback, useEffect, useState } from 'react'
import useDebounce from '../../../hooks/useDebounce'
import useRefresh from '../../../hooks/useRefresh'
import {
    getUserActiveTrades,
    getUserTrades,
    getUserOrders,
    placeCloseLimit,
    setStopLoss,
    cancelOrder,
    closeposition,
} from './position.actions'
import { notification } from 'antd'
import { ANT_ICON_NOTIFICATION } from 'src/constants/antsConstants'

const selectSelf = (state) => state.positionReducer

export const usePositionResolverState = () => {
    return useSelector(selectSelf)
}

export const useFetchUserActiveTrades = () => {
    const dispatch = useDispatch()

    const fetch = useCallback(() => dispatch(getUserActiveTrades()), [dispatch])

    useEffect(() => {
        fetch()
    }, [])

    return { reload: fetch }
}

export const useFetchUserTrades = (keyId) => {
    const [init, setInit] = useState(false)
    const { fastRefresh } = useRefresh()
    const { reloadActiveTrades } = usePositionResolverState()
    const dispatch = useDispatch()

    const fetch = useCallback(() => {
        if (keyId > 0) dispatch(getUserTrades(keyId))
    }, [dispatch, keyId])

    const start = useCallback(() => {
        if (!init) setInit(true)
    }, [init])

    useEffect(() => {
        start()
    }, [keyId])

    useEffect(() => {
        if (init) fetch()
    }, [fastRefresh, init, reloadActiveTrades])

    useDebounce(
        () => {
            fetch()
        },
        1000,
        [keyId]
    )

    return { reload: fetch }
}

export const useFetchUserOrders = (keyId) => {
    const [init, setInit] = useState(false)
    const { fastRefresh } = useRefresh()
    const dispatch = useDispatch()
    const { reloadActiveOrders } = usePositionResolverState()

    const fetch = useCallback(() => {
        if (keyId > 0) dispatch(getUserOrders(keyId))
    }, [dispatch, keyId])

    const start = useCallback(() => {
        if (!init) setInit(true)
    }, [init])

    useEffect(() => {
        start()
    }, [keyId])

    useEffect(() => {
        if (init) fetch()
    }, [fastRefresh, init, reloadActiveOrders])

    useDebounce(
        () => {
            fetch()
        },
        1000,
        [keyId]
    )
    return { reload: fetch }
}

export const useSetTP = () => {
    const dispatch = useDispatch()

    return useCallback(
        async ({ entryPrice, side, symbol, keyId, ...payload }) => {
            try {
                console.log('payload', payload)
                console.log('entryPrice', entryPrice)
                console.log('side', side)
                console.log('symbol', symbol)
                console.log('keyId', keyId)

                await dispatch(
                    placeCloseLimit({
                        entryPrice,
                        side,
                        symbol,
                        keyId,
                        ...payload,
                    })
                ).unwrap()
                notification.success({
                    icon: ANT_ICON_NOTIFICATION.SUCCESS,
                    message:
                        entryPrice > 0
                            ? 'Setted take profit'
                            : 'Removed take profit',
                    description:
                        entryPrice > 0
                            ? 'You successfully set a take profit to your position'
                            : 'You successfully remove your take profit from your position',
                    placement: 'bottomRight',
                })
            } catch (error) {
                notification.error({
                    icon: ANT_ICON_NOTIFICATION.ERROR,
                    message: 'Error',
                    description: 'Failed to set a take profit. Try again',
                    placement: 'bottomRight',
                })
            }
        },
        [dispatch]
    )
}

export const useSetSL = () => {
    const dispatch = useDispatch()
    return useCallback(
        async ({ stopLoss, ...payload }) => {
            try {
                await dispatch(setStopLoss({ stopLoss, ...payload })).unwrap()
                notification.success({
                    icon: ANT_ICON_NOTIFICATION.SUCCESS,
                    message:
                        stopLoss > 0 ? 'Setted stop loss' : 'Removed stop loss',
                    description:
                        stopLoss > 0
                            ? 'You successfully set a stop loss to your position'
                            : 'You successfully remove your stop loss from your position',
                    placement: 'bottomRight',
                })
                // dispatch(reloadTrades())
            } catch (error) {
                notification.error({
                    icon: ANT_ICON_NOTIFICATION.ERROR,
                    message: 'Error',
                    description: 'Failed to set stop loss. Try again',
                    placement: 'bottomRight',
                    // style: {
                    //     width
                    // }
                })
            }
        },
        [dispatch]
    )
}

export const useClosePosition = () => {
    const dispatch = useDispatch()

    return useCallback(
        (payload) => dispatch(closeposition(payload)),
        [dispatch]
    )
}

export const useCancelOrder = () => {
    const dispatch = useDispatch()

    return useCallback((payload) => dispatch(cancelOrder(payload)), [dispatch])
}
