import React from 'react'
import { Modal } from 'antd'
import { FaSpinner } from 'react-icons/fa'

const ConfirmModal = ({
    visible,
    onClose,
    onConfirm,
    confirmButtonText = 'CONFIRM',
    children,
    loading,
    width = 526,
}) => {
    return (
        <Modal
            centered
            closable={false}
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={width}
        >
            <div className="w-full pt-8">
                {children}
                {!loading ? (
                    <div className="flex flex-row justify-center items-center w-full gap-3 mt-12">
                        <button
                            className="flex justify-center items-center text-base h-14 w-40 text-palette-gray-700 font-semibold bg-palette-black-725"
                            onClick={onClose}
                        >
                            CANCEL
                        </button>
                        <button
                            className="flex justify-center items-center text-base h-14 w-40 bg-palette-black-725 text-palette-brown-901 font-semibold border border-palette-brown-901"
                            onClick={onConfirm}
                        >
                            {confirmButtonText}
                        </button>
                    </div>
                ) : (
                    <div className="flex flex-row justify-center items-center w-full gap-3 mt-12">
                        <button
                            className="flex justify-center items-center text-base h-14 w-40 bg-palette-black-725 text-palette-brown-901 font-semibold border border-palette-brown-901"
                            onClick={onConfirm}
                        >
                            <FaSpinner className="animate-spin inline-block relative" />
                        </button>
                    </div>
                )}
            </div>
        </Modal>
    )
}

export default ConfirmModal
