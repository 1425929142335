import { createSelector } from '@reduxjs/toolkit'
import { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CUSTOM, WEEKLY } from 'src/constants/temporality.constants'
import useDebounce from '../../../hooks/useDebounce'
import { getStartDateTemporality } from 'src/utils/temporalityServices'
import { useUserInfo } from 'src/store/reducers/userInfoReducer/userInfo.hooks'
import useRefresh from '../../../hooks/useRefresh'
import {
    getAllWalletBalance,
    getreferralbyuser,
    getAdminReferralbyuser,
} from './admin.actions'

const selectSelf = (state) => state.adminReducer

export const useAdminState = () => {
    return useSelector(selectSelf)
}

export const balancesSelector = createSelector(
    selectSelf,
    (self) => self.balances
)

export const useBalances = ({ temporality = WEEKLY, rangeDate }) => {
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState()
    const { slowRefresh } = useRefresh()
    // const data = useSelector(balancesSelector)
    const dispatch = useDispatch()
    const getAllBalances = useCallback(async () => {
        if (loading) return
        setLoading(true)
        try {
            if (temporality === CUSTOM) {
                const [start, end] = rangeDate

                await dispatch(
                    getAllWalletBalance({
                        start: start.format('YYYY-MM-DD'),
                        end: end.format('YYYY-MM-DD'),
                    })
                ).unwrap()
            } else {
                const start = getStartDateTemporality(temporality)

                await dispatch(getAllWalletBalance({ start })).unwrap()
            }
        } catch (error) {
            console.log('Error:', error)
            setError(error)
        } finally {
            setLoading(false)
        }
    }, [dispatch, loading, temporality, rangeDate])

    useEffect(() => {
        getAllBalances()
    }, [slowRefresh])

    useDebounce(
        () => {
            getAllBalances()
        },
        600,
        [temporality, rangeDate]
    )

    return { error, loading, getAllBalances }
}

export const useFetchAdminReferral = () => {
    const dispatch = useDispatch()
    const userExtraInfo = useUserInfo()

    const fetch = useCallback(() => {
        dispatch(getAdminReferralbyuser(userExtraInfo.id))
    }, [userExtraInfo])

    useEffect(() => {
        fetch()
    }, [])
}

export const useFetchReferral = () => {
    const dispatch = useDispatch()
    const userExtraInfo = useUserInfo()

    const fetch = useCallback(() => {
        dispatch(getreferralbyuser(userExtraInfo.id))
    }, [userExtraInfo])

    useEffect(() => {
        fetch()
    }, [])
}

