import React from 'react'
import Tag from 'antd/es/tag'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

export const EpochsCondensed = ({epochs, initialAmount, pnl}) => {
  return (
      <div>          
          <div className=" mb-4 flex flex-wrap">
              {epochs.length > 0 ? (
                  epochs.map((e) => (
                      <Tag color="#716235" className="mb-3" key={e.id}>
                          Epoch {e.id}
                      </Tag>
                  ))
              ) : (
                  <Tag color="#716235" className="mb-3">
                      No epochs registered
                  </Tag>
              )}
          </div>
          <div className="flex flex-row w-full">
              <div className="pr-2 flex flex-col flex-1">
                  <div className="font-semibold font-maven text-xs text-palette-gray-700 mb-3">
                      Initial Amount
                  </div>
                  <div className="tracking-tighter font-medium font-oxanium text-palette-gray-100 mt-auto text-base leading-none">
                      {formatNumberToCurrency(initialAmount)}
                  </div>
              </div>
              <div className="flex flex-col pr-4 flex-1 border-l-2 pl-6 border-[#2B2B2B]">
                  <div className="font-semibold font-maven text-xs text-palette-gray-700 mb-3">
                      Actual Balance
                  </div>
                  <div className="text-[#B2955F] text-base font-medium font-oxanium mt-auto leading-none">
                      {formatNumberToCurrency(initialAmount + pnl)}
                      <span className="text-xs leading-none"> USD</span>{' '}
                  </div>
              </div>
          </div>
      </div>
  )
}
