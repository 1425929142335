import symbols from 'src/JSONS/symbols.json'
/**
 * Get the format currency for a symbol (e.g. $0,000.00, 0,000.0000, etc.)
 */
export const getSymbolFormat = (symbol) => {
    const $symbol = symbols.find(
        (s) =>
            s.symbol.toUpperCase() === symbol.toUpperCase() ||
            s.baseCurrency.toUpperCase() === symbol
    )

    if (!$symbol) return '$0,000.00'
    return `$${$symbol.format}`
}

export const getSymbolQtyStep = (symbol) => {
    const $symbol = symbols.find(
        (s) =>
            s.symbol.toUpperCase() === symbol.toUpperCase() ||
            s.baseCurrency.toUpperCase() === symbol
    )

    if (!$symbol) return 1
    return $symbol.qtyStep
}

export const getSymbolData = (symbol) => {
    return (
        symbols.find(
            (s) =>
                s.symbol.toUpperCase() === symbol.toUpperCase() ||
                s.baseCurrency.toUpperCase() === symbol
        ) || {}
    )
}
