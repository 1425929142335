import React, { useCallback, useRef } from 'react'
import CustomModal from 'src/components/Modals/GenericModals/CustomModal'
import AddCustomerToEpochForm from './AddCustomerToEpochForm'
import { useAddUserToEpoch } from 'src/store/reducers/staffReducer/staff.hooks'

export const AddCustomerToEpochModal = ({
    visible = false,
    onClose,
    selectedSeller,
    user
}) => {
    const formRef = useRef(null)
    const { addUserToEpoch, loading } = useAddUserToEpoch()

    const handleClose = () => {
        formRef.current.resetForm()
        onClose()
    }

    const handleOkay = useCallback(() => {
        formRef.current.submitForm()
    }, [])

    const handleSubmit = useCallback(
        async (values) => {
            await addUserToEpoch({ ...values, user })
            handleClose()
        },
        [addUserToEpoch, selectedSeller]
    )

    return (
        <CustomModal
            loading={loading}
            visible={visible}
            title={'ADD CUSTOMER TO EPOCH'}
            width={450}
            onCancel={handleClose}
            onOk={handleOkay}
        >
            <AddCustomerToEpochForm ref={formRef} onSubmit={handleSubmit} />
        </CustomModal>
    )
}
