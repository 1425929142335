import { updateArrayPosition } from 'src/utils/arrays'
import { createReducer } from '@reduxjs/toolkit'
import {
    setActiveAccount,
    getKeyEmisors,
    getAccountBalance,
    getActiveTrades,
    getBybitTrades,
    saveKeys,
    getKeys,
    getBybitOrders,
    subscribePosition,
    unsubscribePosition,
    deleteClosePosition,
    subscribechildren,
    setSubscribed,
    resetSubscribed,
    unsubscribingBybit,
    unsubscribeChildren,
    subscribeAll,
    unsuscribeAll,
    updateKey,
} from './dashboard.actions'

export const BYBIT = 'BYBIT'
export const CLIENTS = 'CLIENTS'

export const initialState = {
    activekeysbybit: null,
    keysbybit: [],
    tradesBybit: [],
    ordersBybit: null,
    balance: 0,
    cumpnl: 0,
    initialbalance: 0,
    refresh: false,
    activeTrades: [],
    keyEmisor: [],
    subscriptions: {},
}

const dashboardReducer = createReducer(initialState, (builder) => {
    builder
        .addCase(saveKeys.fulfilled, (state, { payload }) => {
            state.activekeysbybit = payload.activeAccount
        })
        .addCase(getKeys.fulfilled, (state, { payload }) => {
            if (payload) {
                state.keysbybit = payload.map(({ secret, key, ...v }) => v)
                if (!state.activekeysbybit) {
                    state.activekeysbybit = payload[0].id
                }
            }
            if (payload === '') {
                state.keysbybit = []
                state.activekeysbybit = null
            }
        })
        .addCase(getBybitTrades.fulfilled, (state, { payload }) => {
            state.tradesBybit = payload
        })
        .addCase(getBybitOrders.fulfilled, (state, { payload }) => {
            state.ordersBybit = payload
        })
        .addCase(subscribePosition.fulfilled, (state, { payload }) => {
            state.refresh = !state.refresh
        })
        .addCase(subscribePosition.rejected, (state, { payload }) => {})
        .addCase(getActiveTrades.fulfilled, (state, { payload }) => {
            state.activeTrades = payload
        })
        .addCase(getKeyEmisors.fulfilled, (state, { payload }) => {
            state.keyEmisor = payload
        })
        .addCase(unsubscribePosition.fulfilled, (state, { payload }) => {
            state.refresh = !state.refresh
        })
        .addCase(setActiveAccount, (state, { payload }) => {
            state.activekeysbybit = payload
        })
        .addCase(deleteClosePosition.fulfilled, (state, { payload }) => {
            state.refresh = !state.refresh
        })
        .addCase(getAccountBalance.fulfilled, (state, { payload }) => {
            state.balance = payload[0]?.wallet_balance ?? 0
            // TODO: Fix this with user trading balances table instead of cumrealized pnl
            state.cumpnl = payload[0]?.cum_realised_pnl ?? 0
            state.initialbalance = payload[0]?.initialBalance ?? 0
        })
        .addCase(deleteClosePosition.rejected, (state, { payload }) => {})
        .addCase(subscribechildren.fulfilled, (state) => {
            state.subscriptions = {
                ...state.subscriptions,
                [CLIENTS]: {
                    subscribed: true,
                    error: false,
                },
            }
        })
        .addCase(subscribechildren.rejected, (state, { payload }) => {
            if (payload?.status === 'duplicated thread') {
                state.subscriptions = {
                    ...state.subscriptions,
                    [CLIENTS]: {
                        subscribed: true,
                        error: false,
                    },
                }
            } else {
                state.subscriptions = {
                    ...state.subscriptions,
                    [CLIENTS]: {
                        subscribed: false,
                        error: true,
                    },
                }
            }
        })
        .addCase(setSubscribed, (state, { payload }) => {
            state.subscriptions = {
                ...state.subscriptions,
                [`${BYBIT}-${payload}`]: {
                    subscribed: true,
                    error: false,
                },
            }
        })
        .addCase(resetSubscribed, (state, { payload }) => {
            state.subscriptions = {
                ...state.subscriptions,
                [`${BYBIT}-${payload}`]: {
                    subscribed: false,
                    error: true,
                },
            }
        })
        .addCase(unsubscribingBybit.fulfilled, (state) => {
            state.subscriptions = {
                ...state.subscriptions,
                [BYBIT]: {
                    subscribed: false,
                    error: false,
                },
            }
        })
        .addCase(unsubscribeChildren.fulfilled, (state) => {
            state.subscriptions = {
                ...state.subscriptions,
                [CLIENTS]: {
                    subscribed: false,
                    error: false,
                },
            }
        })
        .addCase(subscribeAll.fulfilled, (state) => {
            const updateKeys = state.keysbybit.reduce((acc, v) => {
                return [...acc, { ...v, subscribed: true }]
            }, [])

            state.keysbybit = updateKeys
        })
        .addCase(unsuscribeAll.fulfilled, (state) => {
            const updateKeys = state.keysbybit.reduce((acc, v) => {
                return [...acc, { ...v, subscribed: false }]
            }, [])

            state.keysbybit = updateKeys
        })
        .addCase(updateKey, (state, { payload }) => {
            const index = state.keysbybit.findIndex((v) => v.id === payload)
            const updatedKey = state.keysbybit[index]
            updatedKey.subscribed = !updatedKey.subscribed

            const newarrkeysbybit = updateArrayPosition(
                state.keysbybit,
                index,
                updatedKey
            )
            state.keysbybit = newarrkeysbybit
        })
})

export default dashboardReducer
