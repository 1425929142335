import {
    StatsTitle,
    StatsContainer,
    StatsDefaultValue,
} from '../StatsComponents'

const Stats4 = ({ value = 0, title, active = 0, closed = 0 }) => {
    return (
        <StatsContainer>
            <div className="flex flex-col">
                <div className="mb-4">
                    <StatsTitle title={title} />
                    <StatsDefaultValue value={value} />
                </div>
                <div className="flex flex-row justify-around font-medium text-[10px] lg:text-xs text-palette-brown-901">
                    <div className="mr-auto">{active} ACTIVE</div>
                    <div className="m-auto">{closed} CLOSED</div>
                </div>
            </div>
        </StatsContainer>
    )
}

export default Stats4
