import React, { useImperativeHandle, useCallback } from 'react'

import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'

import * as yup from 'yup'
import { validations } from 'src/service/yupValidations'
import { useFormik } from 'formik'

import FormikAntInputPassword from 'src/components/FormikAntDInputs/FormikAntInputPassword'

const AddSellerForm = React.forwardRef(({ onSubmit }, ref) => {
    const _initialState = {
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
    }

    const yupSchema = yup.object().shape({
        userName: validations.alphaNumericName,
        email: validations.mail,
        password: validations.password,
        confirmPassword: validations.passwordConfirmation,
    })

    const handleSubmit = useCallback(
        (values) => {
            onSubmit({
                ...values,
            })
        },
        [onSubmit]
    )

    const formik = useFormik({
        initialValues: _initialState,
        validationSchema: yupSchema,
        onSubmit: handleSubmit,
    })

    useImperativeHandle(ref, () => ({
        submitForm: () => formik.submitForm(),
        resetForm: () => formik.resetForm(_initialState),
    }))

    return (
        <form onSubmit={formik.handleSubmit} className="pb-3">
            <div>
                <div className="mb-1">Username</div>
                <FormikAntInput
                    formik={formik}
                    name="userName"
                    placeholder="Username"
                    size="small"
                    transform={{
                        output: (value) => {
                            // valdate if value is empty and return empty string
                            // and apply a regex to only allow letters and numbers and no spaces
                            // and return it as a string

                            let _value = value
                            if (!_value) return ''
                            _value = _value.replace(/[^a-zA-Z0-9]/g, '')

                            return _value
                        },
                    }}
                />
            </div>
            <div>
                <div className="mb-1">Email</div>
                <FormikAntInput
                    formik={formik}
                    name="email"
                    placeholder="Email"
                    size="small"
                    transform={{
                        output: (value) => {
                            // Validate if value is empty and return empty string
                            // if value is not string transform it to string
                            // then apply a regex to only allow email format and no spaces
                            // and return it as a string

                            let _value = value
                            if (!_value) return ''
                            _value = _value.toString()
                            _value = _value.replace(/\s/g, '')
                            _value = _value.replace(/[^a-zA-Z0-9@.]/g, '')

                            return _value.toLowerCase()
                        },
                    }}
                />
            </div>
            <div>
                <div className="mb-1">Password</div>
                <FormikAntInputPassword
                    formik={formik}
                    name="password"
                    placeholder="Password"
                    size="small"
                />
            </div>
            <div>
                <div className="mb-1">Confirm Password</div>
                <FormikAntInputPassword
                    formik={formik}
                    name="confirmPassword"
                    placeholder="Password"
                    size="small"
                />
            </div>
        </form>
    )
})
AddSellerForm.displayName = 'AddSellerForm'

export default AddSellerForm
