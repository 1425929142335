/* eslint-disable no-unused-vars */
import React, { useCallback, useState, useEffect } from 'react'
import { FaUser, FaLock, FaSpinner } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { LOG_IN_SCREEN } from 'src/store/reducers/auth/auth.slice'
import { login } from 'src/store/reducers/auth/auth.actions'
import {
    useScreenAccess,
    useUpdateScreenAccess,
    useResetScreenAccess,
} from '../store/reducers/auth/auth.hooks'
import { useDispatch } from 'react-redux'
import logoIgniite from 'src/assets/images/logo.png'
import { Input, Alert } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'
import NoLoggedLayout from 'src/components/LayoutsComponents/noLoggedLayout'
import useAuth from '../hooks/useAuth'
import { sleep } from 'src/utils/promise'
import Button from '../components/Buttons/Button'

const LogIn = () => {
    const [error, setError] = useState('')
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [{ user, pass }, setAuth] = useState({ user: '', pass: '' })

    const { login } = useAuth()
    const updateScreenAccess = useUpdateScreenAccess()

    const loginSubmit = useCallback(
        async (e) => {
            e.preventDefault()
            setLoading(true)

            try {
                await sleep(500)
                await login({ user, password: pass })
                updateScreenAccess(LOG_IN_SCREEN)
                navigate('2fa/verify')
            } catch (error) {
                console.log('Error: ', error)
                if (error?.error) setError(error.message)
            } finally {
                setLoading(false)
            }
        },
        [user, pass, updateScreenAccess]
    )

    useResetScreenAccess(loading)

    return (
        <NoLoggedLayout>
            <div className="w-full max-w-[450px]">
                <div className="flex justify-center">
                    <img
                        src={logoIgniite}
                        className="w-4/6"
                        alt="LOGO-IGNIITE"
                    />
                </div>

                <div className="flex justify-center mt-20">
                    <form
                        className="flex flex-col w-full"
                        onSubmit={loginSubmit}
                    >
                        <div className="w-full">
                            <Input
                                id={'user'}
                                name={'user'}
                                placeholder={'E-MAIL'}
                                bordered={false}
                                className={`h-[60px] rounded-sm w-full bg-palette-black-725 hover:border-none hover:outline-none hover:ring-1 hover:ring-palette-gray-700 placeholder:text-palette-gray-700`}
                                style={{ fontSize: '12px' }}
                                onChange={(e) => {
                                    e.preventDefault()
                                    setAuth((state) => ({
                                        ...state,
                                        user: e.target.value,
                                    }))
                                }}
                            />
                        </div>
                        <div className="w-full mt-8">
                            <Input.Password
                                id={'password'}
                                name={'password'}
                                placeholder={'PASSWORD'}
                                bordered={false}
                                className={`h-[60px] rounded-sm w-full bg-palette-black-725 hover:border-none hover:outline-none hover:ring-1 hover:ring-palette-gray-700 placeholder:text-palette-gray-700`}
                                style={{ fontSize: '12px' }}
                                onChange={(e) => {
                                    e.preventDefault()
                                    setAuth((state) => ({
                                        ...state,
                                        pass: e.target.value,
                                    }))
                                }}
                                iconRender={(visible) =>
                                    visible ? (
                                        <EyeOutlined
                                            style={{ color: '#fff' }}
                                        />
                                    ) : (
                                        <EyeInvisibleOutlined
                                            style={{ color: '#fff' }}
                                        />
                                    )
                                }
                            />
                        </div>
                        {error && (
                            <Alert
                                className="mt-1"
                                showIcon
                                message={error}
                                closable
                                type="error"
                                onClose={() => setError('')}
                            />
                        )}
                        <Button
                            loading={loading}
                            type="submit"
                            variant="light"
                            className="mt-8 mb-6 h-[68px] w-full rounded-sm"
                        >
                            LOGIN
                        </Button>
                    </form>
                </div>

                <div className="mt-2">
                    <a
                        className="text-palette-gray-700 cursor-pointer "
                        onClick={() => navigate('/forgotpassword')}
                    >
                        Forgot your password?
                    </a>
                </div>
                <div className="text-center mt-16">
                    <span className="font-semibold text-palette-gray-700">
                        Not registered yet?{' '}
                        <a
                            className="text-palette-brown-901 cursor-pointer "
                            onClick={() => navigate('/register')}
                        >
                            Sign up
                        </a>
                    </span>
                </div>
            </div>
        </NoLoggedLayout>
    )
}

export default LogIn
