/* eslint-disable no-unused-vars */
import React from 'react'
import { FaSpinner } from 'react-icons/fa'
import { TbRefresh } from 'react-icons/tb'
import { Navigate, useNavigate } from 'react-router-dom'
import { SIGN_IN_SCREEN } from 'src/store/reducers/auth/auth.slice'
import Button from 'src/components/Buttons/Button'
import {
    useGenerate2faQR,
    useScreenAccess,
    useUpdateSignInSteps,
    useSignInSteps,
} from 'src/store/reducers/auth/auth.hooks'

const QRDisplay = ({ base64, loading, reload }) => {
    let content = (
        <img
            className="w-full h-full"
            src={`data:image/png;base64,${base64}`}
        />
    )

    if (loading)
        content = <FaSpinner className="animate-spin text-2xl text-white" />

    if (base64 === '' && !loading)
        content = (
            <div
                className="absolute inset-0 cursor-pointer flex justify-center items-center"
                onPointerDown={reload}
            >
                <TbRefresh className="text-3xl text-white" />
            </div>
        )
    return (
        <div className="w-[275px] h-[275px] flex justify-center items-center bg-palette-black-725 relative">
            {content}
        </div>
    )
}

const ScanQrCode = (props) => {
    const screen = useScreenAccess()
    const { stake: stakeCompleted } = useSignInSteps()
    let redirect
    if (screen !== SIGN_IN_SCREEN) redirect = 'register'
    if (screen === SIGN_IN_SCREEN && !stakeCompleted)
        redirect = '/register/steps/stakenft'

    if (redirect) return <Navigate to={redirect} replace />

    const { base64, loading, reload } = useGenerate2faQR()
    const navigate = useNavigate()
    const updateSignInSteps = useUpdateSignInSteps()

    return (
        <div className="w-full max-w-[440px] mx-auto xl:ml-[15%] lg:mt-[12%] pb-12 lg:pb-0">
            <div className="flex flex-col">
                <div className="ml-auto font-oxanium text-palette-brown-901 font-semibold text-xs">
                    STEP 3/4
                </div>
                <div className="flex flex-col items-center  mt-6">
                    <div className="text-xl font-oxanium text-palette-brown-901 font-medium tracking-widest">
                        GOOGLE AUTHENTICATION
                    </div>
                    <div className="w-full flex justify-center mt-6">
                        <QRDisplay
                            base64={base64}
                            loading={loading}
                            reload={reload}
                        />
                    </div>
                    <div className="text-palette-gray-700 font-medium text-sm mt-20">
                        For security reasons install Google authentication
                    </div>
                    <div className="mt-12 lg:mt-20 w-full">
                        <Button
                            onClick={() => {
                                updateSignInSteps({ scan: true })
                                navigate('/register/steps/verify')
                            }}
                            className="h-[68px] w-full font-semibold"
                        >
                            VERIFY
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ScanQrCode
