// import useMediaQuery from './useMediaQuery'
import { useMemo } from 'react'
import { useBreakpoint } from './useBreakpoint'

const useResponsiveValue = (
    values = {
        base: undefined,
        sm: undefined,
        md: undefined,
        lg: undefined,
        xl: undefined,
        xl2: undefined,
    }
) => {
    const breakpoint = useBreakpoint({
        xl2: '(min-width: 1536px)',
        xl: '(min-width: 1280px)',
        lg: '(min-width: 1024px)',
        md: '(min-width: 768px)',
        sm: '(min-width: 640px)',
        base: '(min-width: 0px)',
    })

    const responsiveOrder = ['xl2', 'xl', 'lg', 'md', 'sm', 'base']

    // Function that will return the breackpoint value if it exists or the next one in the order defined in responsiveOrder array (e.g. if the breakpoint is 'sm' and the value for 'sm' is undefined, it will return the value for 'base')
    // and if the next one is undefined too, it will return the value for the next one and so on until it finds a value or it reaches the end of the array (in this case it will return undefined)

    const getValue = (breakpoint) => {
        let value = values[breakpoint]
        let index = responsiveOrder.indexOf(breakpoint)
        while (value === undefined && index < responsiveOrder.length) {
            index++
            value = values[responsiveOrder[index]]
        }
        return value
    }

    return useMemo(() => getValue(breakpoint), [breakpoint])
}

export default useResponsiveValue
