/* eslint-disable no-unused-vars */
import React, { useCallback, useMemo, useEffect } from 'react'
import { FaSpinner } from 'react-icons/fa'
import { TbRefresh } from 'react-icons/tb'
import { Navigate, useNavigate } from 'react-router-dom'
import useAuthW3 from 'src/hooks/web3/useAuthW3'
import { useEthers } from '@usedapp/core'
import useNftWalletOfOwner from '../../hooks/web3/nftsHooks/useNftWalletOfOwner'
import { SIGN_IN_SCREEN } from 'src/store/reducers/auth/auth.slice'
import {
    useScreenAccess,
    useUpdateSignInSteps,
} from 'src/store/reducers/auth/auth.hooks'
import useFetchNft from 'src/hooks/web3/nftsHooks/useFetchNft'
import ReactPlayer from 'react-player'
import useStake from '../../hooks/web3/nftsHooks/useStake'
import useStakesNFtId from 'src/hooks/web3/nftsHooks/useStakesNFtId'
import { useUserInfo } from '../../store/reducers/userInfoReducer/userInfo.hooks'
import { Alert } from 'antd'
import NFTDisplay from '../../components/NFTDisplay/index'
import Button from 'src/components/Buttons/Button'

const StakeNft = () => {
    const screen = useScreenAccess()

    if (screen !== SIGN_IN_SCREEN) return <Navigate to={'/register'} replace />

    const navigate = useNavigate()
    const { address } = useUserInfo()
    const { account } = useEthers()
    const { loginMetamask } = useAuthW3()

    const updateSignInSteps = useUpdateSignInSteps()
    const nftsId = useNftWalletOfOwner(account)
    const stakedTokenId = useStakesNFtId(account)

    const { nft, loading: loadingNft } = useFetchNft(
        stakedTokenId > 0 ? stakedTokenId : nftsId
    )

    const { initStake, stateStake, stateApprove, resetState } = useStake(nftsId)

    const handleStake = useCallback(async () => {
        if (!account) {
            loginMetamask()
        }

        if (nftsId) {
            initStake()
        }
    }, [account, nftsId])

    const staking = useMemo(() => {
        return (
            stateStake.status === 'PendingSignature' ||
            stateStake.status === 'Mining' ||
            stateApprove.status === 'PendingSignature' ||
            stateApprove.status === 'Mining'
        )
    }, [stateStake, stateApprove])
    const last4Characters = address.substr(address.length - 8)

    const handleGoNextStep = useCallback(() => {
        updateSignInSteps({ stake: true })
        navigate('/register/steps/scan')
    }, [])

    useEffect(() => {
        if (stateStake.status === 'Success') {
            handleGoNextStep()
        }
    }, [stateStake])

    return (
        <div className="w-full max-w-[440px] mx-auto xl:ml-[15%] lg:mt-[12%] pb-12 lg:pb-0">
            <div className="flex flex-col">
                <div className="flex flex-row items-center">
                    <div className="text-xl font-oxanium text-palette-brown-901 font-medium tracking-widest">
                        STAKE A NFT
                    </div>
                    <div className="ml-auto font-oxanium text-palette-brown-901 font-semibold text-xs">
                        STEP 2/4
                    </div>
                </div>
                <div className="flex flex-col items-center mt-20">
                    {' '}
                    {account && address !== account && (
                        <Alert
                            className=""
                            showIcon
                            message={
                                'Trying to stake with an incorrect account, swith to your account that ends with ....' +
                                last4Characters
                            }
                            closable
                            type="error"
                            // onClose={() => clearError()}
                        />
                    )}
                    <div className="bg-palette-black-725 p-8 mt-4">
                        <div className="w-[231px] flex bg-palette-black-800 relative before:content-[''] before:block before:pt-[100%] text-white">
                            <div className="absolute inset-0 flex justify-center items-center">
                                {(nftsId > 0 || stakedTokenId > 0) && (
                                    <NFTDisplay
                                        nft={nft?.image}
                                        loading={loadingNft}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="text-palette-gray-100 font-oxanium font-bold text-lg text-center my-5">
                            IGNIITE NFT
                        </div>
                        <div className="w-full">
                            <button
                                disabled={
                                    staking ||
                                    stakedTokenId > 0 ||
                                    address !== account
                                }
                                onClick={handleStake}
                                className="h-[68px] w-full rounded-sm text-palette-brown-901 bg-palette-black-725 text-sm font-semibold border border-palette-brown-901 flex justify-center items-center disabled:cursor-not-allowed"
                            >
                                {stakedTokenId > 0 ? (
                                    'STAKED'
                                ) : staking ? (
                                    <FaSpinner className="animate-spin inline-block relative" />
                                ) : (
                                    'STAKE'
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="mt-12 lg:mt-20 w-full">
                        <Button
                            disabled={stakedTokenId === 0 || !stakedTokenId}
                            onClick={handleGoNextStep}
                            className="h-[68px] w-full font-semibold"
                        >
                            Continue
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StakeNft
