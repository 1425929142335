import React, { useCallback, useRef } from 'react'
import CustomModal from 'src/components/Modals/GenericModals/CustomModal'
import AddCustomerForm from './AddCustomerForm'
import { useRegisterClient } from 'src/store/reducers/staffReducer/staff.hooks'

export const AddCustomerModal = ({ visible = false, onClose, selectedSeller }) => {
    const formRef = useRef(null)
    const { signUp, loading } = useRegisterClient()

    const handleClose = () => {
        formRef.current.resetForm()
        onClose()
    }

    const handleOkay = useCallback(() => {
        formRef.current.submitForm()
    }, [])

    const handleSubmit = useCallback(
        async (values) => {
            await signUp({ ...values, seller: selectedSeller.id })             
                handleClose()
        },
        [signUp, selectedSeller]
    )

    return (
        <CustomModal
            loading={loading}
            visible={visible}
            title={'ADD NEW CLIENT'}
            width={450}
            onCancel={handleClose}
            onOk={handleOkay}
        >
            <AddCustomerForm ref={formRef} onSubmit={handleSubmit} />
        </CustomModal>
    )
}
