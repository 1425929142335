import { combineReducers } from 'redux'
import { clear } from 'redux-localstorage-simple'
import authReducer from './auth/auth.slice'
import dashboardReducer from './dashboardReducer/dashboard.slice'
// import userPaymentReducer from './userReducer/paymentStatusReducer'
import adminReducer from './adminReducer/admin.slice'
import userInfoReducer from './userInfoReducer/userInfo.slice'
import statsReducer from './statsReducer/stats.slice'
import globalTypes from './globalTypes'
import paymentsReducer from './paymentsReducer/payments.slice'
import mintRequirementsBackup from './mintRequirementsReducer/mintRequirementsBackup'
import mintRequirementsReducer from './mintRequirementsReducer/mintRequirementsReducer'
import exchangeReducer from './exchangeReducer/exchange.slice'
import positionReducer from './positionResolver/position.slice'
import referralUserTrades from './referralUserTradesReducer/referralUserTrades.slice'
import historyTrades from './historyTradesReducer/historyTrades.slice'
import staff from './staffReducer/staff.slice'
import customer from './customerReducer/customer.slice'

export const PERSISTED_KEYS = [
    'authReducer',
    'dashboardReducer',
    'userInfoReducer',
    'mintRequirementsBackup',
    'paymentsReducer',
]

const appReducer = combineReducers({
    authReducer,
    dashboardReducer,
    // userPaymentReducer,
    adminReducer,
    referralUserTrades,
    userInfoReducer,
    statsReducer,
    paymentsReducer,
    mintRequirementsBackup,
    mintRequirementsReducer,
    exchangeReducer,
    positionReducer,
    historyTrades,
    staff,
    customer
    // referralReducer,
})

const rootReducer = (state, action) => {
    // Here I reset all the data of my reducers when user logout
    if (action.type === globalTypes.LOG_OUT) {
        localStorage.removeItem('token')
        clear({})
        state = {}
    }
    return appReducer(state, action)
}

export default rootReducer
