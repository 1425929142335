/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react'
import ButtonUptime from './ButtonUptime'

import { ONE, TWO, THREE, MONTH } from 'src/constants/Uptime.constants'
import locale from 'antd/es/date-picker/locale/en_US'
import dayjs from 'dayjs'

const Uptime = ({ selected, onSelect }) => {
    const [dates, setDates] = useState(null)
    const [hackValue, setHackValue] = useState(null)
    const [value, setValue] = useState(null)

    return (
        <div
            className={
                'w-full mb-3 md:w-auto space-x-2 flex flex-row lg:ml-auto'
            }
        >
            <div className={'space-x-2 flex flex-row w-full lg:w-auto'}>
                <ButtonUptime
                    onPointerDown={() => onSelect(MONTH)}
                    selected={MONTH === selected}
                >
                    1M
                </ButtonUptime>
                <ButtonUptime
                    onPointerDown={() => onSelect(ONE)}
                    selected={ONE === selected}
                >
                    1Y
                </ButtonUptime>

                <ButtonUptime
                    onPointerDown={() => onSelect(TWO)}
                    selected={TWO === selected}
                >
                    2Y
                </ButtonUptime>
                <ButtonUptime
                    onPointerDown={() => onSelect(THREE)}
                    selected={THREE === selected}
                >
                    3Y
                </ButtonUptime>
            </div>
        </div>
    )
}

export default Uptime
