import React, { useState, useCallback, useEffect, useMemo } from 'react'

import ReactPlayer from 'react-player'

import videoBg from 'src/assets/videos/mint-background.mp4'
import { Mainnet, useEthers } from '@usedapp/core'
import useFetchNft from '../hooks/web3/nftsHooks/useFetchNft'
import useMintNftUSDT from 'src/hooks/web3/nftsHooks/useMintNftWithUSDT'
import { FaSpinner } from 'react-icons/fa'
import ConfirmMintModal from 'src/components/MintComponents/ConfirmModal'
import MintModal from '../components/MintComponents/MintModal/index'
import MintRequirementsModal from 'src/components/MintComponents/MintRequirementsModal'
import useAccountAlreadyMinted from '../hooks/web3/nftsHooks/useAccountAlreadyMinted'

const Mint = () => {
    const [{ visibleConfirm, visibleRequirements }, setVisible] = useState({
        visibleConfirm: false,
        visibleRequirements: false,
    })
    const [nftId, setNftId] = useState(null)
    const { chainId, switchNetwork } = useEthers()
    const { initMint, stateMint, stateApprove, resetState } = useMintNftUSDT()
    const minted = useAccountAlreadyMinted()

    const { loading: loadingNft, nft } = useFetchNft(nftId)

    const loading = useMemo(() => {
        return (
            stateMint.status === 'PendingSignature' ||
            stateMint.status === 'Mining' ||
            loadingNft
        )
    }, [stateMint, loadingNft])

    const approving = useMemo(() => {
        return (
            stateApprove.status === 'PendingSignature' ||
            stateApprove.status === 'Mining'
        )
    }, [stateApprove])

    const visibleModal = useMemo(() => {
        const { status } = stateMint

        return (
            status === 'PendingSignature' ||
            status === 'Mining' ||
            status === 'Success'
        )
    }, [stateMint])

    const handleModalVisible = useCallback(
        async (e) => {
            if (e.target.disabled) {
                await switchNetwork(Mainnet.chainId)
                return
            }
            setVisible({ visibleRequirements: true })
        },
        [chainId]
    )

    const handleConfirm = useCallback(() => {
        setVisible({ visibleConfirm: false })
        initMint()
    }, [initMint])

    useEffect(() => {
        if (stateMint.status === 'Success') {
            const {
                receipt: { logs },
            } = stateMint

            setNftId(parseInt(Number(logs[1].topics[3])))
        }
    }, [stateMint])

    return (
        <div className="h-screen w-full flex flex-col justify-center items-center bg-palette-black-700 px-2 relative overflow-hidden">
            <div className="absolute inset-0">
                <ReactPlayer
                    width="100%"
                    height="100%"
                    controls={false}
                    playing={true}
                    url={videoBg}
                    playsinline
                    muted
                    loop
                    className="custom-react-player"
                    onError={(e) => console.log('error', e)}
                />
            </div>
            <MintRequirementsModal
                visible={visibleRequirements}
                onFinish={() =>
                    setVisible({
                        visibleConfirm: true,
                        visibleRequirements: false,
                    })
                }
                onClose={() => setVisible({ visibleRequirements: false })}
            />
            <ConfirmMintModal
                visible={visibleConfirm}
                onClose={() => setVisible({ visibleConfirm: false })}
                onConfirm={handleConfirm}
            />
            <MintModal
                loading={loading}
                visible={visibleModal}
                onCancel={() => {
                    resetState()
                    setNftId(0)
                }}
                nft={nft ? { nftId, ...nft } : undefined}
            />

            <div className="relative text-5xl font-bold tracking-wider">
                A SINGLE SPARK CAN START A MOVEMENT
            </div>
            <button
                onClick={handleModalVisible}
                disabled={minted || approving || visibleModal}
                className="mt-24 relative w-full max-w-[384px] h-[68px] rounded-sm text-white bg-gradient-to-r from-[#A6772B] to-[#E5CD66] disabled:from-[#A6772B]/70 disabled:to-[#E5CD66]/70 disabled:cursor-not-allowed text-sm font-semibold flex justify-center items-center "
            >
                {approving ? (
                    <FaSpinner className="animate-spin inline-block relative" />
                ) : minted ? (
                    'MINTED'
                ) : (
                    'MINT 2000 USDT'
                )}
            </button>
        </div>
    )
}

export default Mint
