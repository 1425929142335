import React from 'react'
import ConfirmModal from '../Modals/GenericModals/ConfirmModal'
import { formatNumberToCurrency } from 'src/utils/formatNumber'
import dayjs from "dayjs";

const PayPeriodicity = ({ visible, onClose, onConfirm, loading = false, data }) => {
    const usersArray = Object.values(data.users);

    const onConfirmIfPeriodicytDontFinished = () => {
        alert("I don't know the period is over yet.")
    }
    const onConfirmPeriodicity = data.periodicity_start === '' ? onConfirmIfPeriodicytDontFinished : onConfirm;
    
    return (
        <ConfirmModal
            visible={visible}
            onClose={onClose}
            onConfirm={onConfirmPeriodicity}
            loading={loading}
        >
            <div className="flex flex-col gap-2 text-center">
                <div className="mx-auto text-lg text-palette-gray-700 font-semibold"  style={{ textAlign: 'left' }}>
                    Epoch: {data.id}
                    <br/>
                    Periodicity: {data.periodicity}
                    <br/>
                    Epoch Date: {dayjs(data.epoch_start).format('MMM DD,YYYY')} / {dayjs(data.epoch_end).format('MMM DD,YYYY')}
                    <br />
                    {data.periodicity_start && (
                        <div>Periodicity: {dayjs(data.periodicity_start).format('MMM DD,YYYY')} / {dayjs(data.periodicity_end).format('MMM DD,YYYY')}</div>
                    )}
                    <br/>
                    <br/>
                    <div>
                        {!data.periodicity_start ? (
                            <div>I don&apos;t know the period is over yet.</div>
                        ) : (
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th>User</th>
                                        <th>Balance</th>
                                        <th>Revenue</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {usersArray.map((user) => (
                                        <tr key={user.user_name}>
                                            <td className='text-sm pr-5'>{user.user_name}</td>
                                            <td className='text-sm pr-7'>{formatNumberToCurrency(user.balance)}</td>
                                            <td className='text-sm'>{formatNumberToCurrency(user.revenue)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>
            </div>
        </ConfirmModal>
    )
}

export default PayPeriodicity
