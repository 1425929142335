import { Checkbox, Input } from 'antd'
import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { formatNumberToCurrency } from 'src/utils/formatNumber'
import {
    useSetTP,
    useSetSL,
} from '../../store/reducers/positionResolver/position.hooks'
import CustomModal from './../Modals/GenericModals/CustomModal'
import { usePositionResolverState } from 'src/store/reducers/positionResolver/position.hooks'
import {
    formatValueToNumberOnChange,
    formatValueToNumber,
} from 'src/utils/inputsUtilities'

const initialValues = { sl: undefined, tp: undefined }

export const SetSLTPModal = ({
    isManual,
    tradeData,
    visible,
    emisorSelected,
    onCancel,
    ...props
}) => {
    const { activeOrders } = usePositionResolverState()
    const [{ sl, tp }, setValues] = useState(initialValues)
    const [manual, setManual] = useState(false)

    const setTp = useSetTP()
    const setSL = useSetSL()
    const handleSetSl = (name, value) =>
        setValues((state) => ({ ...state, [name]: value }))
    const { entryprice, liqPrice, manualTP, symbol } = tradeData ?? {}

    const tradeManualTP = useMemo(() => {
        return activeOrders?.[emisorSelected]?.find(
            (trade) => trade.symbol === symbol && trade.closeOrder
        )?.orderPrice
    }, [activeOrders, symbol, emisorSelected])

    const updateState = useCallback(() => {
        if (!visible) {
            setValues(initialValues)
            setManual(false)
        } else {
            setValues((state) => ({
                ...state,
                sl: tradeData.sl !== '0' ? tradeData.sl : undefined,
                tp: tradeManualTP ?? undefined,
            }))
            if (isManual) setManual(manualTP)
        }
    }, [tradeData, tradeManualTP, visible, manualTP, isManual])

    const setSLTP = useCallback(async () => {
        const { side, symbol, quantity } = tradeData
        const config = {
            side,
            symbol,
            keyId: emisorSelected,
        }
        if (isManual) {
            if (Number(tradeManualTP) !== Number(tp) && tp)
                setTp({
                    ...config,
                    qty: Number(quantity),
                    entryPrice: Number(tp),
                })
        }
        if (
            Number(sl) !== Number(tradeData.sl) &&
            (sl || (!sl && Number(tradeData.sl) > 0))
        )
            setSL({
                ...config,
                stopLoss: sl ? Number(sl) : 0,
            })
        setValues(initialValues)
        onCancel()
    }, [
        isManual,
        manual,
        sl,
        tp,
        tradeManualTP,
        tradeData,
        emisorSelected,
        setSL,
    ])

    useEffect(() => {
        updateState()
    }, [visible])

    const resetTP = useCallback(() => {
        if (!manual && tp)
            setValues((state) => ({
                ...state,
                tp: undefined,
            }))
    }, [manual, tp])

    useEffect(() => {
        resetTP()
    }, [manual])
    return (
        <CustomModal
            visible={visible}
            width={400}
            title="TP/SL"
            onOk={setSLTP}
            onCancel={onCancel}
            {...props}
        >
            <form className="space-y-4">
                <div>
                    <div className="flex flex-row">
                        <div>Entry Price</div>
                        <div className="ml-auto font-medium">
                            {formatNumberToCurrency(entryprice)}
                        </div>
                    </div>
                    <div className="flex flex-row">
                        <div>Liquidation Price</div>
                        <div className="ml-auto font-medium text-experts-red-3">
                            {formatNumberToCurrency(liqPrice)}
                        </div>
                    </div>
                </div>

                {isManual && (
                    <div className="flex flex-row">
                        <div className="">Enable Manual Take Profit</div>
                        <Checkbox
                            className="ml-auto relative -mt-1"
                            checked={manual}
                            onChange={(e) => {
                                if (!manualTP) setManual(e.target.checked)
                            }}
                        />
                    </div>
                )}

                <div className="">
                    <div className="">Take Profit</div>
                    <Input
                        name="tp"
                        id="tp"
                        value={tp}
                        disabled={!manual && isManual}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(value, {
                                decimals: 6,
                                min: 0,
                                enableEmptyValue: true,
                            })
                            handleSetSl(name, $value.toString())
                            // const _value = numberValidation(value, 6, 0)
                            // if (_value) handleSetSl(name, _value)
                        }}
                        onBlur={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumber(
                                value,
                                tradeManualTP
                            )
                            handleSetSl(
                                name,
                                $value === 0 ? tradeManualTP : $value
                            )
                        }}
                    />
                </div>

                <div className="">
                    <div className="">Stop Loss</div>
                    <Input
                        name="sl"
                        id="sl"
                        value={sl}
                        onChange={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumberOnChange(value, {
                                decimals: 6,
                                min: 0,
                                enableEmptyValue: true,
                            })
                            handleSetSl(name, $value)
                        }}
                        onBlur={(e) => {
                            const {
                                target: { value, name },
                            } = e
                            const $value = formatValueToNumber(value)

                            if ($value === 0) handleSetSl(name, undefined)
                        }}
                    />
                </div>
            </form>
        </CustomModal>
    )
}

export default SetSLTPModal
