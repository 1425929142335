import React from 'react'
import * as echarts from 'echarts/core'
import ReactEcharts from 'echarts-for-react'
import {
    TooltipComponent,
    TitleComponent,
} from 'echarts/components'
import {
    CanvasRenderer,
} from 'echarts/renderers'
import { categories, links, titles } from '../../constants/graphConstants'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

// Register the required components
echarts.use([
    TitleComponent,
    TooltipComponent,
    CanvasRenderer,
])

export const WinsxLossesDetails = ({ graphicData }) => {

    const offset = 0
    const offset2= 0
    const offset3 = 0

    const data = [
        {
            name: `${graphicData.tradesNum}`,
            id: 'Number of trades',
            x: 300,
            y: 300+offset3 ,
            category: 0,
        },
        {
            id: 'WINS',
            name: `${graphicData.wins}`,
            x: 500,
            y: 200+offset2,
            category: 1,
        },
        {
            id: 'Biggest Win',
            name: `${formatNumberToCurrency(graphicData.biggestWin)}`,
            x: 700,
            y: 200+offset2 ,
            category: 3,
        },
        {
            id: 'totalWins',
            name: `${formatNumberToCurrency(graphicData.totalWins)}`,
            x: 900,
            y: 200+offset2 ,
            category: 3,
        },

        {
            id: 'LOSSES',
            name: `${graphicData.losses}`,
            x: 500,
            y: 400 + offset,
            category: 2,
        },
        {
            id: 'Biggest Loss',
            name: `${formatNumberToCurrency(graphicData.biggestLoss)}`,
            x: 700,
            y: 400 + offset,
            category: 4,
        },
        {
            id: 'Total Losses USDT',
            name: `${formatNumberToCurrency(graphicData.totalLosses)}`,
            x: 900,
            y: 400 + offset,
            category: 4,
        },
    ] 
    
    const option = {
        title: titles,
        tooltip: {},
       
        series: [
            // values
            {
                type: 'graph',
                layout: 'none',
                // bottom: '5%',
                textStyle: {
                    fontFamily: "'Maven Pro', 'sans-serif'",
                },

                tooltip: {
                    show: false,
                },
                label: {
                    show: true,
                    fontWeight: 600,
                    fontFamily: "'Maven Pro', 'sans-serif'",
                    fontSize: 12,
                },

                data: data,
                links: links,
                categories: categories,
            },
        ],
    }

    return (
        <div className="col-span-12 md:col-span-6 p-5 rounded border-palette-black-725 border-2 bg-palette-black-600">
            <div className="text-[16px] font-normal text-palette-gray-100 font-maven mb-5">
                WINS & LOSSES DETAILS
            </div>
            <ReactEcharts option={option} />
        </div>
    )
}

