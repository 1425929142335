/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit'
import { useEffect, useState } from 'react'
import useRefresh from '../../../hooks/useRefresh'
import { getWalletBalance } from './exchange.actions'

const initialState = {
    leverage: 0,
    availableBalance: 0,
    walletBalance: 0,
    orderMargin: 0,
    usedMargin: 0,
    equity: 0,
}

const exchangeSlice = createSlice({
    name: 'mintRequirements',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getWalletBalance.fulfilled, (state, { payload }) => {
                const {
                    available_balance: availableBalance,
                    wallet_balance: walletBalance,
                    used_margin: usedMargin,
                    order_margin: orderMargin,
                    equity,
                } = payload[0]

                return {
                    ...state,
                    availableBalance,
                    walletBalance,
                    usedMargin,
                    orderMargin,
                    equity,
                }
            })
            .addCase(getWalletBalance.pending, (state) => {
                return initialState
            })
    },
})

export default exchangeSlice.reducer
