/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react'
// import 'antd/dist/antd.css'
import axiosInstance from 'src/store/services/axiosConfig'
import { useFormik } from 'formik'
import FormikAntInputAdmin from 'src/components/FormikAntDInputs/FormikAntInputAdmin'
import Button from 'src/components/Buttons/Button'
// import { removeEmptySpaces } from 'src/utils/stringUtils'
import classNames from 'classnames'
import { Select, notification } from 'antd'
import allSymbols from 'src/JSONS/symbols.json'
import * as yup from 'yup'
import { validations } from 'src/service/yupValidations'
import TradesTablePullAdmin from 'src/components/tables/tradesTablePullAdmin'
import { useDispatch } from 'react-redux'
import { refresh } from 'src/store/reducers/historyTradesReducer/historyTrades.actions'
import {
    useGetAllEpochs,
    useGetAllTrades,
    useHistoryTradesState,
} from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import FormikAntSelectAdmin from 'src/components/FormikAntDInputs/FormikAntSelectAdmin'
import { CsvTrades } from './CsvTrades'
const { Option } = Select

const headersActive = ['Date', 'Pair', 'Side', 'QTY', 'Epoch', 'Actions']

const TradesAdmin = ({ symbols = [] }) => {
    const dispatch = useDispatch()
    useGetAllEpochs()
    useGetAllTrades()
    const { epochs } = useHistoryTradesState()

    const postData = async (
        { pnlUsd, crypto, type, qty, epoch },
        { setSubmitting }
    ) => {
        try {
            const data = []
            for (let i = 0; i < epoch.length; i++) {
                const d = {
                    pnlUsd,
                    pair: crypto,
                    type,
                    qty,
                    epoch: epoch[i],
                }

                data.push(d)
            }

            await axiosInstance.post(`epochs/createpooltradeshistory/`, data)
            dispatch(refresh())
            notification.success({
                message: 'Create New Trade',
                description: 'You successfully created a new Trade',
                placement: 'bottomRight',
            })
        } catch (error) {
            console.log('Error: ', error)
        } finally {
            setSubmitting(false)
            formik.resetForm()
        }

        setSubmitting(false)
    }
    const schema = yup.object().shape({
        pnlUsd: validations.number,
        crypto: validations.generic,
        type: validations.generic,
        qty: validations.number,
        epoch: validations.array,
    })

    const formik = useFormik({
        initialValues: {
            pnlUsd: '',
            pair: '',
            type: undefined,
            qty: '',
            epoch: [],
        },
        validationSchema: schema,
        onSubmit: postData,
    })
    const availableSymbols = useMemo(() => {
        return allSymbols.reduce((acc, symbol) => {
            if (symbols.findIndex((s) => s.symbol === symbol.symbol) !== -1)
                return acc
            return [...acc, symbol.symbol]
        }, [])
    }, [symbols])
    
    return (
        <div className="pl-6 h-full overflow-y-auto overflow-x-hidden scroll-generic pr-6 pb-6">
            <div>
                <div className="bg-palette-black-600 mt-3 rounded-md shadow-md ">
                    <form onSubmit={formik.handleSubmit}>
                        <div className="px-4 pt-5 ">
                            <div className="text-base mb-3">Insert Trade</div>
                            <div className="flex mb-7 flex-col lg:flex-row">
                                <div className="flex-1 mr-2 md:mr-8">
                                    <FormikAntSelectAdmin
                                        formik={formik}
                                        name="epoch"
                                        mode="tags"
                                        value={
                                            formik.values?.epoch !== []
                                                ? formik.values?.epoch
                                                : null
                                        }
                                        placeholder="SELECT EPOCH"
                                        style={{
                                            width: '100%',
                                            // height: '40px',
                                        }}
                                        className={classNames(
                                            'text-center rounded-sm',
                                            {
                                                'ring-1 ring-red-600':
                                                    (formik.touched.epoch &&
                                                        formik.errors.epoch) ||
                                                    (formik.errors.epoch &&
                                                        formik.submitCount > 0),
                                            }
                                        )}
                                        size="large"
                                        onChange={(val) => {
                                            formik.setValues({
                                                ...formik.values,
                                                epoch: val,
                                            })
                                        }}
                                        options={epochs.map((epoch) => {
                                            return {
                                                value: epoch.id.toString(),
                                                label: `EPOCH ${epoch.id}`,
                                            }
                                        })}
                                    />
                                </div>
                                <div className="flex-1 mr-2 md:mr-8">
                                    <FormikAntInputAdmin
                                        className="h-10 mr-2 md:mr-8"
                                        name="pnlUsd"
                                        placeholder={'PNL USD'}
                                        formik={formik}
                                        value={formik.values?.pnlUsd}
                                    />
                                </div>
                                <div className="flex-1 mr-2 md:mr-8">
                                    <FormikAntSelectAdmin
                                        formik={formik}
                                        name="crypto"
                                        showSearch
                                        className={classNames(
                                            'text-center rounded-sm',
                                            {
                                                'ring-1 ring-red-600':
                                                    (formik.touched.crypto &&
                                                        formik.errors.crypto) ||
                                                    (formik.errors.crypto &&
                                                        formik.submitCount > 0),
                                            }
                                        )}
                                        onSelect={formik.handleChange}
                                        onChange={(value) => {
                                            formik.setFieldValue(
                                                'crypto',
                                                value
                                            )
                                        }}
                                        value={formik.values?.crypto}
                                        placeholder="SELECT PAIR"
                                        optionFilterProp="children"
                                        filterOption={(input, option) =>
                                            (option?.label ?? '')
                                                .toLowerCase()
                                                .includes(input.toLowerCase())
                                        }
                                        filterSort={(optionA, optionB) =>
                                            (optionA?.label ?? '')
                                                .toLowerCase()
                                                .localeCompare(
                                                    (
                                                        optionB?.label ?? ''
                                                    ).toLowerCase()
                                                )
                                        }
                                        options={availableSymbols.map(
                                            (symbol) => {
                                                return {
                                                    value: symbol,
                                                    label: symbol,
                                                }
                                            }
                                        )}
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                            textAlign: 'center',
                                        }}
                                        size="large"
                                    />
                                </div>
                                <div className="flex-1 mr-2 md:mr-8">
                                    <FormikAntInputAdmin
                                        placeholder={'QUANTITY'}
                                        className="h-10"
                                        name="qty"
                                        formik={formik}
                                        value={formik.values?.qty}
                                    />
                                </div>
                                <div className="flex-1 mr-2 md:mr-8">
                                    <FormikAntSelectAdmin
                                        value={
                                            formik.values?.type !== ''
                                                ? formik.values?.type
                                                : undefined
                                        }
                                        name="type"
                                        formik={formik}
                                        placeholder="SELECT SIDE"
                                        style={{
                                            width: '100%',
                                            height: '40px',
                                        }}
                                        className={classNames(
                                            'text-center rounded-sm',
                                            {
                                                'ring-1 ring-red-600':
                                                    (formik.touched.type &&
                                                        formik.errors.type) ||
                                                    (formik.errors.type &&
                                                        formik.submitCount > 0),
                                            }
                                        )}
                                        size="large"
                                        onChange={(val) => {
                                            formik.setValues({
                                                ...formik.values,
                                                type: val,
                                            })
                                        }}
                                        options={[
                                            {
                                                value: 'Buy',
                                                label: 'BUY',
                                            },
                                            {
                                                value: 'Sell',
                                                label: 'SELL',
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="w-full mb-7 lg:w-1/4 flex-row gap-3 lg:mt-0 lg:flex">
                                    <Button
                                        loading={formik.isSubmitting}
                                        disabled={formik.isSubmitting}
                                        type="submit"
                                        variant="light"
                                        className={`w-full h-10`}
                                    >
                                        SUBMIT
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            
            <CsvTrades/>
            
            <TradesTablePullAdmin
                headers={headersActive}
                emptyMessage="NO TRADES REGISTERED YET"
            />
        </div>
    )
}

export default TradesAdmin
