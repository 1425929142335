import React, {useEffect} from 'react'
import { CustomerEpochsGrid } from 'src/components/CustomerComponents/CustomerEpochsGrid'
import { CustomerProfits } from 'src/components/CustomerComponents/CustomerProfits'
import { useFetchCustomerEpochsBalances } from 'src/store/reducers/customerReducer/customer.hooks'
import {
    useGetClientEpochDetails,
    useHistoryTradesState,
    useGetUserPayments,
} from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import { formatNumberToCurrency } from 'src/utils/formatNumber'
import CustomTable from '../../components/AdminComponents/CustomTable'
import {formatDate} from "../../utils/formatDate";
import {useNavigate} from "react-router-dom";
import {selectEpoch} from "../../store/reducers/historyTradesReducer/historyTrades.actions";
import {useDispatch} from "react-redux";

const columns = [
    {
        title: 'Epoch',
        dataIndex: 'epoch',
        key: 'epoch',
    },
    {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: 'Fee',
        dataIndex: 'fee',
        key: 'fee',
    },
    {
        title: 'Amount paid',
        dataIndex: 'amount',
        key: 'amount',
    },
    {
        title: 'Amount before fee',
        dataIndex: 'amountbeforefee',
        key: 'amountbeforefee',
    },
]

const CustomerView = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    useGetClientEpochDetails()
    useFetchCustomerEpochsBalances()
    const { clientEpochDetails } = useHistoryTradesState()
    const paymentsUser = useGetUserPayments()
    
    const paymentsUserWithDetails = paymentsUser.map(obj => {
      return { ...obj, date: formatDate(obj.date), amount: formatNumberToCurrency(obj.amount), fee: formatNumberToCurrency(obj.amount/70*30), amountbeforefee: formatNumberToCurrency(obj.amount/70*100) }; // Agregar el campo "fee" con el valor que desees
    });
    
    
    useEffect(() => {
        if (clientEpochDetails.length === 1)
            dispatch(selectEpoch(clientEpochDetails.id))
            navigate('/epochdetails', { replace: true })
    }, [clientEpochDetails])
    
    // Pnl from non closed epochs
    const estimatedRoi = clientEpochDetails
        .map((e) => {
            if (e.status) {
                return Number(e.pnl)
            }
            return 0
        })
        .reduce((acc, curr) => acc + curr, 0)

    return (
        <div className="flex flex-col mb-10">
            <div className="flex flex-row mb-6">
                <div className="text-palette-brown-901 font-oxanium text-2xl font-bold pr-10 border-r border-gray-50">
                    My Epochs
                </div>
                <div className="flex flex-col pl-10">
                    <div className="font-medium text-base text-palette-gray-900 pb-3">
                        Estimated ROI
                    </div>
                    <div className="text-white font-oxanium text-2xl">
                        {formatNumberToCurrency(estimatedRoi)}
                    </div>
                </div>
            </div>
            <div className="grid grid-cols-12 gap-4 auto-rows-min">
                <div className="col-start-1 col-end-13 md:col-start-1 md:col-end-5">
                    <CustomerProfits />
                </div>
                <div className="col-start-1 col-end-13 md:col-start-5 md:col-end-13">
                    <CustomTable
                        columns={columns}
                        dataSource={paymentsUserWithDetails}
                    />
                </div>
            </div>
            <CustomerEpochsGrid clientEpochDetails={clientEpochDetails} />
        </div>
    )
}
export default CustomerView
