import { createSlice } from '@reduxjs/toolkit'
import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useMintRequirementBackUp } from './mintRequirementsBackup'

const initialState = {
    account: '',
    createdBitso: false,
    bitsoAccountFunds: false,
    usdtTransfered: false,
    ethTransfered: false,
}

const mintRequirementsSlice = createSlice({
    name: 'mintRequirements',
    initialState,
    reducers: {
        updateMintRequirements(state, { payload }) {
            return { ...state, ...payload }
        },
        resetMintRequirements(state) {
            return initialState
        },
        loadBackUp(state, { payload }) {
            return payload
        },
    },
})

export const { updateMintRequirements, loadBackUp, resetMintRequirements } =
    mintRequirementsSlice.actions

const selectSelf = (state) => state.mintRequirementsReducer

export const useMintRequirement = () => {
    return useSelector(selectSelf)
}

export const useUpdateMintRequirements = () => {
    const dispatch = useDispatch()
    return useCallback(
        (data) => dispatch(updateMintRequirements(data)),
        [dispatch]
    )
}

export const useResetMintRequirements = (address) => {
    const dispatch = useDispatch()
    const mintR = useMintRequirement()

    const _resetMintRequirements = useCallback(
        () => dispatch(resetMintRequirements()),
        [dispatch]
    )

    const handleReset = useCallback(() => {
        if (mintR.account && mintR.account !== address) {
            _resetMintRequirements()
        }
    }, [mintR, address])

    useEffect(() => {
        handleReset()
    }, [address])
}

export const useLoadBackup = (address) => {
    const dispatch = useDispatch()
    const mintRBackUp = useMintRequirementBackUp()

    const _loadBackup = useCallback(
        (data) => dispatch(loadBackUp(data)),
        [dispatch]
    )

    const handleLoadBackUp = useCallback(() => {
        const data = mintRBackUp.find((v) => v.account === address)
        console.log({ data, address, mintRBackUp })
        if (data) {
            _loadBackup(data)
        }
    }, [mintRBackUp, address])

    useEffect(() => {
        if (address) handleLoadBackUp()
    }, [address])
}

export const useRequirementsUpdateAccount = (address) => {
    const mintRB = useMintRequirementBackUp()
    const { account } = useMintRequirement()

    const updateMintRequirements = useUpdateMintRequirements()

    const handleUpdate = useCallback(() => {
        if (address && !account && !mintRB.find((v) => v.account === address))
            updateMintRequirements({ account: address })
    }, [account, address])

    useEffect(() => {
        handleUpdate()
    }, [account, address])
}

export default mintRequirementsSlice.reducer
