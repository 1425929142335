import './styles/App.less'
import { useKeys } from './hooks/useKeys'
import React from 'react'
// import { useCheckIfStaked } from './store/reducers/auth/auth.hooks'

import { useFetchUserInfo } from 'src/store/reducers/userInfoReducer/userInfo.hooks'
// import ActivePaymentModal from './components/Modals/PaymentModal/ActivePaymentModal'
import AppRoutes from './routes/app.routes'

function App() {
    useKeys()
    // useCheckIfStaked()
    useFetchUserInfo()

    return (
        <React.Fragment>
            {/* <ActivePaymentModal /> */}
            <AppRoutes />
        </React.Fragment>
    )
}

export default App
