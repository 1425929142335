import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import LogedLayout from 'src/components/LayoutsComponents/logedLayout'
import StepsLayout from 'src/components/LayoutsComponents/stepsLayout'
import { useIsStaked, useIsAuth } from 'src/store/reducers/auth/auth.hooks'
import { useUserInfo } from 'src/store/reducers/userInfoReducer/userInfo.hooks'
import AdminTrader from 'src/views/AdminView/AdminTrader'
import ReferralsTrades from 'src/views/AdminView/ReferralsTrades'
import DashboardAdmin from 'src/views/DashboardView'
import ForgetPassword from 'src/views/ForgetPasswordView'
import HelpView from 'src/views/HelpView'
import KeysView from 'src/views/KeysView'
import LearnView from 'src/views/LearnView'
import LiveTrading from 'src/views/LiveTradingView'
import Mint from 'src/views/MintView'
import SendEmailForgotPassword from 'src/views/SendEmailForgotPasswordView'
import ScanQrCode from 'src/views/StepsView/ScanQrCode'
import StakeNft from 'src/views/StepsView/StakeNft'
import VerifyCode from 'src/views/StepsView/VerifyCode'
import TermsView from 'src/views/TermsView'
import LandingView from './../views/LandingView'
import LogIn from '../views/LoginView'
import SignUp from '../views/SignupView'
import AdminView from '../views/AdminView/AdminView'
import SellerView from '../views/SellerView'
import PositionResolverView from '../views/AdminView/PositionResolverView'
import ProfileView from '../views/ProfileView'
// import Staking from '../views/StakingView'
import LogoutView from '../views/LogoutView'
import CustomerView from 'src/views/CustomerView'
import { EpochDetails } from 'src/views/CustomerView/EpochDetails'

export default function AppRoutes() {
    const isAuth = useIsAuth()
    const isStaked = useIsStaked()
    const { active, isSuperuser, isStaff } = useUserInfo()

    return (
        <Routes>
            {!isAuth ? (
                <React.Fragment>
                    <Route path="/" element={<LandingView />} />
                    <Route path="learn" element={<LearnView />} />
                    <Route path="help" element={<HelpView />} />
                    <Route path="mint" element={<Mint />} />
                    <Route path="termsconditions" element={<TermsView />} />

                    <Route path="forgotpassword">
                        <Route index element={<ForgetPassword />} />
                        <Route
                            path="emailSended"
                            element={<SendEmailForgotPassword />}
                        />
                    </Route>
                    <Route path="login">
                        <Route index element={<LogIn />} />
                        <Route path="2fa" element={<StepsLayout />}>
                            <Route path="verify" element={<VerifyCode />} />
                        </Route>
                    </Route>
                    <Route path="register">
                        <Route index element={<SignUp />} />
                        <Route path="steps" element={<StepsLayout />}>
                            <Route
                                index
                                element={<Navigate to={'scan'} replace />}
                            />
                            <Route path="stakenft" element={<StakeNft />} />
                            <Route path="scan" element={<ScanQrCode />} />
                            <Route path="verify" element={<VerifyCode />} />
                        </Route>
                    </Route>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    {' '}
                    <Route path="/" element={<LogedLayout />}>
                        <Route
                            index
                            element={
                                <Navigate
                                    to={
                                        isStaked && active
                                            ? 'dashboard'
                                            : 'payments'
                                    }
                                    replace
                                />
                            }
                        />
                        {isStaked && active && (
                            <React.Fragment>
                                <Route path="keys" element={<KeysView />} />
                                <Route
                                    path="dashboard"
                                    element={
                                        isSuperuser || isStaff ? (
                                            <DashboardAdmin />
                                        ) : (
                                            <CustomerView />
                                        )
                                    }
                                />
                                <Route
                                    path="epochdetails"
                                    element={<EpochDetails />}
                                />
                                <Route
                                    path="livetrading"
                                    element={<LiveTrading />}
                                />
                            </React.Fragment>
                        )}
                        {isSuperuser && (
                            <Route path="admin">
                                <Route index element={<AdminView />} />
                            </Route>
                        )}
                        {(isSuperuser || isStaff) && (
                            <React.Fragment>
                                <Route path="trader/admin">
                                    <Route index element={<AdminTrader />} />
                                    <Route
                                        path="referral/:idUser"
                                        element={<ReferralsTrades />}
                                    />
                                </Route>
                                <Route
                                    path="positionresolver"
                                    element={<PositionResolverView />}
                                />
                            </React.Fragment>
                        )}
                        {isStaff && (
                            <Route
                                path="staff/sales"
                                element={<SellerView />}
                            />
                        )}

                        <Route path="profile" element={<ProfileView />} />
                        {/* <Route path="payments" element={<Staking />} /> */}
                        <Route path="logout" element={<LogoutView />} />
                    </Route>
                </React.Fragment>
            )}
            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
    )
}
