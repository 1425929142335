import React from 'react'
import { formatNumberToCurrency } from './../../utils/formatNumber'
import { Table } from 'antd'

import days from 'dayjs'
import { sortNumberAnt, sortTextAlpha } from 'src/utils/tableAntsUtils'
import { sortDate } from './../../utils/tableAntsUtils'

const TableCloseTrades = ({ dataSource }) => {
    const columns = [
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',

            render: (value) => {
                // console.log({ values })
                return <span>{days(value).format('YYYY-MM-DD')}</span>
            },
            sorter: sortDate('date'),
        },
        {
            title: 'Symbol',
            dataIndex: 'symbol',
            key: 'symbol',
            sorter: sortTextAlpha('symbol'),
        },
        {
            title: 'Pnl',
            dataIndex: 'pnl',
            key: 'pnl',
            render: (v) => (
                <span
                    className={
                        Number(v) >= 0
                            ? 'text-palette-green-500'
                            : 'text-palette-pink-700'
                    }
                >
                    {formatNumberToCurrency(v)}
                </span>
            ),
            sorter: sortNumberAnt('pnl'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ]

    return (
        <Table
            className="mt-2"
            columns={columns}
            dataSource={dataSource}
            scroll={{ y: '600px', x: '1000px' }}
        />
    )
}

export default TableCloseTrades
