import axios from 'axios'

// Set your api URL here
const axiosInstance = axios.create({
    baseURL:
        process.env.NODE_ENV === 'production'
            ? 'https://igniite.io/api/'
            : 'http://localhost:3000/',
})

// This is in case you need to intercept axios request

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem('token')
    if (token) {
        config.headers.Authorization = `token ${token}`
    }
    return config
})

axiosInstance.interceptors.response.use(
    (response) =>
        new Promise((resolve, reject) => {
            resolve(response)
        }),
    (error) => {
        if (!error.response) {
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }
        // if (error.response.status === 401) {
        if (error.response.status === 401 && localStorage.getItem('verified')) {
            localStorage.removeItem('token')
            localStorage.removeItem('verified')
            window.location = '/logout'
            console.log(error)
        } else {
            return new Promise((resolve, reject) => {
                reject(error)
            })
        }
    }
)
export default axiosInstance
