import { useEffect, useRef } from 'react'
import loadingVideo from 'src/assets/videos/loading-mint-dots.mp4'
import useOnScreen from './../../../hooks/useOnScreen'
import ReactPlayer from 'react-player'

const MintLoadingVideo = ({ loading, onEnded }) => {
    const containerRef = useRef(null)
    const playerRef = useRef(null)
    const isVisible = useOnScreen(containerRef)

    const restartVideo = () => {
        playerRef.current.seekTo(0)
    }

    // const handleLoop = useCallback(() => {
    //     if (loading && playedVideo > 3) {
    //         restartVideo()
    //     }
    // }, [loading, playedVideo])

    // const handleOnProgress = ({ playedSeconds }) => {
    //     setPlayed(playedSeconds)
    // }

    // useEffect(() => {
    //     handleLoop()
    // }, [playedVideo])

    useEffect(() => {
        if (!isVisible) {
            restartVideo()
        }
    }, [isVisible])

    return (
        <div ref={containerRef}>
            <ReactPlayer
                ref={playerRef}
                width="100%"
                height="100%"
                controls={false}
                playing={isVisible}
                url={loadingVideo}
                playsinline
                muted={true}
                loop
                // onProgress={handleOnProgress}
                onError={(e) => console.log('error', e)}
                // onEnded={() => onEnded()}
            />
        </div>
    )
}

export default MintLoadingVideo
