import { createAsyncThunk, createAction } from '@reduxjs/toolkit'
import globalTypes from '../globalTypes'
import axiosInstance from '../../services/axiosConfig'

export const updateScreenAccess = createAction('auth/updateScreenAccess')
export const setAuth = createAction('auth/setAuth')
export const logout = createAction(globalTypes.LOG_OUT)
export const updateSignInSteps = createAction('auth/updateSignInSteps')
export const updateStaked = createAction('auth/setStaked')

export const login = createAsyncThunk(
    'auth/login',
    async ({ user, password }, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.post('bybit/login/', {
                username: user,
                password,
            })
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const signup = createAsyncThunk(
    'auth/signUp',
    async (
        { user, password, email, address, referralcode },
        { rejectWithValue }
    ) => {
        try {
            const res = await axiosInstance.post('bybit/register/', {
                username: user,
                email,
                password,
                address,
                referralcode: referralcode ?? '',
            })

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const createQr2fa = createAsyncThunk(
    'auth/2fa/createQr',
    async (_, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.get('accounts/totp/create/')

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const verify2fa = createAsyncThunk(
    'auth/2fa/verifyQr',
    async (token, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post(
                `accounts/totp/login/${token}/`
            )

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const sendEmailForgotPassord = createAsyncThunk(
    'auth/forgotpassword/sendEmail',
    async (email, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post(`bybit/recoverPassword/`, {
                email,
            })

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const sendEmail = createAsyncThunk(
    'auth/sendEmail',
    async (email, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post(`bybit/sendemail/`, {
                email,
            })

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const updatePassword = createAsyncThunk(
    'auth/forgotpassword/update',
    async ({ currentPassword, password }, { rejectWithValue }) => {
        try {
            const res = await axiosInstance.post(`bybit/changePassword/`, {
                currentPassword,
                password,
            })

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)
