import { createAsyncThunk } from '@reduxjs/toolkit'
import axiosInstance from '../../services/axiosConfig'
import dayjs from 'dayjs'

export const getAllWalletBalance = createAsyncThunk(
    'admin/getAllWalletBalance',
    async (
        { start, end = dayjs().format('YYYY-MM-DD') },
        { rejectWithValue }
    ) => {
        try {
            const res = await axiosInstance.post('bybit/getwalletbalanceall/', {
                start,
                end,
            })

            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const getAdminReferralbyuser = createAsyncThunk(
    'admin/getreferralbyuseradmin',
    async (idUser, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/referral/getreferralbyuseradmin/${idUser}`
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

export const getreferralbyuser = createAsyncThunk(
    'referral/getreferralbyuser',
    async (idUser, { rejectWithValue, dispatch }) => {
        try {
            const res = await axiosInstance.get(
                `bybit/referral/getreferralbyuser/${idUser}`
            )
            return res.data
        } catch (err) {
            if (!err.response) throw err

            return rejectWithValue(err.response.data)
        }
    }
)

