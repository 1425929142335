/* eslint-disable no-unused-vars */
import React, {useState} from 'react'
import {
    formatNumberToCurrency,
} from 'src/utils/formatNumber'
import { Tag } from 'antd'
import dayjs from 'dayjs'
import { BiDotsHorizontalRounded } from 'react-icons/bi'
import { AiOutlineDown } from 'react-icons/ai'
import { EpochRow } from './EpochRow'
import { EpochsCondensed } from './EpochsCondensed'
import { EpochsExpanded } from './EpochsExpanded'
import { NoEpochs } from './NoEpochs'


export const CustomerCard = ({
    name,
    initialAmount,
    pnl,
    epochs,
}) => {
    const [displayEpochs, setDisplayEpochs] = useState(false)
    
    return (
        <div className="text-white flex flex-col px-5 pb-3 pt-4 rounded-sm border border-[#2B2B2B] bg-[#1f1f1f] h-fit">
            <div className="mb-4 relative">
                <div className=" font-medium text-palette-beigde-100 text-lg font-oxanium leading-none ">
                    {name}
                </div>
                <div
                    onClick={() => setDisplayEpochs(!displayEpochs)}
                    className={`${displayEpochs && 'rotate-180'} ${
                        epochs.length <= 1 && 'hidden'
                    } absolute right-0 translate-x-1/2 rounded-full cursor-pointer hover:bg-gray-500/50 hover:text-white top-1/2 -translate-y-1/2 h-7 w-7 flex transition delay-150 duration-300 ease-in-out`}
                >
                    <AiOutlineDown className="text-xl m-auto text-palette-gray-900" />
                </div>
            </div>
            {displayEpochs || epochs.length <= 1 ? (
                epochs.length === 0 ?
                <NoEpochs/>
                : <EpochsExpanded epochs={epochs} />
            ) : (
                <EpochsCondensed
                    epochs={epochs}
                    initialAmount={initialAmount}
                    pnl={pnl}
                />
            )}
        </div>
    )
}
