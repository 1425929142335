import { createSlice, isAnyOf } from '@reduxjs/toolkit'

import {
    getAllWalletBalance,
    getreferralbyuser,
    getAdminReferralbyuser,
} from './admin.actions'

export const initialState = {
    balances: [],
    id: 0,
    userId: 0,
    userName: '',
    email: '',
    referrals: [],
    incomingCommisions: [],
    lastCommissions: [],
    ordersPaymentsStatus: [],
    totalProfitReferral: '',
    totalCommissionReferral: '',
    commissionSystem: '',
}

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAllWalletBalance.fulfilled, (state, { payload }) => {
                state.balances = payload
            })
            .addMatcher(
                isAnyOf(
                    getreferralbyuser.fulfilled,
                    getAdminReferralbyuser.fulfilled
                ),
                (state, { payload }) => {
                    if (payload) {
                        const {
                            user_id: userId,
                            user_username: userName,
                            user_email: email,
                            incoming_commissions: incomingCommisions,
                            last_commissions: lastCommissions,
                            OrdersPaymentsStatus: ordersPaymentsStatus,
                            ...rest
                        } = payload

                        return {
                            ...state,
                            userId,
                            userName,
                            email,
                            incomingCommisions,
                            lastCommissions,
                            ordersPaymentsStatus,
                            ...rest,
                        }
                    }
                }
            )
    },
})

export default adminSlice.reducer
