// import  { Temporality } from "../constants/temporality.constants"
import { WEEKLY, CUSTOM, temporality } from '../constants/temporality.constants'
import dayjs from 'dayjs'
import { useState, useMemo } from 'react'

/**
 * Hook to calculate the range of trades based on the temporality selected by the user
 * It does not add 1 day compared to original useTemporality hook
 * Temporality => daily, weekly, monthly, yearly
 * @param {string} temporality - Temporality to be used to calculate the range of trades
 */

export const useTemporality2 = (
    initalTemporality,
    customRange = [],
    options
) => {
    const [selectedTemporality, setSelectedTemporality] = useState(
        initalTemporality || WEEKLY
    )

    return useMemo(() => {
        const endDate = dayjs().startOf('day')

        const $dateFormat = options?.dateFormat || 'YYYY-MM-DD'

        const range = {}
        if (selectedTemporality === CUSTOM) {
            const [start, end] = customRange
            const $start = dayjs(start)
            const $end = dayjs(end)
            range.startDate = $start
            range.endDate = $end
            range.startDateString = $start.format($dateFormat)
            range.endDateString = $end.format($dateFormat)
        } else {
            const startDate = dayjs()
                .startOf('day')
                .subtract(temporality[selectedTemporality], 'day')
            range.startDate = startDate
            range.endDate = endDate
            range.startDateString = startDate.format($dateFormat)
            range.endDateString = endDate.format($dateFormat)
        }
        return {
            temporality: selectedTemporality,
            setTemporality: setSelectedTemporality,
            range,
        }
    }, [selectedTemporality, options, customRange])
}
