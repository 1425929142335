import React from 'react'

const StatsTitle = ({ title }) => {
    return (
        <div className="text-palette-gray-700 font-semibold text-[10px] lg:text-xs">
            {title}
        </div>
    )
}

export default StatsTitle
