import React, { useImperativeHandle, useCallback } from 'react'
import FormikAntInput from 'src/components/FormikAntDInputs/FormikAntInput'
import * as yup from 'yup'
import { validations } from 'src/service/yupValidations'
import { useFormik } from 'formik'
import FormikAntInputPassword from 'src/components/FormikAntDInputs/FormikAntInputPassword'
import { useHistoryTradesState } from 'src/store/reducers/historyTradesReducer/historyTrades.hooks'
import { removeEmptySpaces } from 'src/utils/stringUtils'
import classNames from 'classnames'
import FormikAntSelectAdmin from 'src/components/FormikAntDInputs/FormikAntSelectAdmin'
// import FormikAntSelectAdmin from 'src/components/FormikAntDInputs/FormikAntSelectAdmin'
// import classNames from 'classnames'
// import { useStaffState } from 'src/store/reducers/staffReducer/staff.hooks'

const AddCustomerForm = React.forwardRef(({ onSubmit }, ref) => {
    // const { salesStaffMembers }= useStaffState()
    const { epochs } = useHistoryTradesState()

    const _initialState = {
        userName: '',
        email: '',
        password: '',
        confirmPassword: '',
        epoch: null,
        initialBalance: '',
    }

    const yupSchema = yup.object().shape({
        userName: validations.alphaNumericName,
        email: validations.mail,
        password: validations.password,
        confirmPassword: validations.passwordConfirmation,
        epoch: validations.number,
        initialBalance: validations.number,
    })

    const handleSubmit = useCallback(
        (values) => {
            onSubmit({
                ...values,
            })
        },
        [onSubmit]
    )

    const formik = useFormik({
        initialValues: _initialState,
        validationSchema: yupSchema,
        onSubmit: handleSubmit,
    })

    useImperativeHandle(ref, () => ({
        submitForm: () => formik.submitForm(),
        resetForm: () => formik.resetForm(_initialState),
    }))

    return (
        <form onSubmit={formik.handleSubmit} className="pb-3">
            <div>
                <div className="mb-1">Username</div>
                <FormikAntInput
                    formik={formik}
                    name="userName"
                    placeholder="Username"
                    size="small"
                    transform={{
                        output: (value) => {
                            // valdate if value is empty and return empty string
                            // and apply a regex to only allow letters and numbers and no spaces
                            // and return it as a string

                            let _value = value
                            if (!_value) return ''
                            _value = _value.replace(/[^a-zA-Z0-9]/g, '')

                            return _value
                        },
                    }}
                />
            </div>
            {/* <div>
                <div className="mb-1">Select Seller</div>
                <FormikAntSelectAdmin
                    formik={formik}
                    name={'seller'}
                    size='large'
                    value={
                        formik.values?.seller !== ''
                            ? formik.values?.seller
                            : null
                    }
                    placeholder="SELECT SELLER"
                    className={classNames('text-center rounded-sm h-10', {
                        'ring-1 ring-red-600':
                            (formik.touched.epoch && formik.errors.epoch) ||
                            (formik.errors.epoch && formik.submitCount > 0),
                    })}
                    onChange={(val) => {
                        formik.setValues({
                            ...formik.values,
                            seller: val,
                        })
                    }}
                    options={salesStaffMembers.map((s) => {
                        return {
                            value: s.id,
                            label: s.user,
                        }
                    })}
                />
            </div> */}
            <div>
                <div className="mb-1">Email</div>
                <FormikAntInput
                    formik={formik}
                    name="email"
                    placeholder="Email"
                    size="small"
                    transform={{
                        output: (value) => {
                            // Validate if value is empty and return empty string
                            // if value is not string transform it to string
                            // then apply a regex to only allow email format and no spaces
                            // and return it as a string

                            let _value = value
                            if (!_value) return ''
                            _value = _value.toString()
                            _value = _value.replace(/\s/g, '')
                            _value = _value.replace(/[^a-zA-Z0-9@.]/g, '')

                            return _value.toLowerCase()
                        },
                    }}
                />
            </div>
            <div>
                <div className="mb-1">Password</div>
                <FormikAntInputPassword
                    formik={formik}
                    name="password"
                    size="small"
                    placeholder="Password"
                />
            </div>
            <div>
                <div className="mb-1">Confirm Password</div>
                <FormikAntInputPassword
                    formik={formik}
                    name="confirmPassword"
                    placeholder="Password"
                    size="small"
                />
            </div>
            <div>
                <div className="mb-1">Select Epoch</div>
                <FormikAntSelectAdmin
                    formik={formik}
                    name="epoch"
                    value={
                        formik.values?.epoch !== null
                            ? formik.values?.epoch
                            : null
                    }
                    placeholder="SELECT EPOCH"
                    style={{
                        width: '100%',
                        // height: '40px',
                    }}
                    className={classNames('text-center rounded-sm', {
                        'ring-1 ring-red-600':
                            (formik.touched.epoch && formik.errors.epoch) ||
                            (formik.errors.epoch && formik.submitCount > 0),
                    })}
                    size="large"
                    onChange={(val) => {
                        formik.setValues({
                            ...formik.values,
                            epoch: val,
                        })
                    }}
                    options={epochs.map((epoch) => {
                        return {
                            value: epoch.id.toString(),
                            label: `EPOCH ${epoch.id}`,
                        }
                    })}
                />
                <div className="mb-1 text-palette-gray-700 font-oxanium font-medium text-base">
                    Initial Balance
                </div>
                <FormikAntInput
                    formik={formik}
                    name="initialBalance"
                    size="small"
                    prefix={
                        <span className="text-base text-[#B2955F] font-semibold">
                            $
                        </span>
                    }
                    // placeholder="Username"
                    transform={{
                        output: (value) => removeEmptySpaces(value),
                    }}
                />
            </div>
        </form>
    )
})
AddCustomerForm.displayName = 'AddCustomerForm'

export default AddCustomerForm
