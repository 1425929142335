import { Tag } from 'antd'
import React from 'react'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

export const NoEpochs = () => {
  return (
      <div className="last:text-blue-600">
          <div className="last:border-0 border-b border-palette-gray-500">
              <div className=" mb-4 flex justify-between">
                  <Tag color="#131313" className="mb-3">
                      No epochs to show
                  </Tag>
              </div>
              <div className="flex flex-row w-full">
                  <div className="pr-2 flex flex-col flex-1">
                      <div className="font-semibold font-maven text-xs text-palette-gray-700 mb-3">
                          Initial Amount
                      </div>
                      <div className="tracking-tighter font-medium font-oxanium text-palette-gray-100 mt-auto text-base leading-none">
                          {formatNumberToCurrency(0)}
                      </div>
                  </div>
                  <div className="flex flex-col pr-4 flex-1 border-l-2 pl-6 border-[#2B2B2B]">
                      <div className="font-semibold font-maven text-xs text-palette-gray-700 mb-3">
                          Actual Balance
                      </div>
                      <div className="text-[#B2955F] text-base font-medium font-oxanium mt-auto leading-none">
                          {formatNumberToCurrency(0)}
                          <span className="text-xs leading-none">
                              {' '}
                              USD
                          </span>{' '}
                      </div>
                  </div>
              </div>
          </div>
      </div>
  )
}
