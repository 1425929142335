import Icon from 'src/components/Icons/Icon'

const ICON_SUCCESS = 'SUCCESS'
const ICON_ERROR = 'ERROR'
const ICON_WARNING = 'WARNING'
const ICON_INFO = 'INFO'

export const ANT_ICON_TYPE = {
    SUCCESS: ICON_SUCCESS,
    ERROR: ICON_ERROR,
    WARNING: ICON_WARNING,
    INFO: ICON_INFO,
}

export const ANT_ICON_NOTIFICATION = {
    [ICON_SUCCESS]: <Icon name="4-check" className="w-6 h-6" />,
    [ICON_ERROR]: <Icon name="4-wrong" className="w-6 h-6" />,
    [ICON_WARNING]: <Icon name="4-alert" className="w-6 h-6" />,
    [ICON_INFO]: <Icon name="4-info" className="w-6 h-6" />,
}
