import React from 'react'
import NoLoggedLayout from 'src/components/LayoutsComponents/noLoggedLayout'
import logoIgniite from 'src/assets/images/logo.png'
import { Navigate, useNavigate } from 'react-router-dom'
import { TbArrowLeft } from 'react-icons/tb'
import { FORGOT_PASSWORD_SCREEN } from 'src/store/reducers/auth/auth.slice'
import { useScreenAccess } from 'src/store/reducers/auth/auth.hooks'

const SendEmailForgotPassword = () => {
    const screen = useScreenAccess()

    if (screen !== FORGOT_PASSWORD_SCREEN)
        return <Navigate to={'/forgotpassword'} replace />
    const navigate = useNavigate()
    return (
        <NoLoggedLayout>
            <div className="w-full max-w-[450px] mx-auto ">
                <div className="flex justify-center">
                    <img
                        src={logoIgniite}
                        className="w-4/6"
                        alt="LOGO-IGNIITE"
                    />
                </div>
                <div className="mt-8 bg-palette-black-800 flex justify-center items-center rounded-sm py-16 px-3 lg:px-0 text-sm text-center">
                    We sent you an email with the steps to recover your password
                </div>{' '}
                <div className="flex flex-row mt-8 mb-6 gap-3">
                    <button
                        onClick={() => {
                            navigate('/login', { replace: true })
                        }}
                        type="submit"
                        className="h-[68px] w-full rounded-sm text-palette-brown-901 bg-palette-black-700 text-sm font-semibold flex justify-center items-center border border-palette-brown-901"
                    >
                        {' '}
                        <div className="flex flex-row justify-center items-center gap-2">
                            <TbArrowLeft
                                size={'1.6rem'}
                                className="text-palette-brown-901"
                            />
                            <div>GO BACK TO LOGIN</div>
                        </div>
                    </button>
                </div>
            </div>
        </NoLoggedLayout>
    )
}

export default SendEmailForgotPassword
