import React from 'react'
import { Modal } from 'antd'
import Button from 'src/components/Buttons/Button'

const GenericModal = ({ visible, onClose, children, buttonText = 'CLOSE' }) => {
    return (
        <Modal
            centered
            visible={visible}
            onCancel={onClose}
            footer={null}
            width={526}
        >
            <div className="w-full pt-8">
                {children}
                <div className="flex justify-center w-full gap-3 mt-12">
                    <Button className="w-40" variant="light" onClick={onClose}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default GenericModal
