import { useCall } from '@usedapp/core'

import { useERC721Contract } from '../useContract'
import { NFT_ADDRESS_IGNIITE } from '../../../constants/addressConstants'
import { useMemo } from 'react'

const useNftWalletOfOwner = (account) => {
    const contract = useERC721Contract(NFT_ADDRESS_IGNIITE)
    const { value } =
        useCall(
            account && {
                contract,
                method: 'walletOfOwner',
                args: [account],
            }
        ) ?? {}

    return useMemo(() => {
        // if (error) {
        //     console.error('Error', error.message)
        //     return undefined
        // }

        return value?.[0]?.[0] ? Number(parseInt(value?.[0]?.[0])) : undefined
    }, [value])
}

export default useNftWalletOfOwner
