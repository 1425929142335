import React from 'react'

const StatsDefaultValue = ({ value }) => {
    return (
        <div className="mt-4 text-lg lg:text-2xl text-palette-gray-100 font-oxanium font-bold leading-none">
            {value}
        </div>
    )
}

export default StatsDefaultValue
