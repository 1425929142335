import React from 'react'
import {
    formatNumberToCurrency,
    formatNumberToPercentage,
} from 'src/utils/formatNumber'
import Button from 'src/components/Buttons/Button'
import { useNavigate } from 'react-router-dom'
import { FaCheck, FaTimes } from 'react-icons/fa'
// import useDeepCompareEffect from './../../hooks/useDeepCompareEffect'
import { sortBoolean, sortNumberAnt } from '../../utils/tableAntsUtils'
import { sortTextAlpha } from 'src/utils/tableAntsUtils'
import CustomTable from './CustomTable'

const TableRefers = ({ dataSource = [], hideColumns = [] }) => {
    const navigation = useNavigate()

    const columns = [
        {
            title: 'Username',
            dataIndex: 'user_username',
            key: 'user_username',
            width: '12%',
            sorter: sortTextAlpha('user_username'),
            enableSearch: true,
            // onFilter: (value, record) => record.user_username.startsWith(value),
        },
        {
            title: 'Email',
            dataIndex: 'user_email',
            key: 'user_email',
            width: '13%',
            sorter: sortTextAlpha('user_email'),
            enableSearch: true,
        },
        {
            title: 'Bybit Refer',
            dataIndex: 'bybitReferred',
            key: 'bybitReferred',
            sorter: sortBoolean('bybitReferred'),
            render: (value) => (
                <span
                    className={
                        value
                            ? 'text-palette-green-500'
                            : 'text-palette-pink-700'
                    }
                >
                    {value ? <FaCheck /> : <FaTimes />}
                </span>
            ),
        },
        {
            title: 'NFT Id',
            dataIndex: 'nftId',
            key: 'nftId',
            sorter: sortNumberAnt('nftId'),
        },
        {
            title: 'Balance',
            dataIndex: 'balance',
            key: 'balance',
            sorter: sortNumberAnt('balance'),
            render: (v) => <span> {formatNumberToCurrency(v)} </span>,
        },
        {
            title: 'Profit%',
            dataIndex: 'profitPercentage',
            key: 'profitPercentage',
            sorter: sortNumberAnt('profitPercentage'),
            render: (v) => (
                <span
                    className={
                        Number(v) >= 0
                            ? 'text-palette-green-500'
                            : 'text-palette-pink-700'
                    }
                >
                    {formatNumberToPercentage(v)}
                </span>
            ),
        },
        {
            title: 'Profit',
            dataIndex: 'profit',
            key: 'profit',
            render: (v) => (
                <span
                    className={
                        Number(v) >= 0
                            ? 'text-palette-green-500'
                            : 'text-palette-pink-700'
                    }
                >
                    {formatNumberToCurrency(v)}
                </span>
            ),
            sorter: sortNumberAnt('profit'),
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            key: 'commission',
            render: (v) => (
                <span
                    className={
                        Number(v) >= 0
                            ? 'text-palette-green-500'
                            : 'text-palette-pink-700'
                    }
                >
                    {formatNumberToCurrency(v)}
                </span>
            ),
            sorter: sortNumberAnt('commission'),
        },
        {
            title: 'Payment Status',
            dataIndex: 'paymentStatus',
            key: 'paymentStatus',
            filters: [
                {
                    text: 'Paid',
                    value: 'Paid',
                },
                {
                    text: 'Pending',
                    value: 'Pending',
                },
                // {
                //     text: 'Pending',
                //     value: 'Pending',
                // },
            ],
            onFilter: (value, record) => record.paymentStatus.startsWith(value),
        },
        {
            title: '',
            render: (_, values) => (
                <Button
                    variant="light"
                    onClick={() => navigation(`referral/${values.user_id}`)}
                >
                    View details
                </Button>
            ),
        },
    ].filter((v) => !hideColumns.includes(v.dataIndex))

    return (
        <CustomTable
            className="mt-2 w-full"
            columns={columns}
            dataSource={dataSource}
            scroll={{
                y: '600px',
                x: '1400px',
            }}
        />
    )
}

export default TableRefers
