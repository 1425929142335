/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { Drawer } from 'antd'
import SideBar from './LayoutsComponents/SideBar'
import { AiOutlineBell, AiOutlineUser, AiOutlineLogout } from 'react-icons/ai'
import logoIgniite from 'src/assets/images/logo_full.png'
import { TbArrowRight, TbArrowLeft, TbArrowsRightLeft } from 'react-icons/tb'
import { BiMenu } from 'react-icons/bi'
import {
    MdDashboard,
    MdOutlineHelpOutline,
    MdOutlinePeopleOutline,
    MdOutlineSettings,
    MdOutlineAdminPanelSettings,
} from 'react-icons/md'
import useMediaQuery from './../hooks/useMediaQuery'

// import * as actions from 'src/store/reducers/userReducer/paymentStatusReducer'
import useAccountName from './../hooks/useAccountName'
import classNames from 'classnames'
import LogoutConfirm from './Modals/LogoutConfirm'
import { useIsStaked } from '../store/reducers/auth/auth.hooks'

import { useUserInfo } from 'src/store/reducers/userInfoReducer/userInfo.hooks'
import {
    useActiveKey,
    useKeysBybit,
    useSetActiveAccount,
} from 'src/store/reducers/dashboardReducer/dashboard.hooks'

const menus = [
    { menuName: 'HOME', path: '/dashboard', icon: MdDashboard },
    // { menuName: 'LIVE TRADING', path: '/livetrading', icon: MdDashboard },
    // { menuName: 'TRADES', path: '/dashboard', icon: TbArrowsRightLeft },
    // { menuName: 'ACCOUNTS', path: '/keys', icon: MdOutlinePeopleOutline },
    // { menuName: 'CONFIG', path: '/config', icon: MdOutlineSettings },
]

const PAYED = 1
const PENDING = 2
const OVERDUE = 3

const statusColor = {
    [PAYED]: '#09C69A',
    [PENDING]: '#DCB651',
    [OVERDUE]: '#F44E67',
}

const DefaultNavbarMenuItem = ({ path, menuName }) => {
    const navigate = useNavigate()

    return (
        <div
            className="text-center pt-4 cursor-pointer"
            onClick={() => navigate(path)}
        >
            <span className="px-2 pb-2  border-transparent hover:border-b-palette-brown-901  border-2 ">
                {menuName}
            </span>
        </div>
    )
}

const DefaultNavbarMenu = () => {
    const { isSuperuser, isStaff } = useUserInfo()
    return (
        <div className="flex-row justify-center grid grid-cols-6 gap-2">
            {menus.map((v) => (
                <DefaultNavbarMenuItem
                    key={`default-menu-${v.menuName}`}
                    {...v}
                />
            ))}
            {isSuperuser && (
                <DefaultNavbarMenuItem menuName={'ADMIN'} path="/admin" />
            )}
            {isSuperuser && (
                <DefaultNavbarMenuItem
                    menuName={isSuperuser ? 'ADMIN TRADER' : 'ADMIN'}
                    path="/trader/admin"
                />
            )}
            {isStaff && !isSuperuser && (
                <DefaultNavbarMenuItem menuName={'SALES'} path="/staff/sales" />
            )}
            {isSuperuser && (
                <DefaultNavbarMenuItem
                    menuName={'RESOLVER'}
                    path="/positionresolver"
                />
            )}
        </div>
    )
}

const RightMenu = ({ paymentStatusId, isMobile, enableAccess }) => {
    const [visible, setVisible] = useState(false)
    const [visibleModal, setVisibleModal] = useState(false)
    const handleShowDrawer = () => setVisible((state) => !state)
    const navigate = useNavigate()

    const activeKey = useActiveKey()
    const accounts = useKeysBybit()
    const setActiveAccount = useSetActiveAccount()

    const color = useMemo(() => {
        return statusColor[paymentStatusId] ?? '#bfb8a4'
    }, [paymentStatusId])

    const handleSelect = useCallback((e) => {
        setActiveAccount(Number(e.target.value))
    }, [])

    return (
        <div className="flex flex-row ml-auto">
            <LogoutConfirm
                visible={visibleModal}
                onClose={() => setVisibleModal((state) => !state)}
                onConfirm={() => navigate('/logout')}
            />
            {activeKey && enableAccess && (
                <select
                    onChange={handleSelect}
                    value={activeKey}
                    className="mr-3 bg-transparent hover:cursor-pointer text-palette-brown-901 border-0 focus:outline-none"
                >
                    {accounts.map(({ id, name }) => {
                        return (
                            <option key={`opt-${id}-${name}`} value={id}>
                                {activeKey === id.toString() &&
                                    'Active Account: '}
                                {name}
                            </option>
                        )
                    })}
                </select>
            )}
            {/* {accountName && (
                <div
                    className={classNames(
                        'flex  py-4  text-palette-brown-901 md:flex-row md:mr-12 md:items-end flex-col justify-end text-xs mr-2 md:gap-2 my-auto'
                    )}
                >
                    <div>ACTIVE ACCOUNT: </div>{' '}
                    <div className="text-right">{accountName}</div>
                </div>
            )} */}
            <div className="flex flex-row gap-3 md:gap-5">
                <div className="flex items-center cursor-pointer text-lg md:text-xl text-palette-gray-700">
                    <AiOutlineBell />
                </div>

                <div
                    className="flex items-center cursor-pointer  text-lg md:text-xl  text-palette-gray-700"
                    onClick={handleShowDrawer}
                >
                    <AiOutlineUser style={{ color }} />
                </div>

                <div
                    className="flex items-center cursor-pointer  text-lg md:text-xl text-palette-gray-700 "
                    onClick={() => setVisibleModal((state) => !state)}
                >
                    <AiOutlineLogout className="-rotate-90" />
                </div>
            </div>

            <Drawer
                drawerStyle={{ backgroundColor: '#8D793F' }}
                headerStyle={{
                    backgroundColor: '#1A1A1A',
                    border: 0,
                    margin: 0,
                    paddingTop: '1.2rem',
                }}
                bodyStyle={{
                    backgroundColor: '#1A1A1A',
                    color: '8D793F',
                    paddingRight: '2.5rem',
                }}
                title={
                    <button onClick={handleShowDrawer}>
                        <TbArrowRight
                            size={'1.6rem'}
                            className="text-palette-brown-901"
                        />
                    </button>
                }
                width={320}
                placement="right"
                closable={false}
                onClose={handleShowDrawer}
                visible={visible}
            >
                <SideBar
                    onClose={handleShowDrawer}
                    logout={() => {
                        handleShowDrawer()
                        setVisibleModal((state) => !state)
                    }}
                />
            </Drawer>
        </div>
    )
}

const ContextMenuItem = ({ path, menuName, icon }) => {
    const navigate = useNavigate()
    const Icon = icon
    return (
        <div
            className=" flex flex-row pl-[25%]  pt-4 cursor-pointer"
            onClick={() => navigate(path)}
        >
            <Icon className="text-xl relative mt-[0.18rem]" />
            <span className="px-2 pb-2 border-transparent hover:border-b-palette-brown-901 border-2 ">
                {menuName}
            </span>
        </div>
    )
}

const ContextMenu = () => {
    const { isSuperuser, isStaff } = useUserInfo()
    return (
        <div className="text-palette-beigde-100">
            {menus.map((v) => (
                <ContextMenuItem
                    key={`context-menu-item-${v.menuName}`}
                    {...v}
                />
            ))}
            {isSuperuser && (
                <ContextMenuItem
                    menuName={'ADMIN'}
                    path={'/admin'}
                    icon={MdOutlineAdminPanelSettings}
                />
            )}
            {isSuperuser && (
                <ContextMenuItem
                    menuName={isSuperuser ? 'ADMIN TRADER' : 'ADMIN'}
                    path={'/trader/admin'}
                    icon={MdOutlineAdminPanelSettings}
                />
            )}
            {isStaff && !isSuperuser && (
                <ContextMenuItem
                    menuName={'SALES'}
                    path="/staff/sales"
                    icon={MdOutlineAdminPanelSettings}
                />
            )}
            {isSuperuser && (
                <ContextMenuItem
                    menuName={'RESOLVER'}
                    path={'/positionresolver'}
                    icon={MdOutlineAdminPanelSettings}
                />
            )}
        </div>
    )
}

const LeftMenu = () => {
    const [visible, setVisible] = useState(false)
    const handleShowDrawer = () => setVisible((state) => !state)

    return (
        <React.Fragment>
            <div
                className="flex items-center px-2 cursor-pointer text-palette-yellow-800 text-xl"
                onClick={handleShowDrawer}
            >
                <BiMenu />
            </div>{' '}
            <Drawer
                drawerStyle={{ backgroundColor: '#8D793F' }}
                headerStyle={{
                    backgroundColor: '#1A1A1A',
                    border: 0,
                    margin: 0,
                    paddingTop: '1.2rem',
                }}
                bodyStyle={{
                    backgroundColor: '#1A1A1A',
                    color: '8D793F',
                    paddingRight: '2.5rem',
                }}
                title={
                    <button onClick={handleShowDrawer} className=" float-right">
                        <TbArrowLeft
                            size={'1.6rem'}
                            className="text-palette-brown-901"
                        />
                    </button>
                }
                width={320}
                placement="left"
                closable={false}
                onClose={handleShowDrawer}
                visible={visible}
            >
                <ContextMenu />
            </Drawer>
        </React.Fragment>
    )
}

const Brand = () => {
    const navigate = useNavigate()
    return (
        <div className="flex items-center justify-center bg-palette-black-800 h-full mr-2">
            <a onPointerDown={() => navigate('/')} className="h-[50%]">
                <img
                    src={logoIgniite}
                    className="rounded-full h-full"
                    alt="Logo-Igniite"
                />
            </a>
        </div>
    )
}

const DefaultNavbar = ({ acountName, paymentStatusId, enableAccess }) => {
    return (
        <div className="flex w-full h-full mx-auto max-w-[1340px] text-palette-beigde-100">
            <Brand />
            {enableAccess && <DefaultNavbarMenu />}
            <RightMenu
                enableAccess={enableAccess}
                acountName={acountName}
                paymentStatusId={paymentStatusId}
            />
        </div>
    )
}

const MobileNavbar = ({ acountName, enableAccess, isSuperuser }) => {
    return (
        <div className="flex w-full h-full mx-auto max-w-[1340px]">
            {enableAccess && <LeftMenu />}
            <Brand />
            <RightMenu
                isMobile
                acountName={acountName}
                enableAccess={enableAccess}
            />
        </div>
    )
}

const Header = () => {
    const acountName = useAccountName()
    const isStaked = useIsStaked()
    const { active, isSuperuser, isStaff } = useUserInfo()

    const dispatch = useDispatch()
    // const getPaymentStsAction = useCallback(
    //     () => dispatch(actions.paymentstatus()),
    //     [dispatch]
    // )

    // useEffect(() => {
    //     getPaymentStsAction()
    // }, [])

    // const paymentStatusId = useSelector(
    //     (state) => state.userPaymentReducer.statusId
    // )

    const isMobile = useMediaQuery('(max-width: 1023px)')

    return (
        <header className="flex h-14 bg-palette-black-800 text-palette-beigde-100 text-sm font-medium lg px-3 xl:px-2 2xl:px-0">
            {!isMobile ? (
                <DefaultNavbar
                    acountName={acountName}
                    paymentStatusId={0}
                    enableAccess={isStaked && active}
                />
            ) : (
                <MobileNavbar
                    isMobile={isMobile}
                    acountName={acountName}
                    enableAccess={isStaked && active}
                />
            )}
        </header>
    )
}

export default Header
