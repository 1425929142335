/* eslint-disable no-unused-vars */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import cloneDeep from 'lodash/cloneDeep'
import { useDispatch } from 'react-redux'
import {
    createStateSyncMiddleware,
    initMessageListener,
} from 'redux-state-sync'
import rootReducer, { PERSISTED_KEYS } from './reducers'

import { initialState as authInitialState } from './reducers/auth/auth.slice'
import { initialState as dashboardInitialState } from './reducers/dashboardReducer/dashboard.slice'
import { initialState as userInfoInitialState } from './reducers/userInfoReducer/userInfo.slice'
import { initialState as backupMintRState } from './reducers/mintRequirementsReducer/mintRequirementsBackup'
import { initialState as paymentState } from './reducers/paymentsReducer/payments.slice'

const config = {
    // TOGGLE_TODO will not be triggered in other tabs
    blacklist: [],
}

const store = configureStore({
    devTools: process.env.NODE_ENV !== 'production',
    reducer: rootReducer,
    middleware: [
        ...getDefaultMiddleware({ thunk: true }),
        createStateSyncMiddleware(config),
        save({ states: PERSISTED_KEYS }),
    ],
    preloadedState: load({
        states: PERSISTED_KEYS,
        preloadedState: {
            authReducer: cloneDeep(authInitialState),
            dashboardReducer: cloneDeep(dashboardInitialState),
            userInfoReducer: cloneDeep(userInfoInitialState),
            mintRequirementsBackup: cloneDeep(backupMintRState),
            paymentsReducer: cloneDeep(paymentState),
        },
    }),
})

initMessageListener(store)

export default store
