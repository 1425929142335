import React, { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { logout } from 'src/store/reducers/auth/auth.actions'

const LogoutView = () => {
    const dispatch = useDispatch()
    const logoutAction = useCallback(() => dispatch(logout()), [dispatch])

    useEffect(() => {
        logoutAction()
    }, [])

    return <></>
}

export default LogoutView
