import React from 'react'
import classNames from 'classnames'

export const CustomerElement = ({ selected, onSelected, name }) => {
    return (
        <div
            onClick={onSelected}
            className={classNames(
                'relative h-14 flex items-center mb-3 text-[#878787] font-semibold text-sm pl-[15%] hover:cursor-pointer hover:bg-palette-gray-100',
                {
                    "before:absolute bg-[#D3B895]/20 before:content-[''] before:h-full before:w-1 before:left-0 before:bg-palette-brown-901":
                        selected,
                }
            )}
        >
            {name}
        </div>
    )
}
