import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Alert, Checkbox } from 'antd'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEthers } from '@usedapp/core'
import useAuthW3 from '../hooks/web3/useAuthW3'
import logoIgniite from 'src/assets/images/logo.png'
import { SIGN_IN_SCREEN } from '../store/reducers/auth/auth.slice'
import { signup } from '../store/reducers/auth/auth.actions'
import {
    useUpdateScreenAccess,
    useResetScreenAccess,
} from '../store/reducers/auth/auth.hooks'
import FormikAntInput from '../components/FormikAntDInputs/FormikAntInput'
import FormikAntInputPassword from '../components/FormikAntDInputs/FormikAntInputPassword'
import { IoWalletOutline } from 'react-icons/io5'
import FormikAntInpuWithButton from '../components/FormikAntDInputs/FormikAntInpuWithButton'
import NoLoggedLayout from 'src/components/LayoutsComponents/noLoggedLayout'
import useAuth from '../hooks/useAuth'
import { validations } from '../utils/yupValidations'
import { sleep } from '../utils/promise'
import Button from 'src/components/Buttons/Button'
import { removeEmptySpaces } from 'src/utils/stringUtils'

const schema = yup.object().shape({
    user: validations.userName,
    email: validations.mail,
    password: validations.password,
    passwordConfirm: validations.passwordConfirmation,
    terms: validations.generic,
    walletAddress: validations.walletAddress,
})

const InputRules = ({ rules = [] }) => {
    return (
        <div className="flex flex-col gap-3 pr-8 pl-2 py-2">
            {rules.map((v) => (
                <div key={`input-rule-${v}`} className="flex flex-row gap-4">
                    <div className="w-3 h-3 rounded-full border border-white relative mt-[3px]"></div>
                    <div className="text-white text-xs">{v}</div>
                </div>
            ))}
        </div>
    )
}

const SignUpForm = () => {
    const { loginMetamask, logout } = useAuthW3()
    const { account } = useEthers()
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const { login } = useAuth()
    const dispatch = useDispatch()
    const [searchParams] = useSearchParams()

    const registerUser = useCallback(
        (data) => dispatch(signup(data)),
        [dispatch]
    )
    const updateScreenAccess = useUpdateScreenAccess()

    const handleRegister = useCallback(
        async ({ email, walletAddress, ...values }, { setSubmitting }) => {
            setSubmitting(true)
            try {
                await registerUser({
                    email: email.toLowerCase(),
                    address: walletAddress,
                    ...values,
                }).unwrap()
                await sleep(500)

                await login({ email: email.toLowerCase(), ...values })

                updateScreenAccess(SIGN_IN_SCREEN)
                navigate('steps/stakenft', { replace: true })
            } catch (error) {
                console.log('Error: ', error)
                if (error?.error) setError(error.message)
            } finally {
                await sleep(1000)
                setSubmitting(false)
            }
        },
        [registerUser, login]
    )
    const referralcode = searchParams.get('referralcode')

    const formik = useFormik({
        initialValues: {
            user: '',
            email: '',
            password: '',
            passwordConfirm: '',
            terms: '',
            walletAddress: '',
            referralcode: referralcode ?? '',
        },
        onSubmit: handleRegister,
        validationSchema: schema,
    })

    useResetScreenAccess(formik.isSubmitting)

    useEffect(() => {
        if (account) {
            formik.setFieldValue('walletAddress', account)
        } else {
            formik.setFieldValue('walletAddress', '')
        }
    }, [account])

    return (
        <NoLoggedLayout>
            <div className="w-full max-w-[450px] mx-auto">
                <div className="flex justify-center">
                    <img
                        src={logoIgniite}
                        className="w-4/6"
                        alt="LOGO-IGNIITE"
                    />
                </div>

                <div className="mt-8 flex flex-row items-end text-palette-brown-901">
                    <div className="text-lg ">REGISTER</div>
                    <div className="ml-auto">Step 1/4</div>
                </div>

                <div className="flex justify-center mt-4">
                    <form
                        className="flex flex-col w-full"
                        onSubmit={formik.handleSubmit}
                    >
                        <div className="w-full">
                            <FormikAntInput
                                name={'user'}
                                formik={formik}
                                placeholder="USERNAME"
                                value={formik.values?.user}
                                tooltip
                                tooltipProps={{
                                    title: (
                                        <InputRules
                                            rules={[
                                                '8-30 Characters',
                                                'Only alphanumeric characters',
                                            ]}
                                        />
                                    ),
                                }}
                                transform={{
                                    output: (value) => removeEmptySpaces(value),
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <FormikAntInput
                                name={'email'}
                                formik={formik}
                                value={formik.values?.email}
                                placeholder="E-MAIL"
                                transform={{
                                    output: (value) =>
                                        removeEmptySpaces(value.toLowerCase()),
                                }}
                                tooltip
                                tooltipProps={{
                                    title: (
                                        <InputRules
                                            rules={[
                                                '8-30 Characters',
                                                'Valid email format example@mail.com',
                                            ]}
                                        />
                                    ),
                                }}
                            />
                        </div>

                        <div className="w-full">
                            <FormikAntInpuWithButton
                                name={'walletAddress'}
                                disabled
                                formik={formik}
                                placeholder="WALLET ADDRESS"
                                value={account}
                                onClickButton={() => {
                                    if (account) {
                                        logout()
                                    } else loginMetamask()
                                }}
                                icon={<IoWalletOutline />}
                            />
                        </div>

                        <div className="w-full">
                            <FormikAntInputPassword
                                name="password"
                                formik={formik}
                                placeholder="PASSWORD"
                                tooltip
                                tooltipProps={{
                                    title: (
                                        <InputRules
                                            rules={[
                                                '8-30 Characters',
                                                'At least one upercase letter',
                                                'At least one lowercase letter',
                                                'At least one number',
                                            ]}
                                        />
                                    ),
                                }}
                                transform={{
                                    output: (value) => {
                                        return removeEmptySpaces(value)
                                    },
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <FormikAntInputPassword
                                name={'passwordConfirm'}
                                formik={formik}
                                placeholder="CONFIRM PASSWORD"
                                transform={{
                                    output: (value) => removeEmptySpaces(value),
                                }}
                            />
                        </div>
                        {error && (
                            <Alert
                                className=""
                                showIcon
                                message={error}
                                closable
                                type="error"
                                onClose={() => setError('')}
                            />
                        )}

                        <div className="w-full text-left text-sm">
                            <Checkbox
                                name="terms"
                                onChange={(v) => {
                                    formik.setValues({
                                        ...formik.values,
                                        terms: v.target.checked
                                            ? v.target.checked.toString()
                                            : '',
                                    })
                                }}
                                onBlur={formik.handleBlur}
                                defaultChecked={false}
                            >
                                <div className="ml-1 text-palette-gray-700 font-semibold text-sm">
                                    I agree to the terms and conditions.{' '}
                                </div>
                            </Checkbox>
                            <a
                                href="/termsconditions"
                                rel="noopener noreferrer"
                                target={'_blank'}
                                className=" text-sm"
                            >
                                Read Terms & Conditions
                            </a>
                        </div>

                        <div>
                            <Button
                                loading={formik.isSubmitting}
                                disabled={formik.isSubmitting}
                                type="submit"
                                variant="light"
                                className="mt-8 mb-6 h-[68px] w-full rounded-sm"
                            >
                                SIGN UP
                            </Button>
                        </div>
                    </form>
                </div>

                <div className="text-center mt-8 pb-20 lg:pb-0">
                    <span className="font-semibold text-palette-gray-700">
                        Already have an account?{' '}
                        <a
                            className="text-palette-brown-901 cursor-pointer "
                            onClick={() => navigate('/login')}
                        >
                            Log In
                        </a>
                    </span>
                </div>
            </div>
        </NoLoggedLayout>
    )
}

export default SignUpForm
