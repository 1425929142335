import React, { useEffect, useState, useCallback } from 'react'
import { AiOutlineFilePdf } from 'react-icons/ai'
import { FaSpinner } from 'react-icons/fa'
import { MdSkipNext, MdSkipPrevious } from 'react-icons/md'
import ReactPlayer from 'react-player'
import Button from 'src/components/Buttons/Button'
import NoLoggedLayout from 'src/components/LayoutsComponents/noLoggedLayout'
import { formatNumberDigits } from 'src/utils/formatter'

// import { useNavigate } from 'react-router-dom'
import { sleep } from 'src/utils/promise'
import { RiArrowDownSFill, RiArrowUpSFill } from 'react-icons/ri'

const links = [
    // {
    //     id: 1,
    //     title: 'OFFICIAL LINKS',
    //     link: 'https://igniite.fra1.digitaloceanspaces.com/OFFICIAL_LINKS.pdf',

    //     video: '',
    // },
    {
        id: 1,
        title: 'How to create a Bitso account',
        link: 'https://igniite.fra1.digitaloceanspaces.com/1-%20HOW%20TO%20CREATE%20A%20BITSO%20ACCOUNT.pdf',
        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/1-HOW%20TO%20CREATE%20A%20BITSO%20ACCOUNT.mp4',
        section: 'account',
    },
    {
        id: 2,
        title: 'How to fund your Bitso account',
        link: 'https://igniite.fra1.digitaloceanspaces.com/2-%20HOW%20TO%20FUND%20YOUR%20BITSO%20ACCOUNT.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/2-HOW%20TO%20FUND%20YOUR%20BITSO%20ACCOUNT.mp4',
        section: 'funds',
    },
    {
        id: 3,
        title: 'How to create a bybit account',
        link: 'https://igniite.fra1.digitaloceanspaces.com/3-%20HOW%20TO%20CREATE%20A%20BYBIT%20ACCOUNT.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/3-HOW%20TO%20CREATE%20A%20BYBIT%20ACCOUNT.mp4',
        section: 'account',
    },
    {
        id: 4,
        title: 'How to install MetaMask',
        link: 'https://igniite.fra1.digitaloceanspaces.com/4-%20HOW%20TO%20INSTALL%20METAMASK.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/4-HOW%20TO%20INSTALL%20METAMASK.mp4',
        section: 'account',
    },
    {
        id: 5,
        title: 'How to transfer usdt from Bitso to MetaMask',
        link: 'https://igniite.fra1.digitaloceanspaces.com/5-%20HOW%20TO%20TRANSFER%20USDT%20FROM%20BITSO%20TO%20METAMASK.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/5-HOW%20TO%20TRANSFER%20USDT%20FROM%20BITSO%20TO%20METAMASK.mp4',
        section: 'funds',
    },
    {
        id: 6,
        title: 'How to transfer ETH from Bitso to MetaMask',
        link: 'https://igniite.fra1.digitaloceanspaces.com/6-%20HOW%20TO%20TRANSFER%20ETH%20FROM%20BITSO%20TO%20METAMASK.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/6-HOW%20TO%20TRANSFER%20ETH%20FROM%20BITSO%20TO%20METAMASK.mp4',
        section: 'funds',
    },
    {
        id: 7,
        title: 'How to transfer usdt from Bitso to ByBit',
        link: 'https://igniite.fra1.digitaloceanspaces.com/7-%20HOW%20TO%20TRANSFER%20USDT%20FROM%20BITSO%20TO%20BYBIT.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/7-HOW%20TO%20TRANSFER%20USDT%20FROM%20BITSO%20TO%20BYBIT.mp4',
        section: 'funds',
    },
    {
        id: 8,
        title: 'How to mint',
        link: 'https://igniite.fra1.digitaloceanspaces.com/8-HOW%20TO%20MINT.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/9-HOW%20TO%20MINT.mp4',
        section: 'mint',
    },
    {
        id: 9,
        title: 'How to create an Igniite account',
        link: 'https://igniite.fra1.digitaloceanspaces.com/9-HOW%20TO%20CREATE%20AN%20IGNIITE%20ACCOUNT.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/10-HOW%20TO%20CREATE%20AN%20IGNIITE%20ACCOUNT.mp4',
        section: 'platform',
    },
    {
        id: 10,
        title: 'How to create and link an API key',
        link: 'https://igniite.fra1.digitaloceanspaces.com/10-HOW%20TO%20CREATE%20AND%20LINK%20AN%20API%20KEY.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/11-HOW%20TO%20CREATE%20AND%20LINK%20AN%20API%20KEY.mp4',
        section: 'platform',
    },
    {
        id: 11,
        title: 'How to unstake and stake the NFT',
        link: 'https://igniite.fra1.digitaloceanspaces.com/11-HOW%20TO%20UNSTAKE%20AND%20STAKE.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/12-HOW%20TO%20UNSTAKE%20AND%20STAKE%20A%20NFT.mp4',
        section: 'platform',
    },
    {
        id: 12,
        title: 'How to pay the weekly fee',
        link: 'https://igniite.fra1.digitaloceanspaces.com/12-HOW%20TO%20PAY%20THE%20PROFIT%20FEE.pdf',

        video: 'https://igniite.fra1.digitaloceanspaces.com/VIDEOS/13-HOW%20TO%20PAY%20THE%20PROFIT%20FEE.mp4',
        section: 'platform',
    },
]

const VideoDisplay = ({ loading, url }) => {
    return (
        <div className="w-full h-52 md:h-80 xl:h-[445px] flex justify-center items-center bg-palette-black-725">
            {loading ? (
                <FaSpinner className="animate-spin text-lg text-palette-gray-700" />
            ) : (
                <ReactPlayer
                    width="100%"
                    height="100%"
                    url={url}
                    controls={true}
                    playing={true}
                    volume={0.2}
                />
            )}
        </div>
    )
}
const VideoSection = ({
    selected,
    loading,
    disableLast,
    disableNext,
    onClickPrev,
    onClickNext,
}) => {
    return (
        <div className="flex flex-col gap-3 xl:gap-5">
            <div className="xl:h-[34px] flex items-end">
                <span className="text-lg xl:text-xl font-bold font-oxanium text-palette-brown-901 tracking-widest">
                    <span className="mr-5">
                        {formatNumberDigits(selected.id)}
                    </span>
                    {selected.title}
                </span>
            </div>
            <VideoDisplay url={selected.video} loading={loading} />
            <div className="flex flex-row justify-between border-palette-gray-700 border-b pb-4">
                <Button
                    onClick={onClickPrev}
                    disabled={disableLast}
                    className="py-1 xl:py-0 xl:h-[34px] text-[10px] xl:text-xs"
                    variant="dark"
                >
                    <MdSkipPrevious className="text-base xl:text-lg mr-2" />{' '}
                    Last Video
                </Button>
                <Button
                    onClick={onClickNext}
                    disabled={disableNext}
                    className="py-1 xl:py-0 xl:h-[34px] text-[10px] xl:text-xs"
                    variant="dark"
                >
                    Next Video
                    <MdSkipNext className="text-base xl:text-lg ml-2" />
                </Button>
            </div>
            <div className="">
                <div className="flex flex-row">
                    <span
                        className={`uppercase hover:cursor-pointer text-xs text-palette-gray-700 tracking-wide`}
                    >
                        OFFICIAL LINKS
                    </span>
                    <a
                        className="relative -mt-1 p-1 hover:cursor-pointer"
                        href={
                            'https://igniite.fra1.digitaloceanspaces.com/OFFICIAL%20LINKS.pdf'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <AiOutlineFilePdf className="text-lg text-palette-gray-700" />
                    </a>
                </div>
            </div>
        </div>
    )
}

const LearnItem = ({ id, link, title, selected, onSelect }) => {
    return (
        <div className="flex flex-row py-4 gap-4 border-palette-gray-700 border-b last:border-0">
            <span
                onClick={onSelect}
                className={`uppercase hover:cursor-pointer  text-xs ${
                    !selected
                        ? 'text-palette-gray-700'
                        : 'text-palette-brown-901'
                } tracking-wide`}
            >
                {/* <span className="mr-5">{formatNumberDigits(id)}</span> */}
                {title}
            </span>
            <a
                className="relative -mt-1 p-1 hover:cursor-pointer"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
            >
                <AiOutlineFilePdf className="text-lg text-palette-gray-700" />
            </a>
        </div>
    )
}

const Category = ({ title, data, selected, onSelect }) => {
    const [visible, setVisible] = useState(false)
    return (
        <div className=" ">
            <div
                className="flex flex-row hover:bg-palette-black-700/80 hover:cursor-pointer  px-7 py-3"
                onClick={() => setVisible((state) => !state)}
            >
                {' '}
                <div>{title}</div>
                <div className="ml-auto">
                    {' '}
                    <div className="text-lg w-5/12 ml-auto">
                        {!visible ? <RiArrowDownSFill /> : <RiArrowUpSFill />}
                    </div>
                </div>
            </div>
            <div className="px-7">
                {visible &&
                    data.map((v) => {
                        return (
                            <LearnItem
                                key={`learn-opt-${title}-${v.id}`}
                                selected={v.id === selected}
                                onSelect={() => onSelect(v)}
                                {...v}
                            />
                        )
                    })}
            </div>
        </div>
    )
}

const LearnSelectSection = ({ selected, onSelect }) => {
    return (
        <div className="flex flex-col gap-5 ">
            <div className="h-[34px]">
                <button className="h-full  px-7 rounded-md border border-palette-brown-901 bg-[#8D793F29] text-[#BFB8A4]">
                    VIDEOS
                </button>
            </div>
            <div className="flex flex-col gap-5 bg-palette-black-800 pb-16">
                <Category
                    selected={selected.id}
                    title={'CREATE ACCOUNTS'}
                    data={links.filter((v) => v.section === 'account')}
                    onSelect={onSelect}
                />
                <Category
                    selected={selected.id}
                    title={'FUNDS'}
                    data={links.filter((v) => v.section === 'funds')}
                    onSelect={onSelect}
                />
                <Category
                    selected={selected.id}
                    title={'MINT'}
                    data={links.filter((v) => v.section === 'mint')}
                    onSelect={onSelect}
                />
                <Category
                    selected={selected.id}
                    title={'HOW TO USE THE PLATFORM'}
                    data={links.filter((v) => v.section === 'platform')}
                    onSelect={onSelect}
                />
            </div>
        </div>
    )
}

const LearnView = (props) => {
    const [selected, setSelected] = useState(links[0])
    const [loading, setLoading] = useState(false)

    const initLoading = async (action) => {
        setLoading(true)
        await sleep(1200)
        setLoading(false)
    }

    const handleSelect = (value) => {
        setSelected(value)
    }

    const handleNext = useCallback(() => {
        const currentPos = selected.id - 1
        if (currentPos < 13 && !loading) setSelected(links[currentPos + 1])
    }, [selected, loading])

    const handlePrev = useCallback(() => {
        const currentPos = selected.id - 1
        if (currentPos > 0 && !loading) setSelected(links[currentPos - 1])
    }, [selected, loading])

    useEffect(() => {
        initLoading()
    }, [selected])

    return (
        <NoLoggedLayout enableNavbar>
            <div className="w-full max-w-[1326px] mx-auto flex flex-col xl:flex-row gap-4 pb-8">
                <div className="w-full xl:w-[790px]">
                    <VideoSection
                        selected={selected}
                        loading={loading}
                        onClickPrev={handlePrev}
                        onClickNext={handleNext}
                        disableLast={selected.id === 1}
                        disableNext={selected.id === 14}
                    />
                </div>
                <div className="flex-grow flex flex-col">
                    <LearnSelectSection
                        onSelect={handleSelect}
                        selected={selected}
                    />
                </div>
            </div>
        </NoLoggedLayout>
    )
}

export default LearnView
