import {
    StatsTitle,
    StatsContainer,
    StatsDefaultValue,
} from '../StatsComponents'

const Stats = ({ title, value }) => {
    return (
        <StatsContainer>
            <div className="flex flex-row">
                <div className="">
                    <StatsTitle title={title} />
                    <StatsDefaultValue value={value} />
                </div>
            </div>
        </StatsContainer>
    )
}

export default Stats
