/* eslint-disable no-unused-vars */
import React, { useEffect, useCallback } from 'react'
import ReactInputVerificationCode from 'react-input-verification-code'
import {
    SIGN_IN_SCREEN,
    LOG_IN_SCREEN,
} from 'src/store/reducers/auth/auth.slice'
import {
    useScreenAccess,
    useVerifyCode,
    useSignInSteps,
} from 'src/store/reducers/auth/auth.hooks'
import { Navigate, useLocation } from 'react-router-dom'
import { FaSpinner } from 'react-icons/fa'
import { Alert } from 'antd'
import classNames from 'classnames'

const VerifyCode = () => {
    const screen = useScreenAccess()
    const location = useLocation()
    const { scan: scanCompleted, stake: stakeCompleted } = useSignInSteps()

    let redirect
    if (location.pathname.includes('/register/steps/verify')) {
        if (screen !== SIGN_IN_SCREEN) redirect = '/register'
        if (screen === SIGN_IN_SCREEN) {
            if (!scanCompleted) redirect = '/register/steps/scan'
            if (!stakeCompleted) redirect = '/register/steps/stakenft'
        }
    }
    if (
        location.pathname.includes('/login/2fa/verify') &&
        screen !== LOG_IN_SCREEN
    )
        redirect = '/login'

    if (redirect) return <Navigate to={redirect} replace />

    const { verifyCode, loading, error, setError } = useVerifyCode()

    const handleComplete = useCallback(async (data) => {
        if (!data.includes('·')) await verifyCode(data)
    }, [])

    return (
        <div className="w-full max-w-[440px] mx-auto xl:ml-[15%] lg:mt-[12%]">
            <div className="flex flex-col">
                <div
                    className={classNames(
                        'ml-auto font-oxanium text-palette-brown-901 font-semibold text-xs',
                        {
                            hidden: !location.pathname.includes(
                                '/register/steps/verify'
                            ),
                        }
                    )}
                >
                    STEP 4/4
                </div>
                <div className="text-xl font-oxanium text-palette-brown-901 font-medium tracking-widest lg:mt-24">
                    VERIFICATION CODE
                </div>
                <div className="flex flex-col mt-16 ">
                    <div className="custom-reactInputVerificationCode ">
                        <ReactInputVerificationCode
                            length={6}
                            onCompleted={handleComplete}
                            autoFocus={true}
                        />
                    </div>
                    <div
                        className={classNames(
                            'w-full flex justify-center items-center text-white py-10',
                            { invisible: !loading }
                        )}
                    >
                        <FaSpinner className="animate-spin text-2xl" />
                    </div>
                    <div className="text-palette-gray-700 font-medium text-xs">
                        ENTER THE 6 DIGIT CODE FROM THE GOOGLE AUTHENTICATION
                        APPLICATION
                    </div>
                    <div className="mt-24 w-full">
                        {error && (
                            <Alert
                                className="mt-4"
                                showIcon
                                message={error}
                                closable
                                type="error"
                                onClose={() => setError('')}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VerifyCode
