import classNames from 'classnames'

const Tabs = ({ menus, selected, onSelect }) => {
    return (
        <div className="flex  justify-start font-bold text-tempname-gray-7 text-xs border-tempname-gray-4 border-b pl-2 gap-12">
            {menus.map((menu) => (
                <div
                    key={`tab-menu-${menu.tabIndex}`}
                    className={classNames(
                        "cursor-pointer relative pb-3 px-6 before:content-['']",
                        {
                            'before:absolute before:-bottom-[1px] before:left-0 before:right-0 before:h-[3px] before:bg-palette-brown-901':
                                menu.tabIndex === selected,
                        }
                    )}
                    onClick={() => onSelect(menu.tabIndex)}
                >
                    {menu.tabName}
                </div>
            ))}
        </div>
    )
}

export default Tabs
