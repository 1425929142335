import React from 'react'
import { formatNumberToCurrency } from 'src/utils/formatNumber'

import { sortNumberAnt, sortTextAlpha } from '../../utils/tableAntsUtils'
import CustomTable from './CustomTable'

const TableInComingComissions = ({ dataSource = [] }) => {
    const columns = [
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            sorter: sortTextAlpha('username'),
            enableSearch: true,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: sortTextAlpha('email'),
            enableSearch: true,
        },
        {
            title: 'Pnl',
            dataIndex: 'pnlBase',
            key: 'pnlBase',
            render: (v) => (
                <span
                    className={
                        Number(v) >= 0
                            ? 'text-palette-green-500'
                            : 'text-palette-pink-700'
                    }
                >
                    {formatNumberToCurrency(v)}
                </span>
            ),
            sorter: sortNumberAnt('pnlBase'),
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
            key: 'amount',
            render: (v) => <span>{formatNumberToCurrency(v)}</span>,
            sorter: sortNumberAnt('amount'),
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
    ]

    return (
        <CustomTable
            className="mt-2"
            columns={columns}
            dataSource={dataSource}
            scroll={{ y: '600px' }}
        />
    )
}

export default TableInComingComissions
